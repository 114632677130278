<script>
	import OptionalFeature from './OptionalFeature.svelte'
	import { fetchCart, putCartItemQuantity } from '../api/cart'
	import { onMount } from 'svelte'
	import Title from './Title.svelte'
	import Spacer from './Spacer.svelte'
	import Box from './Box.svelte'
	import {translate} from "../utils/translations"

	export let productVariantId

	let cart = null
	$: baseItem = cart?.items.find((it) => it.productVariantId === productVariantId)
	$: complements = baseItem?.complements

	onMount(async () => {
		cart = await fetchCart()
	})

	async function setQuantity(productVariantId, complementOfId, quantity) {
		// optimistic UI update
		complements.find((it) => it.productVariantId === productVariantId).quantity = quantity

		// call api
		cart = await putCartItemQuantity(productVariantId, complementOfId, quantity)
	}

</script>

{#if complements && complements.length > 0}
	<div class="optionalFeatureListStandalone">
		<Spacer initial="40" break768="56" />
		<Title level="4">{translate('app.checkout.recommendedProducts')}</Title>
		<Spacer initial="20" break768="40" />

		<div class="optionalFeatureListStandalone-items">
			{#each complements as complement}
				<OptionalFeature baseItemVariantId={productVariantId} {...complement} {setQuantity} />
			{/each}
		</div>
	</div>
{/if}
