<script>
	import {tick} from 'svelte'
	import Button from "./Button.svelte"
	import Collapsible from "./Collapsible.svelte"
	import Modal from "./Modal.svelte"
	import Picture from "./Picture.svelte"
	import ProductInCompareTile from "./ProductInCompareTile.svelte"
	import Title from "./Title.svelte"
	import {comparisons} from "../stores/comparisonStore";
	import {clearComparisons} from "../api/account";
	import {translate} from "../utils/translations";

	export let url
	let modalOpen = false
	let activeProduct = undefined

	const minTilesCount = 8

	async function handleDeleteAllClick() {
		await clearComparisons()
	}
	const closeModal = () => {
		modalOpen = false
	}

	async function handleThumbnailClick(product) {
		activeProduct = product
		modalOpen = false
		await tick()
		modalOpen = true
	}
</script>

<Collapsible expanded={$comparisons.length > 0}>
	<div class="comparatorBar">
		<div class="comparatorBar-in">
			<span class="comparatorBar-label">
				{translate('app.product.comparatorBar.label')}
			</span>
			<ul class="comparatorBar-products">
				{#each $comparisons as product}
					<li class="comparatorBar-products-item">
						<button class="comparatorBar-thumbnail" on:click={() => handleThumbnailClick(product)}>
							<span class="comparatorBar-thumbnail-in">
								<Picture image={product.image} sizes="100px" />
							</span>
						</button>
					</li>
				{/each}
				{#each Array(Math.max(0, minTilesCount - $comparisons.length)) as _foo}
					<li class="comparatorBar-products-item is-empty"></li>
				{/each}
			</ul>

			<div class="comparatorBar-actions">
				<Button type="button" variant="outlined" on:click={handleDeleteAllClick}>{translate('app.product.comparatorBar.remove')}</Button>
				<Button type="link" link={url} variant="contained">{translate('app.product.comparatorBar.compare')}</Button>
			</div>
		</div>
	</div>

</Collapsible>

<Modal open={modalOpen} type="right">
	<svelte:fragment slot="title">
		<Title level="2" viewLevel="4">V porovnání</Title>
	</svelte:fragment>
	<div class="comparatorBar-modal-content">
		<ProductInCompareTile product={activeProduct} onDelete={closeModal} />
	</div>
</Modal>
