<script>
	import clsx from "clsx";

	export let attribute
	export let option = undefined
	export let optionGroup = [option]
	export let label = option
	export let view = undefined

	function removeFromUrl(url, option, value) {
		const params = [...url.searchParams.entries()].filter((entry) => {
			url.searchParams.delete(entry[0])

			if (entry[0] === 'p') {
				return false
			}
			if (entry[0] === 'sort') {
				return true
			}

			if (entry[0] === option) {
				if (typeof value !== 'undefined' || value === null) {
					return value !== entry[1]
				} else {
					return false
				}
			}
			return true
		})

		params.forEach((entry) => {
			url.searchParams.append(...entry)
		})

		return url.toString()
	}

	function triggerChange(element) {
		if ('createEvent' in document) {
			var evt = document.createEvent('HTMLEvents')
			evt.initEvent('change', false, true)
			element.dispatchEvent(evt)
		} else element.fireEvent('onchange')
	}

	// TODO: if we could somehow trigger change on filters, this could work as `on:click`
	function updateFilters(e) {
		optionGroup.forEach((optionItem) => {
			const refInput = document.querySelector(
				`input[name="${attribute}"][value="${optionItem}"], input[name="${attribute}[]"][value="${optionItem}"]`,
			)
			if (refInput) {
				switch (refInput.type) {
					case 'checkbox':
						refInput.checked = false
						triggerChange(refInput)
						e.preventDefault()
						break
					case 'range':
						refInput.value = ''
						triggerChange(refInput)
						e.preventDefault()
						break
				}
			}
		})
	}

	export let updatedUrl

	$: {
		const url = new URL(window.location.href)
		optionGroup.forEach(optionItem => {
			updatedUrl = removeFromUrl(url, attribute, optionItem)
		})
	}

	function handleClick(e) {
		const url = new URL(window.location.href)
		e.preventDefault()
		optionGroup.forEach(optionItem => {
			updatedUrl = removeFromUrl(url, attribute, optionItem)
		})
		window.location.href = updatedUrl
	}

</script>

<div class={clsx("appliedFiltersItem", view && `view-${view}`)}>
	<span class="appliedFiltersItem-caption"><slot /></span>
	<a class="appliedFiltersItem-unsetter" href={updatedUrl} on:click={handleClick}>&times;</a>
</div>
