<script>
	import Spacer from './Spacer.svelte'

	export let label = ''
	export let start = 0
	export let end = 0
</script>

<button
	on:click
	type="button"
	class="progressIndicator"
	aria-label={label}
	style="--progressIndicator-progress-start: {start}; --progressIndicator-progress-end: {end};"
>
	<span class="progressIndicator-in" />
	{#if $$slots.thumbnail}
		<div class="progressIndicator-thumbnail">
			<slot name="thumbnail" />
		</div>
	{/if}
</button>
