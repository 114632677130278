<script>
	export let initial = 0
	export let break480 = initial
	export let break768 = break480
	export let break1000 = break768
	export let break1144 = break1000
	export let break1280 = break1144

	const breakpoints = [
		{
			name: 'initial',
			value: initial,
		},
		{
			name: '480',
			value: break480,
		},
		{
			name: '768',
			value: break768,
		},
		{
			name: '1000',
			value: break1000,
		},
		{
			name: '1144',
			value: break1144,
		},
		{
			name: '1280',
			value: break1280,
		},
	]

	let styleAttributeContent = ''
	breakpoints.forEach((breakpoint) => {
		styleAttributeContent += `--spacer-break-${breakpoint.name}: ${breakpoint.value};`
	})
</script>

<div class="spacer" style={styleAttributeContent} />
