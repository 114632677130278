<script>
	import { emailPattern } from '../utils/emailPattern'
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import { postSignIn } from '../api/account'
	import Button from './Button.svelte'
	import Collapsible from './Collapsible.svelte'
	import FormError from './FormError.svelte'
	import FormRow from './FormRow.svelte'
	import LineSeparator from './LineSeparator.svelte'
	import PasswordInput from './PasswordInput.svelte'
	import Spacer from './Spacer.svelte'
	import TextInput from './TextInput.svelte'
	import {translate} from "../utils/translations";

	export let compact = false

	export let signUpLink = undefined
	export let passwordResetRequestLink = undefined

	export let onSignIn = () => {
		// @TODO success message / redirect
		location.reload()
	}

	let values = { email: '', password: '' }
	let errors = {}

	let formElement = undefined

	function handleChange(event) {
		values[event.target.name] = event.target.value
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function handleSubmit() {
		const response = await postSignIn(values.email, values.password)

		if (!response.ok) {
			errors = response.errors
		} else {
			onSignIn()
		}
	}
</script>

<form on:submit|preventDefault={handleSubmit} class="signInForm" method="post" bind:this={formElement}>
	<Collapsible expanded={errors.general}>
		{#if errors.general}
			{#each errors.general as error}
				<FormError message={error} view="standalone" asHtml />
			{/each}
			<Spacer initial="16" />
		{/if}
	</Collapsible>

	<FormRow columnCount={compact ? 2 : 1}>
		<TextInput
			type="email"
			name="email"
			value={values.email}
			on:change={handleChange}
			errorMessage={errors.email?.join(';')}
			label="E-mail"
			autocomplete=""
			required
			pattern={emailPattern}
		/>

		<PasswordInput
			value={values.password}
			errorMessage={errors.password?.join(';')}
			on:change={handleChange}
			autocomplete="current-password"
		/>
	</FormRow>
	<FormRow columnCount={compact ? 2 : 1}>
		<Button on:click={submitButtonClick} type="submit" startIcon="legacy-account-anonymous" size="formItem">{translate('app.signIn.title')}</Button>
		<a class="signInForm-link" href={passwordResetRequestLink}>{translate('app.signIn.forgotPassword')}</a>
	</FormRow>

	{#if signUpLink}
		<Spacer initial="48" />
		<LineSeparator text={translate('app.signIn.noAccount')} />
		<Spacer initial="24" />
		<FormRow>
			<Button type="link" startIcon="cart" link={signUpLink} variant="outlined" condense>{translate('app.signIn.signUp')}</Button>
		</FormRow>
	{/if}
</form>
