<script>
	import { emailPattern } from '../utils/emailPattern'
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import { onMount } from 'svelte'
	import { fetchPersonalInfo, putPersonalInfo } from '../api/account'
	import { fetchCountryList } from '../api/address'
	import Button from './Button.svelte'
	import Collapsible from './Collapsible.svelte'
	import FlashMessage from './FlashMessage.svelte'
	import FormRow from './FormRow.svelte'
	import FancyPhoneInput from './FancyPhoneInput.svelte'
	import Radio from './Radio.svelte'
	import Spacer from './Spacer.svelte'
	import TextInput from './TextInput.svelte'
	import Title from './Title.svelte'
	import {translate} from "../utils/translations";

	let values = {}
	let disableInputs = false
	let successMessage = undefined

	const userPersonalInfo = undefined

	const genderItems = [
		{
			value: 'male',
			label: 'Muž',
		},
		{
			value: 'female',
			label: 'Žena',
		},
	]

	let countryOptions = null
	let defaultPhoneCountryCode = null

	let formElement = undefined

	onMount(async () => {
		countryOptions = (await fetchCountryList()).map(({ id, name }) => ({ value: id, label: name }))
		defaultPhoneCountryCode = countryOptions[0].label === 'Slovensko' ? 'sk' : 'cz'
	})

	async function fetchUserPersonalInfo() {
		disableInputs = true
		const response = await fetchPersonalInfo()
		if(response && response.user) {
			values = {...response.user}
		}
		disableInputs = false
	}
	fetchUserPersonalInfo()

	function handleChange(event) {
		const { name, value, type, checked } = event.target
		values[name] = type === 'checkbox' ? checked : value
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function handleSubmit() {
		const response = await putPersonalInfo(
			values.firstName,
			values.lastName,
			values.phone || '',
			values.gender,
		)

		if (response.successMessage) {
			successMessage = response.successMessage
		}
	}
</script>

<div class="userPersonalSetting">
	<Title level="5">Osobní údaje</Title>
	<Spacer initial="16" />

	<form on:submit|preventDefault={handleSubmit} method="post" bind:this={formElement}>
		<Collapsible expanded={!!successMessage}>
			{#if successMessage}
				<FlashMessage type="success">{successMessage}</FlashMessage>
				<Spacer initial="16" />
			{/if}
		</Collapsible>
		<FormRow columnCount={4}>
			<TextInput
				name="firstName"
				value={values.firstName}
				on:change={handleChange}
				label={translate('app.formInputs.firstName')}
				required
				autocomplete="given-name"
				disabled={disableInputs}
			/>
			<TextInput
				name="lastName"
				value={values.lastName}
				on:change={handleChange}
				label={translate('app.formInputs.lastName')}
				required
				autocomplete="family-name"
				disabled={disableInputs}
			/>
		</FormRow>
		<FormRow columnCount={2}>
			<FancyPhoneInput phone={values?.phone || ''} defaultCountryCode={defaultPhoneCountryCode} onChange={val => {
				values.phone = val.replace(/\s+/g, '')
			}} />
		</FormRow>

		<FormRow columnCount={2}>
			<TextInput
				type="email"
				name="email"
				value={values?.email}
				on:change={handleChange}
				label="E-mail"
				required
				disabled
				pattern={emailPattern}
			>
				<slot slot="note">Díky e-mailu propojíme vaše objednávky, reklamace a dotazy</slot>
			</TextInput>
		</FormRow>

		<Spacer initial="32" />

		<FormRow>
			<Radio
				items={genderItems}
				checkedValue={values?.gender}
				name="gender"
				view="block"
				{handleChange}
			/>
			<Spacer initial="16" />
		</FormRow>

		<FormRow>
			<div class="userPersonalSetting-button">
				<Button on:click={submitButtonClick} type="submit">Uložit</Button>
			</div>
		</FormRow>
	</form>
</div>
