<script>
	import Box from './Box.svelte'
	import OptionInput from './OptionInput.svelte'
	import Picture from './Picture.svelte'
	import Spacer from './Spacer.svelte'

	export let name
	export let items
	export let onChange = undefined
	export let onClick = undefined
	export let address = undefined
</script>

<div class="checkboxList">
	{#each items as item, i}
		<Box>
			<OptionInput
				on:change={(event) => onChange?.(item, event.target.checked)}
				on:click={onClick}
				viewType="checkbox"
				type="radio"
				value={i}
				{name}
				disabled={item.disabled || false}
			>
				<slot slot="label">{item.label}</slot>
				<slot name="main" slot="main" {item}>
					{#if item.image}
						<div
							class="checkboxList-image"
							style="--checkboxList-image-width: {item.image.width}px"
						>
							<Picture image={item.image} />
						</div>
					{/if}
					{#if item.description}
						<Spacer initial="6" break768="16" />
						{@html item.description}
					{/if}
					{#if item.isAddressRequired && address?.postalCode}
						<Spacer initial="6" break768="16"/>
						Pro PSČ {address?.postalCode}
					{/if}
				</slot>
				<slot slot="aside">
					{#if item.price}
						{item.price.formatted || item.price}
					{/if}
				</slot>
			</OptionInput>
		</Box>
	{/each}
</div>
