<script>
	import {focusInvalidInput} from '../utils/focusInvalidInput'
	import Button from './Button.svelte'
	import FormRow from './FormRow.svelte'
	import Select from './Select.svelte'
	import Spacer from './Spacer.svelte'
	import TextInput from './TextInput.svelte'
	import {translate} from "../utils/translations";

	export let country = null
	export let postalCode = null
	export let countryOptions
	export let address

	let pattern = null

	let formElement = undefined

	if (address && !country ){
		country = address.country
	}
	if (address && !postalCode) {
		postalCode = address.postalCode
	}
	if (!country && countryOptions.length === 1) {
		country = {id: countryOptions[0].value, name: countryOptions[0].label}
	}

	$: {
		if(country) {
			const startingDigit = country.name === 'Česko' ? "[1-7]" : (country.name === 'Slovensko' ? "[0,8,9]" : "[0-9]")
			pattern = `${startingDigit}[0-9]{2} ?[0-9]{2}`
		}
	}

	function handlePostalCodeChange(event) {
		postalCode = event.target.value
	}

	function handleCountryChange(event) {
		const option = countryOptions.find((option) => option.value === event.target.value)
		country = {id: option.value, name: option.label}
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}
</script>

<form on:submit|preventDefault method="post" bind:this={formElement}>
	<FormRow columnCount={2}>
		<TextInput
			type="text"
			name="postalCode"
			value={postalCode}
			on:change={handlePostalCodeChange}
			label={translate('app.formInputs.postalCode')}
			required
			pattern={pattern}
			autocomplete="postal-code"
		/>
		<Select
			name="country"
			options={countryOptions}
			value={country?.id}
			on:change={handleCountryChange}
			required
			label={translate('app.formInputs.country')}
			autocomplete="country"
		/>
	</FormRow>
	<Spacer initial="24"/>
	<Button on:click={submitButtonClick} type="submit" endIcon="arrow-right" condense={true}>
		{translate('app.checkout.shippingAddress.deliverHere')}
	</Button>
</form>

