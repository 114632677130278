<script>
	import {computeSrc, computeSrcSet} from "../utils/imageSrc";

	/**
	 * @typedef {Object} Image
	 * @property {string} url
	 * @property {number} width
	 * @property {number} height
	 */


	/**
	 * @type {Image}
	 */
	export let image

	export let sizes = '100vw'

	export let media = undefined

	export let fit = undefined

	export let alt = ''

	export let loading = 'lazy'

	export let ratio = image && image.height && image.width ? image.width / image.height : undefined

	$: srcset = image ? computeSrcSet({
		url: image.url,
		fit,
		ratio,
		sizes,
		format: 'webp',
	}) : undefined
	$: srcsetFallback = image ? computeSrcSet({
		url: image.url,
		fit,
		ratio,
		sizes,
	}) : undefined

	// for IE BC
	$: imgWidth = Math.min(1600, image && image.width ? image.width : 1600)
	$: imgHeight = ratio ? Math.round(imgWidth / ratio) : undefined
	$: imgSrc = image ? computeSrc({
		url: image.url,
		width: imgWidth,
		fit,
		ratio,
	}) : undefined
</script>

{#if image}
	<picture class="picture" style="
		--picture-width: {image.width};
		--picture-height: {image.height};
	">
		{#if srcset}
			<source {sizes} {media} {srcset} type="image/webp" />
		{/if}
		{#if srcsetFallback}
			<source {sizes} {media} srcset={srcsetFallback} />
		{/if}
		<img
			on:load
			class="picture-image"
			src={imgSrc}
			width={imgWidth}
			height={imgHeight}
			loading={loading}
			{alt}
		/>
	</picture>
{/if}
