<script>
	import {focusInvalidInput} from '../utils/focusInvalidInput'
	import {emailPattern} from '../utils/emailPattern'
	import Spacer from './Spacer.svelte'
	import FormRow from './FormRow.svelte'
	import TextInput from './TextInput.svelte'
	import Button from './Button.svelte'
	import {putAvailabilityInquiry} from '../api/cart'
	import {genericPhoneFormat} from "../utils/phone";
	import FlashMessage from './FlashMessage.svelte'
	import ReservationSuccess from "./ReservationSuccess.svelte";
	import {translate} from "../utils/translations";
	import Select from "./Select.svelte";

	export let variantId
	export let variantName
	export let variantSize
	export let stores = []
	export let values = {}

	let result = null

	let formElement = undefined

	$: storeOptions = stores.map((it) => ({ value: it.id, label: it.name }))
	$: selectedStore = stores.find((it) => it.id === values.store)

	function handleChange(event) {
		values[event.target.name] = event.target.value
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function handleSubmit(event) {
		const payload = await putAvailabilityInquiry(
			variantId,
			values.firstName,
			values.lastName,
			values.email,
			values.phone,
			values.note,
			values.store,
		)

		result = payload.ok
	}
</script>
<form on:submit|preventDefault={handleSubmit} method="post" bind:this={formElement}>
	<div class="reservationForm">
		<div class="reservationForm-subtitle">
			<div class="reservationForm-productName">
				{variantName}
			</div>

			{#if variantSize}
				<div class="reservationForm-size">Velikost: {variantSize}</div>
			{/if}
		</div>

		{#if result === null}
			<Spacer initial="32" />

			<FormRow columnCount={1}>
				<Select
					name="store"
					value={values.store}
					on:change={handleChange}
					label="Vyberte pobočku"
					options={storeOptions}
					required
				/>
			</FormRow>

			<FormRow columnCount={2}>
				<TextInput
					name="firstName"
					value={values.firstName}
					on:change={handleChange}
					label={translate('app.formInputs.firstName')}
					required
					autocomplete="given-name"
				/>
				<TextInput
					name="lastName"
					value={values.lastName}
					on:change={handleChange}
					label={translate('app.formInputs.lastName')}
					required
					autocomplete="family-name"
				/>
			</FormRow>

			<FormRow columnCount={2}>
				<TextInput
					type="email"
					name="email"
					value={values.email}
					on:change={handleChange}
					label="E-mail"
					required
					autocomplete="email"
					pattern={emailPattern}
				/>
				<TextInput
					type="tel"
					name="phone"
					value={values.phone}
					on:change={handleChange}
					label={translate('app.formInputs.phone')}
					required
					autocomplete="tel"
					pattern={genericPhoneFormat}
				/>
			</FormRow>

			<FormRow>
				<TextInput
					type="textarea"
					name="note"
					value={values.name}
					on:change={handleChange}
					label="Poznámka"
					maxLength="250"
				>
					<slot slot="note">
						{translate('app.form.note.note')}
					</slot>
				</TextInput>
			</FormRow>

			<Spacer initial="16" />

			<div class="reservationForm-gdpr">
				{@html translate('app.form.reservationPrivacyPolicyConsent')}
			</div>

			<Spacer initial="40" />

			<div class="reservationForm-footer">
				<Button on:click={submitButtonClick} type="submit" endIcon="legacy-arrow-right">Odeslat dotaz</Button>
			</div>
		{:else if result === true}
			<Spacer initial="24" />
			<ReservationSuccess personContact={values}>
				<slot slot="message">
					Dotaz byl úspěšné odeslán. Co nejdříve se vám ozveme.
				</slot>
			</ReservationSuccess>
		{:else}
			<Spacer initial="24" />
			<FlashMessage type="error">Dotaz se nepodařilo odeslat.</FlashMessage>
		{/if}
	</div>
</form>
