<script>
	import clsx from 'clsx'
	import { getColorLuminosity } from '../utils/isLightOrDarkColor'

	export let options
	export let availableOptions
	export let checkedOptions
	export let name
	export let onToggle = () => {}
</script>

<div class="colorOptions">
	{#each Object.entries(options) as [key, value]}
		<div class={clsx("colorOptions-item", `view-luminosity-${getColorLuminosity(value.colorHex ?? '#fff')}`, availableOptions.indexOf(key) === -1 ? 'view-disabled' : '')}>
			<label class="colorOptions-control" style={`--colorOptions-item-color: ${value.colorHex ?? '#fff'}`}>
				<input
					class="colorOptions-input"
					type="checkbox"
					name={`${name}[]`}
					value={key}
					checked={checkedOptions?.indexOf(key) !== -1}
					disabled={availableOptions.indexOf(key) === -1}
					on:change={e => onToggle(name, key, e.target.checked)}
				/>
				<span class="colorOptions-button" aria-label={value.label} />
			</label>
		</div>
	{/each}
</div>
