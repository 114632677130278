<script>
	import Box from './Box.svelte'
	import Collapsible from './Collapsible.svelte'
	import Map from './Map.svelte'
	import OptionInput from './OptionInput.svelte'
	import RenderContentIfVisible from './RenderContentIfVisible.svelte'
	import Spacer from './Spacer.svelte'
	import DpdPlaceDetails from "./DpdPlaceDetails.svelte"
	import TextInput from "./TextInput.svelte"
	import { tick } from 'svelte'

	export let mapPinIcon

	export let venues = []
	export let selected = null

	let filter = ""
	let mapZoom = 7
	let elements = {}
	let scrollableContainer

	const mapMarkers = venues.map((venue) => {
		return {
			title: venue.company,
			latitude: venue.latitude,
			longitude: venue.longitude,
			icon: mapPinIcon,
			onClick: () => handleChange(venue),
			small: true,
		}
	})

	let mapCenter = {
		latitude: 50.08291317108088,
		longitude: 14.421589136244334,
	}

	async function handleChange(venue) {
		selected = venue

		mapCenter = { latitude: selected.latitude, longitude: selected.longitude }
		mapZoom = 14

		await tick()

		// @TODO scroll into view after collapsible animation
		setTimeout(() => {
			elements[selected.id].scrollIntoView({ behavior: 'smooth' })
		}, 300)
	}

	function handleFilerChange(event) {
		filter = event.target.value
		scrollableContainer.scrollTo({ top: 0, left: 0 })
	}

	$: filteredVenues = venues.filter(venue => {
		if (filter.length < 2) {
			return true
		}
		const filterWithoutWhitespace = filter.replaceAll(/\s/g, '')
		const postcode = venue.postcode.startsWith(filterWithoutWhitespace)
		const lowercaseFilter = filter.trim().toLowerCase()
		const city = venue.city.toLowerCase().startsWith(lowercaseFilter)
		const company = venue.company.toLowerCase().includes(lowercaseFilter)
		return postcode || city || company
	}).slice(0, 50)

	$: venuesToShow = selected !== null && !filteredVenues.includes(selected) ? [...filteredVenues, selected] : filteredVenues
</script>

<div class="venueOptions view-scrollable">
	<div class="venueOptions-left">
		<TextInput label="Hledat místo (podle PSČ nebo města)" value={filter} on:input={handleFilerChange} />
		<div class="venueOptions-items" bind:this={scrollableContainer}>
			{#each venuesToShow as venue (venue.id)}
				<Box>
					<div class="venueOptions-item" bind:this={elements[venue.id]}>
						<OptionInput
							type="radio"
							name="venues"
							on:change={() => handleChange(venue)}
							checked={venue === selected}
						>
							<slot slot="label">{venue.company}</slot>
							<div class="venueOptions-item-content" slot="main">
								<div>
									<Spacer initial="6" break768="16" />
									{venue.street} {venue.house_number},
									{venue.city},
									{venue.postcode}

									<Collapsible expanded={venue === selected} transitionType="topInsert">
										<Spacer initial="12" />
										<DpdPlaceDetails venue={venue} {mapPinIcon}>
											<slot name="button" slot="button" item={venue} />
										</DpdPlaceDetails>
									</Collapsible>
								</div>
							</div>
						</OptionInput>
					</div>
				</Box>
			{/each}
		</div>
	</div>
	<div class="venueOptions-map">
		<RenderContentIfVisible>
			<Map center={mapCenter} markers={mapMarkers} zoom={mapZoom} />
		</RenderContentIfVisible>
	</div>
</div>
