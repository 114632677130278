<script>
	import {onMount} from 'svelte'
	import {fetchAddressList, fetchCountryList, putAddress} from '../api/address'
	import {fetchCart, putOrder, putPaymentInfo, putShippingInfo} from '../api/cart'
	import {focusInvalidInput} from '../utils/focusInvalidInput'
	import {cart} from '../stores/cartStore'
	import Box from './Box.svelte'
	import Button from './Button.svelte'
	import CartSummary from './CartSummary.svelte'
	import CheckoutBillingAddress from './CheckoutBillingAddress.svelte'
	import CheckoutLayout from './CheckoutLayout.svelte'
	import CheckoutNavigationRow from './CheckoutNavigationRow.svelte'
	import CheckoutRow from './CheckoutRow.svelte'
	import CheckoutShippingAddress from './CheckoutShippingAddress.svelte'
	import CheckoutSignInForm from './CheckoutSignInForm.svelte'
	import CheckoutSignUpForm from './CheckoutSignUpForm.svelte'
	import IconList from './IconList.svelte'
	import OptionInput from './OptionInput.svelte'
	import PriceSummary from './PriceSummary.svelte'
	import Spacer from './Spacer.svelte'
	import TextInput from './TextInput.svelte'
	import Title from './Title.svelte'
	import CheckoutWarnings from "./CheckoutWarnings.svelte"
	import FlashMessage from "./FlashMessage.svelte";
	import {translate} from "../utils/translations";

	export let signUpLink
	export let passwordResetRequestLink
	export let termsAndConditionsLink
	export let heurekaLink
	export let loyaltyProgramLink
	export let showSecondaryVatId = false
	export let isUserLogged

	let addressOptions = null
	let billingCountryOptions = null
	let shippingAddressSameExplicit = null
	let shippingAddressSameDefault = true
	let note = ''

	let orderError = ''

	let questionnaireDisagree = false
	let marketingDisgree = false

	let formElement = undefined
	let submitFired = false

	$: shippingCountryOptions = $cart?.shippingMethod.countries.map(({id, name}) => ({value: id, label: name}))
	$: shippingAddressSameAllowed = shippingCountryOptions?.some(option => option.value === $cart?.billingAddress?.country.id) && $cart.shippingMethod?.type === 'delivery'
	$: shippingAddressSameEffective = shippingAddressSameAllowed && (shippingAddressSameExplicit ?? shippingAddressSameDefault)

	const accountBenefits = [
		{
			icon: 'percentage',
			text: translate('app.account.benefits.discount'),
		},
		{
			icon: 'maintenance',
			text: translate('app.account.benefits.maintenance'),
		},
		{
			icon: 'heart-outline',
			text: translate('app.account.benefits.favorites'),
		},
		{
			icon: 'rollback',
			text: translate('app.account.benefits.rollback'),
		},
	]

	onMount(async () => {
		addressOptions = await fetchAddressList()
		billingCountryOptions = (await fetchCountryList()).map(({id, name}) => ({value: id, label: name}))
	})

	async function putAddressObj(address) {
		return await putAddress({
			...address,
			id: undefined,
			addressId: address.id,
			country: undefined,
			countryId: address.country.id,
			gpsLat: undefined,
			gpsLng: undefined,
		})
	}

	async function handleSignIn() {
		$cart = await fetchCart()
		shippingAddressSameDefault = !$cart?.shippingAddress || $cart.shippingAddress.id === $cart.billingAddress?.id
		addressOptions = await fetchAddressList()
	}

	function onNoteChange(event) {
		note = event.target.value
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function handleSubmit() {
		submitFired = true
		orderError = ''

		if ($cart.billingAddress === null) {
			orderError = 'Vyberte fakturační adresu.'
			submitFired = false
			window.scrollTo(0, 0)
		} else {
			$cart.billingAddress = await putAddressObj($cart.billingAddress)

			if (!shippingAddressSameEffective && shippingAddressSameAllowed) {
				$cart.shippingAddress.id = ($cart.shippingAddress.id === $cart.billingAddress.id) ? null : $cart.shippingAddress.id
				$cart.shippingAddress = await putAddressObj($cart.shippingAddress)
			}

			const expectedTotalPriceCents = $cart.totalPrice.cents;
			await putPaymentInfo($cart.paymentMethod?.code ?? null, $cart.billingAddress.id)
			$cart = await putShippingInfo($cart.shippingMethod?.code ?? null, shippingAddressSameEffective ? $cart.billingAddress.id : $cart.shippingAddress.id)

			await putOrder(note, expectedTotalPriceCents, !questionnaireDisagree, !marketingDisgree)
				.then((response) => {
					if (response.ok) {
						window.location.href = response.redirect
					} else {
						orderError = response.errorMessage
						window.scrollTo(0, 0)
					}
				})
				.catch(() => {
					orderError = "Objednávku se nepodařilo vytvořit."
					submitFired = false
					window.scrollTo(0, 0)
				})
		}
	}
</script>

<CheckoutLayout>
	<slot slot="main">
		{#if $cart && shippingCountryOptions && billingCountryOptions && addressOptions}
			<Title level="2" viewLevel="5">Vaše údaje</Title>
			<Spacer initial="24" />
			{#if orderError}
				<FlashMessage type="error">{orderError}</FlashMessage>
				<Spacer initial="24" />
			{/if}
			<CheckoutWarnings />

			{#if $cart.user.isSignedIn}
				<CheckoutRow condense>
					Jste přihlášen/a jako {$cart.user.user.firstName} {$cart.user.user.lastName} ({$cart.user.user.email})
				</CheckoutRow>
			{:else}
				<CheckoutSignInForm onSignIn={handleSignIn} {passwordResetRequestLink} />
				<CheckoutSignUpForm {loyaltyProgramLink} />
				<Spacer initial="16" />
			{/if}

			<form on:submit|preventDefault={handleSubmit} bind:this={formElement}>
				<CheckoutBillingAddress bind:cart={$cart} countryOptions={billingCountryOptions} bind:addressOptions {showSecondaryVatId} />

				{#if $cart.shippingMethod?.type === 'delivery'}
					<CheckoutShippingAddress
						bind:cart={$cart}
						countryOptions={shippingCountryOptions}
						bind:addressOptions
						bind:shippingAddressSameExplicit
						{shippingAddressSameAllowed}
						{shippingAddressSameEffective}
					/>
				{/if}

				<CheckoutRow condense>
					<TextInput type="textarea" name="note" value={note} on:change={onNoteChange} maxLength="250" label={translate('app.form.label.note')}>
						<slot slot="note">
							{translate('app.form.note.note')}
						</slot>
					</TextInput>
				</CheckoutRow>

				<Spacer initial="24" />

				<OptionInput type="checkbox" name="questionnaireDisagree" checked={questionnaireDisagree} on:change={e => questionnaireDisagree = e.target.checked}>
					<slot slot="label">{translate('app.checkout.consent.heurekaPart1')} <a href={heurekaLink}>Heureka</a> - {translate('app.checkout.consent.heurekaPart2')}.</slot>
				</OptionInput>
				<Spacer initial="16" />
				{#if !isUserLogged}
				<OptionInput type="checkbox" name="marketingDisgree" checked={marketingDisgree} on:change={e => marketingDisgree = e.target.checked}>
					<slot slot="label">{translate('app.checkout.consent.marketing')}</slot>
				</OptionInput>
				<Spacer initial="16" />
				{/if}
				<OptionInput type="checkbox" name="conditionsAgree" required>
					<slot slot="label">{translate('app.checkout.consent.termsPart1')} <a href={termsAndConditionsLink} target="_blank">{translate('app.checkout.consent.termsPart2')}</a></slot>
				</OptionInput>

				<Spacer initial="24" />
				<CheckoutNavigationRow>
					<slot slot="center">
						<PriceSummary
							totalPrice={$cart.totalPrice.formatted}
							vatPrice={$cart.totalPrice.vatFormatted}
						/>
					</slot>
					<slot slot="right">
						<Button on:click={submitButtonClick} type="submit" size="large" condense endIcon="legacy-arrow-right" disabled={!$cart.canBeSubmitted || submitFired}>
							<!-- @TODO: zkontrolovat, že text tlačítka se mění správně pro různé druhy platby -->
							{['DO', 'DOSK', 'MO_HOT', 'HOT_MP', 'ZALOHA'].includes($cart.paymentMethod.code) ? translate('app.checkout.buttons.submit.finish') : translate('app.checkout.buttons.submit.pay')}
						</Button>
					</slot>
				</CheckoutNavigationRow>
			</form>
		{/if}
	</slot>

	<slot slot="aside">
		{#if $cart}
			{#if $cart.items}
				<Title level="2" viewLevel="5">{translate('app.checkout.cart.content')}</Title>
				<Spacer initial="24" />
				<CartSummary
					items={$cart.items}
					otherItems={[$cart.shippingMethod, $cart.paymentMethod, ...$cart.vouchers]}
					totalPrice={$cart.totalPrice}
				/>
				<Spacer initial="40" />
			{/if}

			<Title level="2" viewLevel="5">{translate('app.signUp.benefitsAlt')}</Title>
			<Spacer initial="16" />
			<Box condense>
				<IconList items={accountBenefits} />
				{#if !$cart.user.isSignedIn}
					<Spacer initial="24" />
					<a href={signUpLink}>{translate('app.signUp.createButton')}</a>
				{/if}
			</Box>
		{/if}
	</slot>
</CheckoutLayout>
