<script>
	import { onMount } from 'svelte'
	import { putOrderReservation } from '../api/cart'
	import { emailPattern } from '../utils/emailPattern'
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import { genericPhoneFormat } from '../utils/phone'
	import { translate } from '../utils/translations'
	import Button from './Button.svelte'
	import FlashMessage from './FlashMessage.svelte'
	import FormRow from './FormRow.svelte'
	import OptionInput from './OptionInput.svelte'
	import ReservationSuccess from './ReservationSuccess.svelte'
	import Select from './Select.svelte'
	import Spacer from './Spacer.svelte'
	import TextInput from './TextInput.svelte'

	export let variantId
	export let variantName
	export let variantSize
	export let heurekaLink
	export let stores = []
	export let values = {}

	let result = null
	let privacyPolicy = translate('app.form.reservationPrivacyPolicyConsent')
	let questionnaireDisagree = false

	let formElement = undefined
	let submitFired = false

	$: storeOptions = stores.filter((it) => it.quantity > 0).map((it) => ({ value: it.id, label: it.name }))

	$: selectedStore = stores.find((it) => it.id === values.store)

	function handleChange(event) {
		values[event.target.name] = event.target.value
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function handleSubmit(event) {
		result = null
		submitFired = true
		const payload = await putOrderReservation(
			variantId,
			values.store,
			values.firstName,
			values.lastName,
			values.email,
			values.phone,
			values.note,
			!questionnaireDisagree,
		).catch(() => {
			submitFired = false
			result = false
		})

		result = payload.ok
	}

	onMount(() => {
		if (values.store === undefined && storeOptions.length > 0) {
			values.store = storeOptions[0].value
		}
	})
</script>

<form on:submit|preventDefault={handleSubmit} method="post" bind:this={formElement}>
	<div class="reservationForm">
		<div class="reservationForm-subtitle">
			<div class="reservationForm-productName">
				{variantName}
			</div>

			{#if variantSize}
				<div class="reservationForm-size">Velikost: {variantSize}</div>
			{/if}
		</div>

		{#if result === null || result === false}
			{#if result === false}
				<Spacer initial="32" />
				<FlashMessage type="error">Rezervaci se nepodařilo vytvořit.</FlashMessage>
			{/if}
			<Spacer initial="32" />
			<FormRow columnCount={1}>
				<Select
					name="store"
					value={values.store}
					on:change={handleChange}
					label="Vyberte pobočku"
					options={storeOptions}
				/>
			</FormRow>

			<FormRow columnCount={2}>
				<TextInput
					name="firstName"
					value={values.firstName}
					on:change={handleChange}
					label={translate('app.formInputs.firstName')}
					required
					autocomplete="given-name"
				/>
				<TextInput
					name="lastName"
					value={values.lastName}
					on:change={handleChange}
					label={translate('app.formInputs.lastName')}
					required
					autocomplete="family-name"
				/>
			</FormRow>

			<FormRow columnCount={2}>
				<TextInput
					type="email"
					name="email"
					value={values.email}
					on:change={handleChange}
					label="E-mail"
					required
					autocomplete="email"
					pattern={emailPattern}
				/>
				<TextInput
					type="tel"
					name="phone"
					value={values.phone}
					on:change={handleChange}
					label={translate('app.formInputs.phone')}
					required
					autocomplete="tel"
					pattern={genericPhoneFormat}
				/>
			</FormRow>

			<FormRow>
				<TextInput
					type="textarea"
					name="note"
					value={values.name}
					on:change={handleChange}
					label="Poznámka"
					maxLength="250"
				>
					<slot slot="note">{translate('app.form.note.note')}</slot>
				</TextInput>
			</FormRow>

			<Spacer initial="16" />

			<OptionInput
				type="checkbox"
				name="questionnaireDisagree"
				checked={questionnaireDisagree}
				on:change={(e) => (questionnaireDisagree = e.target.checked)}
			>
				<slot slot="label"
					>{translate('app.checkout.consent.heurekaPart1')} <a href={heurekaLink}>Heureka</a> - {translate(
						'app.checkout.consent.heurekaPart2',
					)}.</slot
				>
			</OptionInput>

			<Spacer initial="16" />

			<div class="reservationForm-additionalInfo">
				{translate('app.checkout.reservationForm.additionalInfo')}
			</div>

			<Spacer initial="16" />

			<div class="reservationForm-gdpr">
				{@html privacyPolicy}
			</div>

			<Spacer initial="40" />

			<div class="reservationForm-footer">
				<Button on:click={submitButtonClick} type="submit" endIcon="legacy-arrow-right" disabled={submitFired}
					>Odeslat rezervaci</Button
				>
			</div>
		{:else if result === true}
			<Spacer initial="24" />
			<ReservationSuccess store={selectedStore} personContact={values}>
				<slot slot="message">
					Rezervace byla úspěšné odeslána. Jakmile bude připravena na prodejně, dáme vám vědět emailem nebo
					sms.
				</slot>
			</ReservationSuccess>
		{:else}
			<Spacer initial="24" />
			<FlashMessage type="error">Nastala neočekávaná chyba. Rezervaci se nepodařilo vytvořit.</FlashMessage>
		{/if}
	</div>
</form>
