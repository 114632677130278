<script>
	import { emailPattern } from '../utils/emailPattern'
	import FormRow from './FormRow.svelte'
	import OptionInput from './OptionInput.svelte'
	import OptionInputWrap from './OptionInputWrap.svelte'
	import Select from './Select.svelte'
	import TextInput from './TextInput.svelte'
	import Collapsible from './Collapsible.svelte'
	import Spacer from './Spacer.svelte'
	import FancyPhoneInput from "./FancyPhoneInput.svelte"
	import {translate} from "../utils/translations";

	export let address = null
	export let countryOptions
	export let hideEmail = false
	export let showCompany = false
	export let showSecondaryVatId = false

	let postalCodePattern = null
	let defaultPhoneCountryCode = countryOptions[0].label === 'Slovensko' ? 'sk' : 'cz'

	let buyingForCompany = !!address?.companyIdentifier

	if (address === null) {
		address = {country: {id: countryOptions[0].value, name: countryOptions[0].label}}
	}

	function handleChange(event) {
		address[event.target.name] = event.target.value
	}

	function handleCountryChange(event) {
		const option = countryOptions.find((option) => option.value === event.target.value)
		address[event.target.name] = {id: option.value, name: option.label}
	}

	function handleCompanyAddressChange(event) {
		buyingForCompany = event.target.checked
	}

	$: {
		if (address && address.country) {
			const startingDigit = address.country.name === 'Česko' ? "[1-7]" : (address.country.name === 'Slovensko' ? "[0,8,9]" : "[0-9]")
			postalCodePattern = `${startingDigit}[0-9]{2} ?[0-9]{2}`
		}
	}
</script>

<FormRow columnCount={2}>
	<TextInput
		name="firstName"
		value={address?.firstName}
		on:change={handleChange}
		label={translate('app.formInputs.firstName')}
		required
		autocomplete="given-name"
	/>
	<TextInput
		name="lastName"
		value={address?.lastName}
		on:change={handleChange}
		label={translate('app.formInputs.lastName')}
		required
		autocomplete="family-name"
	/>
</FormRow>

{#if !hideEmail}
	<FormRow>
		<TextInput
			type="email"
			name="email"
			value={address?.email}
			on:change={handleChange}
			label="E-mail"
			maxLength="80"
			required
			autocomplete="email"
			pattern={emailPattern}
		/>
	</FormRow>
{/if}

<FancyPhoneInput defaultCountryCode={defaultPhoneCountryCode} phone={address?.phone || ''} onChange={val => {
	address.phone = val.replace(/\s+/g, '')
}} />

<FormRow>
	<TextInput
		type="text"
		name="addressLine1"
		maxLength="50"
		value={address?.addressLine1}
		on:change={handleChange}
		label={translate('app.formInputs.addressLine1')}
		required
		autocomplete="address-line1"
	/>
</FormRow>

<FormRow columnCount={2}>
	<TextInput
		type="text"
		name="city"
		maxLength="30"
		value={address?.city}
		on:change={handleChange}
		label={translate('app.formInputs.city')}
		required
		autocomplete="address-level2"
	/>
	<TextInput
		type="text"
		name="postalCode"
		value={address?.postalCode}
		maxLength="20"
		on:change={handleChange}
		label={translate('app.formInputs.postalCode')}
		pattern={postalCodePattern}
		required
		autocomplete="postal-code"
	/>
</FormRow>

<FormRow>
	<Select
		name="country"
		options={countryOptions}
		value={address?.country?.id}
		on:change={handleCountryChange}
		required
		label={translate('app.formInputs.country')}
		autocomplete="country"
	/>
</FormRow>

{#if showCompany}
	<FormRow>
		<OptionInputWrap>
			<OptionInput type="checkbox" name="buying-for-company" checked={buyingForCompany} on:change={handleCompanyAddressChange}>
				<slot slot="label">{translate('app.checkout.billingAddress.companyOrder')}</slot>
			</OptionInput>
		</OptionInputWrap>
	</FormRow>

	<Collapsible expanded={buyingForCompany}>
		<Spacer initial="16" />

		<FormRow>
			<TextInput
				type="text"
				name="companyName"
				value={address?.companyName}
				maxLength="50"
				on:change={handleChange}
				label={translate('app.formInputs.companyName')}
			/>
		</FormRow>

		<FormRow columnCount={showSecondaryVatId ? 3 : 2}>
			<TextInput
				type="text"
				name="companyIdentifier"
				maxLength="20"
				value={address?.companyIdentifier}
				on:change={handleChange}
				label={translate('app.formInputs.companyIdentifier')}
				required={buyingForCompany}
			/>
			<TextInput
				type="text"
				name="vatIdentifier"
				maxLength="20"
				value={address?.vatIdentifier}
				on:change={handleChange}
				label={translate('app.formInputs.vatIdentifier')}
			/>
			{#if showSecondaryVatId}
				<TextInput
					type="text"
					name="vatIdentifierSecondary"
					value={address?.vatIdentifierSecondary}
					on:change={handleChange}
					label={translate('app.formInputs.vatIdentifierSecondary')}
				/>
			{/if}
		</FormRow>
	</Collapsible>
{/if}
