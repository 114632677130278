<script>
	import clsx from 'clsx'
	import intlTelInput from 'intl-tel-input'
	import { onDestroy, onMount } from 'svelte'
	import { formatPhoneNumber, genericPhoneFormat, splitPhoneNumber } from '../utils/phone'
	import { translate } from '../utils/translations'
	import { uniqueId } from '../utils/uniqueId'
	import Collapsible from './Collapsible.svelte'
	import FormError from './FormError.svelte'
	import FormRow from './FormRow.svelte'

	export let phone = ''
	export let onChange
	export let defaultCountryCode = phone.startsWith('+421') ? 'sk' : 'cz'

	let phoneValue = phone

	let isEmpty = phone.length === 0
	let isFocused = false

	let inputId = uniqueId('input-')
	let inputElement
	let iti = undefined
	let phoneParts = undefined
	let countryPrefix = undefined

	let localErrorMessage = undefined

	$: {
		phoneParts = splitPhoneNumber(phone)
		updatePhoneValue()
	}

	$: {
		if (phone) {
			isEmpty = phone.length === 0
		}
	}

	onMount(() => {
		iti = intlTelInput(inputElement, {
			preferredCountries: ['cz', 'sk'],
			initialCountry: defaultCountryCode,
			nationalMode: true,
		})
		countryPrefix = iti.getSelectedCountryData()?.dialCode
	})

	$: {
		if (phone && phone.length > 0 && iti) {
			iti.setNumber(phone)
		}
	}

	onDestroy(() => {
		iti.destroy()
	})

	function updatePhoneValue() {
		requestAnimationFrame(() => {
			phoneValue = formatPhoneNumber(phone, countryPrefix)
		})
	}

	function onInvalid(event) {
		const input = event.target
		localErrorMessage = `Vyplňte prosím číslo ve formátu: +${countryPrefix} 123 456 789`
	}

	function onFocus() {
		isFocused = true
		localErrorMessage = undefined
	}

	function onBlur(event) {
		isFocused = false
		isEmpty = inputElement.value.length === 0
		onPhoneChange()
		event.target.checkValidity()
	}

	function onPhoneChange() {
		updatePhoneValue()
		countryPrefix = iti.getSelectedCountryData()?.dialCode
		phoneParts = splitPhoneNumber(inputElement.value)
		if (countryPrefix && phoneParts[1]) {
			onChange(`+${countryPrefix}${(phoneParts[1] ?? phoneParts[0]).trim()}`)
		} else if (phoneParts[1]) {
			onChange(phoneParts[1])
		} else {
			onChange('')
		}
	}
</script>

<FormRow columnCount="1">
	<label
		for={inputId}
		class={clsx(
			'fancyPhoneInput',
			!isEmpty && 'is-notEmpty',
			isFocused && 'is-focused',
			Boolean(localErrorMessage) && 'is-validationShown',
		)}
	>
		<div class="fancyPhoneInput-frame">
			<input
				id={inputId}
				class="fancyPhoneInput-control"
				on:focus={onFocus}
				on:blur={onBlur}
				on:change={onPhoneChange}
				on:invalid|preventDefault={onInvalid}
				name="phone"
				type="tel"
				maxlength="30"
				autocomplete="tel"
				value={phoneValue}
				label={translate('app.formInputs.phone')}
				pattern={genericPhoneFormat}
				bind:this={inputElement}
			/>
			<div class="fancyPhoneInput-frame-in" />
			<span class="fancyPhoneInput-label">{translate('app.formInputs.phone')}</span>
		</div>
		<div class="fancyPhoneInput-error">
			<Collapsible expanded={Boolean(localErrorMessage)}>
				<FormError message={localErrorMessage} />
			</Collapsible>
		</div>
	</label>
</FormRow>
