<script>
	import AddAddressButton from './AddAddressButton.svelte'
	import Address from './Address.svelte'
	import Box from './Box.svelte'
	import Icon from './Icon.svelte'
	import OptionInput from './OptionInput.svelte'

	export let addressOptions = []
	export let countryOptions = []
	export let selectedOption = null
	export let onAddressSelected
	export let onCreateAddressClicked
	export let onEditAddressClicked

	$: allowedCountrySet = new Set(countryOptions.map(it => it.value))
</script>

<div class="deliveryAddressOptions">
	{#each addressOptions as address}
		<div class="deliveryAddressOptions-item">
			<Box>
				<OptionInput type="radio" name="delivery_address" checked={address.id === selectedOption?.id} disabled={!allowedCountrySet.has(address.country.id)} on:click={e => onAddressSelected(address)}>
					<slot slot="label">{address.firstName} {address.lastName}</slot>
					<slot slot="main">
						<div class="deliveryAddressOptions-item-address">
							<Address address={{ ...address, firstName: undefined, lastName: undefined }} />
						</div>
					</slot>
					<slot slot="aside">
						{#if onEditAddressClicked && allowedCountrySet.has(address.country.id)}
							<button class="deliveryAddressOptions-item-edit" type="button" on:click={e => onEditAddressClicked(address)}>
								<span class="deliveryAddressOptions-item-edit-icon"><Icon name="edit" /></span>
								<span class="deliveryAddressOptions-item-edit-text">Upravit</span>
							</button>
						{/if}
					</slot>
				</OptionInput>
			</Box>
		</div>
	{/each}

	{#if onCreateAddressClicked}
		<div class="deliveryAddressOptions-item">
			<Box>
				<AddAddressButton on:click={onCreateAddressClicked} />
			</Box>
		</div>
	{/if}
</div>
