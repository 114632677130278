<script>
	import Icon from './Icon.svelte'
	import Picture from './Picture.svelte'

	export let item
	export let alt
</script>

<div class="interactiveGalleryThumbnail">
	<div class="interactiveGalleryThumbnail-in">
		{#if item.type ==='image'}
			<Picture image={item.image} sizes="50px" {alt} loading="eager" />
		{:else if ['vimeo', 'youtube'].includes(item.type)}
			<img class="interactiveGalleryThumbnail-image" src={item.video.thumbnailUrl} {alt} loading="eager">
			<span class="interactiveGalleryThumbnail-icon">
				<Icon name="youtubePlay" />
			</span>
		{/if}
	</div>
</div>
