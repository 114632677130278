<script>
	import clsx from 'clsx'

	export let currentTariff = 'basic'

</script>

<div class={clsx("checkoutLoyaltyProgress", "view-" + currentTariff)}>
	<slot />
</div>
