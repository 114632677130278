<script>
	import { sendComponentMessage } from '../utils/initializeComponents'
	import { FilterProducts } from '../components/FilterProducts'
	import Select from './Select.svelte'
	import ButtonSelect from './ButtonSelect.svelte'

	export let sortOptions
	export let filterProductsSelector
	export let initialSort

	let value = initialSort
	const filterProductsElement = document.querySelector(filterProductsSelector)

	function handleInputChange(event) {
		value = event.target.value
		sendComponentMessage(filterProductsElement, FilterProducts, 'setSort', value)
	}
</script>

<form class="sortOptionsForm">
	<ButtonSelect on:change={handleInputChange} name="sort-options" options={sortOptions} {value} />
</form>
