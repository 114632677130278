<script>
	import clsx from 'clsx'
	import Button from './Button.svelte'
	import Collapsible from './Collapsible.svelte'
	import InteractiveGalleryStage from './InteractiveGalleryStage.svelte'
	import InteractiveGalleryThumbnail from './InteractiveGalleryThumbnail.svelte'
	import Picture from './Picture.svelte'
	import ProgressIndicator from './ProgressIndicator.svelte'
	import { lightbox } from '@mangoweb/fslightbox'
	import { computeSrc } from '../utils/imageSrc'
	import { isMaterialized } from '../utils/isMaterialized'

	export let items = []
	export let index = 0

	export let alt = ''

	let vimeoVideoElement

	let thumbnailsVisible = false

	$: aspectRatio = Math.min(...items.map((item) => (item.image?.width / item.image?.height) || 16 / 9))
	$: nextIndex = (index + 1) % items.length
	$: previousIndex = (index + items.length - 1) % items.length

	let direction = 'jump'

	function move(step) {
		index = (index + items.length + step) % items.length
	}

	function goTo(newIndex) {
		if (newIndex === index) {
			return
		}
		direction = 'jump'
		index = newIndex
	}

	let xDown = null
	let xDiff = null
	function getTouches(event) {
		return event.touches
	}
	function handleTouchStart(event) {
		const firstTouch = getTouches(event)[0]
		xDown = firstTouch.clientX
	}
	function handleTouchMove(event) {
		if(!xDown || event.touches.length > 1) {
			xDown = null
			xDiff = null
			return
		}
		const xUp = event.touches[0].clientX
		xDiff = xDown - xUp
	}
	function handleTouchEnd(event) {
		if(!xDiff) {
			return
		}
		if(xDiff > 50) {
			next()
		} else if(xDiff < 50) {
			previous()
		}
		xDiff = null
	}

	function next() {
		direction = 'forward'
		move(+1)
	}

	function previous() {
		direction = 'backward'
		move(-1)
	}

	function open() {
		const scaleFactorToIncreaseDetailWhenZooming = 2
		const mainWidth = Math.round(document.documentElement.clientWidth * window.devicePixelRatio / 300) * 300 * scaleFactorToIncreaseDetailWhenZooming
		const thumbWidth = Math.round(108 * window.devicePixelRatio)
		const format = 'webp'

		const sources = items.map((item) => {
			if(item.type === 'image') {
				return {
					source: computeSrc({ url: item.image.url, width: mainWidth, format }),
					thumb: computeSrc({ url: item.image.url, width: thumbWidth, format }),
					type: 'image',
					caption: item.description,
				}
			} else if(item.type === 'vimeo') {
				return {
					source: vimeoVideoElement,
					thumb: item.video.thumbnailUrl,
					type: null,
					isVideo: true,
					caption: item.video.description,
				}
			} else if(item.type === 'youtube') {
				return {
					source: 'https://www.youtube.com/watch?v=' + item.video.youtubeId,
					thumb: item.video.thumbnailUrl,
					type: 'youtube',
				}
			}
		})

		lightbox(sources,
			{
				showThumbsOnMount: true,
				zoomIncrement: 1,
				onSlideActivate: (current, previous) => {
					// Autoplay vimeo iframe
					const currentSource = current.source.source
					const previousSource = previous?.source.source
					const isVimeoIframe = (source) => source instanceof HTMLIFrameElement && source.src.startsWith('https://player.vimeo.com/')
					const postVimeoMessage = (vimeoIframe, data) => { vimeoIframe.contentWindow?.postMessage(JSON.stringify(data), '*') }
					if (isVimeoIframe(currentSource)) {
						postVimeoMessage(currentSource, { method: 'play' })
					}
					if (isVimeoIframe(previousSource)) {
						postVimeoMessage(previousSource, { method: 'pause' })
					}
				}
			}
		).open(index)
	}
</script>

<div
	on:mouseenter={() => {thumbnailsVisible = true}}
	on:mouseleave={() => {thumbnailsVisible = false}}
	class={clsx('interactiveGallery', `view-direction-${direction}`)}
	style="
		--interactiveGallery-aspectRatio: {aspectRatio};
	"
>
	{#each items as item}
		{#if item.type === 'vimeo'}
			<template>
				<!-- @TODO will not work with multiple vimeo videos -->
				<iframe title="Video"
					src={`https://player.vimeo.com/video/${item.video.vimeoId}?autopause=0&autoplay=1`}
					frameBorder="0"
					allow="autoplay; fullscreen"
					allowFullScreen
					width="1920px"
					height="1080px"
					bind:this={vimeoVideoElement}
				></iframe>
			</template>
		{/if}
	{/each}
	<div class="interactiveGallery-stage">
		<div class="interactiveGallery-stage-in" on:touchstart={handleTouchStart} on:touchmove={handleTouchMove} on:touchend={handleTouchEnd}>
			{#if items.length > 1}
				<div class="interactiveGallery-arrows">
					<div class="interactiveGallery-arrows-item">
						<Button
							condense
							size="small"
							contentIcon="legacy-chevron-left"
							disableRipple
							on:click={previous}
						/>
					</div>
					<div class="interactiveGallery-arrows-item">
						<Button
							condense
							size="small"
							contentIcon="legacy-chevron-right"
							disableRipple
							on:click={next}
						/>
					</div>
				</div>
			{/if}

			{#key index}
				<div class="interactiveGallery-stage-previous">
					<InteractiveGalleryStage item={items[previousIndex]} {alt} />
				</div>
				<button type="button" on:click={open} class="interactiveGallery-stage-image">
					<InteractiveGalleryStage item={items[index]} {alt} />
				</button>
				<div class="interactiveGallery-stage-next">
					<InteractiveGalleryStage item={items[nextIndex]} {alt} />
				</div>
			{/key}
		</div>
	</div>

	{#if items.length > 1}
		<div class="interactiveGallery-counter">
			{index + 1}/{items.length}
		</div>
		<div class="interactiveGallery-tabs">
			{#each items as item, i}
				<div class="interactiveGallery-tabs-item">
					<ProgressIndicator label={`přejít na ${i+1}`} on:click={() => goTo(i)} end={i === index ? 1 : 0}>
						<svelte:fragment slot="thumbnail">
							<InteractiveGalleryThumbnail {item} {alt} />
						</svelte:fragment>
					</ProgressIndicator>
				</div>
			{/each}
		</div>
	{/if}
</div>
