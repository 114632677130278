<script>
	import { createEventDispatcher } from 'svelte'

	export let limitMin
	export let limitMax
	export let name

	export let initialValueMin
	export let initialValueMax

	export let valueFormatter

	let currentValueMin = initialValueMin || limitMin
	let currentValueMax = initialValueMax || limitMax

	let controlElement
	let minHandleElement
	let maxHandleElement
	let isDragged = false

	const dispatch = createEventDispatcher()

	function onPointerMove(event) {
		if (isDragged) {
			const rect = controlElement.getBoundingClientRect()
			const x = event.clientX - rect.left
			let handlePosition = Math.min(Math.max(0, x / rect.width), 1)
			if (event.target === minHandleElement) {
				currentValueMin = Math.round(
					Math.min(limitMin + handlePosition * (limitMax - limitMin), currentValueMax),
				)
				handlePosition = (currentValueMin - limitMin) / (limitMax - limitMin)
			} else if (event.target === maxHandleElement) {
				currentValueMax = Math.round(
					Math.max(limitMin + handlePosition * (limitMax - limitMin), currentValueMin),
				)
				handlePosition = (currentValueMax - limitMin) / (limitMax - limitMin)
			}
		}
	}

	function onPointerDown(event) {
		event.target.setPointerCapture(event.pointerId)
		isDragged = true
	}

	function onPointerUp(event) {
		event.target.releasePointerCapture(event.pointerId)
		isDragged = false

		dispatch('change', {min: currentValueMin, max: currentValueMax, code: name})
	}
</script>

<div class="rangeSlider">
	<div class="rangeSlider-control" bind:this={controlElement}>
		<input
			class="rangeSlider-input"
			type="range"
			name={`${name}_min`}
			bind:value={currentValueMin}
			min={limitMin}
			max={limitMax}
		/>
		<input
			class="rangeSlider-input"
			type="range"
			name={`${name}_max`}
			bind:value={currentValueMax}
			min={limitMin}
			max={limitMax}
		/>
		<div class="rangeSlider-handles">
			<span
				on:pointerdown={onPointerDown}
				on:pointerup={onPointerUp}
				on:pointermove={isDragged ? onPointerMove : undefined}
				class="rangeSlider-handle view-min"
				style={`--rangeSlider-handle-offset: ${(currentValueMin - limitMin) / (limitMax - limitMin)}`}
				bind:this={minHandleElement}
			/>
			<span
				on:pointerdown={onPointerDown}
				on:pointerup={onPointerUp}
				on:pointermove={isDragged ? onPointerMove : undefined}
				class="rangeSlider-handle view-max"
				style={`--rangeSlider-handle-offset: ${(currentValueMax - limitMin) / (limitMax - limitMin)}`}
				bind:this={maxHandleElement}
			/>
		</div>
	</div>
	<div class="rangeSlider-labels">
		<span class="rangeSlider-label">Od {valueFormatter(currentValueMin)}</span>
		<span class="rangeSlider-label">Do {valueFormatter(currentValueMax)}</span>
	</div>
</div>
