<script>
	import {cart} from '../stores/cartStore'
	import clsx from 'clsx'
	import Collapsible from './Collapsible.svelte'
	import Icon from './Icon.svelte'
	import {translate} from '../utils/translations'

	let optionsVisible = false
	let isUrlInClipboard = false

	let url = ''
	$: {
		url = window.location.origin + '/share-cart?' + $cart.items.map(it => it.sku).join('&')
	}

	function handleShareButtonClick() {
		optionsVisible = !optionsVisible
	}

	function handleMouseLeave() {
		optionsVisible = false
		isUrlInClipboard = false
	}

	function handleCopyButtonClick() {
		navigator.clipboard.writeText(url).then(() => {
			isUrlInClipboard = true
		})
	}
</script>

<div class="cartShare" on:mouseleave={handleMouseLeave}>
	<button class="cartShare-button" type="button" on:click={handleShareButtonClick}>
		<span class="cartShare-button-icon"><Icon name="forward-arrow" /></span>
		<span class="cartShare-button-text">{translate('app.checkout.cart.share')}</span>
	</button>
	<div class={clsx("cartShare-options", optionsVisible && "is-visible")}>
		<ul class="cartShare-options-in">
			<li class="cartShare-item">
				<div class="cartShare-option">
					<button class="cartShare-button" on:click={handleCopyButtonClick}>
						<span class="cartShare-button-icon"><Icon name="link" /></span>
						<span class="cartShare-button-text">{translate('app.checkout.cart.copy')}</span>
					</button>
				</div>
				<Collapsible expanded={isUrlInClipboard}>
					<div class="cartShare-option">
						<span class="cartShare-button view-highlight">
							<span class="cartShare-button-icon"><Icon name="check" /></span>
							<span class="cartShare-button-text">{translate('app.checkout.cart.inClipboard')}</span>
						</span>
					</div>
				</Collapsible>
			</li>
			<li class="cartShare-item">
				<div class="cartShare-option">
					<a class="cartShare-button" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`} target="_blank" rel="noopener">
						<span class="cartShare-button-icon"><Icon name="facebook" /></span>
						<span class="cartShare-button-text">{translate('app.checkout.cart.facebookShare')}</span>
					</a>
				</div>
			</li>
		</ul>
	</div>
</div>
