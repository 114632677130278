<script>
	import { onMount,tick } from 'svelte'
	import { putAddress } from '../api/address'
	import { fetchPaymentOptions,fetchShippingOptions,putPaymentInfo,putShippingInfo } from '../api/cart'
	import { cart } from '../stores/cartStore'
	import { configuration } from '../utils/configuration'
	import { translate } from "../utils/translations"
	import AddressPostalCodeForm from "./AddressPostalCodeForm.svelte"
	import Box from './Box.svelte'
	import Button from './Button.svelte'
	import CartSummary from './CartSummary.svelte'
	import CheckoutLayout from './CheckoutLayout.svelte'
	import CheckoutNavigationRow from './CheckoutNavigationRow.svelte'
	import CheckoutWarnings from "./CheckoutWarnings.svelte"
	import DpdPickupOptions from "./DpdPickupOptions.svelte"
	import Modal from './Modal.svelte'
	import OptionInput from './OptionInput.svelte'
	import PriceSummary from './PriceSummary.svelte'
	import RadioThatLooksLikeCheckboxList from './RadioThatLooksLikeCheckboxList.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'
	import VenueOptions from './VenueOptions.svelte'

	export let nextStepLink
	export let mapPinIcon

	let shippingOptions = null
	let paymentOptions = null
	let shippingOptionModalOpen = null
	let dpdOptions = new Map()
	let dpdCountries = []

	$: effectiveShippingAddress = $cart?.shippingAddress ?? $cart?.billingAddress
	$: shippingCountry = null
	$: shippingPostalCode = null
	// $: selectedShippingMethodAddress = $cart?.shippingMethod?.type === 'pickup' ? $cart.shippingMethod.addresses.find(it => it.address.id === $cart.shippingAddress?.id) : null

	onMount(async () => {
		[shippingOptions, paymentOptions] = await Promise.all([
			fetchShippingOptions(),
			fetchPaymentOptions(),
		])
	})

	function handleShippingMethodClick(event) {
		event.preventDefault()
		setShippingMethod(shippingOptions[event.target.value], null)
	}

	async function handlePacketaResult(option, point) {
		const country = option.countries.find(it => it.code.toUpperCase() === point.country.toUpperCase())

		if (!country) {
			return
		}

		if (point.error !== null) {
			alert('TODO_NOT_AVAILABLE')
			return
		}

		if (point.warning !== null) {
			// TODO: discourage the customer from selecting this pick-up point
		}

		const address = await putAddress({
			name: point.name,
			addressId: null,
			firstName: '',
			lastName: '',
			phone: '',
			email: '',
			addressLine1: point.street,
			city: point.city,
			postalCode: point.zip,
			countryId: country.id,
			externalIdentifier: point.id,
			hidden: true,
		})

		await setShippingMethod(option, address.id)
	}

	async function handlePplPickupResult(option, point) {
		const country = option.countries.find(it => it.code.toUpperCase() === point.country.toUpperCase())

		if (!point || !country) {
			console.error('Missing point or selected unsupported country', {'point': point, 'country': country})
			return
		}

		const address = await putAddress({
			name: point.name,
			addressId: null,
			firstName: '',
			lastName: '',
			phone: point.phone ?? '',
			email: '',
			addressLine1: point.street,
			city: point.city,
			postalCode: point.zipCode,
			countryId: country.id,
			externalIdentifier: point.code,
			hidden: true,
		})

		await setShippingMethod(option, address.id)
	}


	async function handleGlsPickupResult(option, point) {
		const countryCode = point.ctrcode

		if (!countryCode) {
			return
		}
		const country = option.countries.find(it => it.code.toUpperCase() === countryCode.toUpperCase())
		if (!country) {
			return
		}

		const address = await putAddress({
			name: point.name,
			addressId: null,
			firstName: '',
			lastName: '',
			phone: point.phone,
			email: point.email,
			addressLine1: point.address,
			city: point.city,
			postalCode: point.zipcode,
			countryId: country.id,
			externalIdentifier: point.pclshopid,
			hidden: true,
		})

		await setShippingMethod(option, address.id)
	}


	async function handleSelectDpdPoint(option, point) {
		const countryCode = dpdCountries.find(it => it.id === point.country_id)?.iso

		if (!countryCode) {
			return
		}

		const country = option.countries.find(it => it.code.toUpperCase() === countryCode.toUpperCase())

		if (!country) {
			return
		}

		const address = await putAddress({
			name: point.company,
			addressId: null,
			firstName: '',
			lastName: '',
			phone: point.phone,
			email: point.email,
			addressLine1: point.street + ' ' + point.house_number,
			city: point.city,
			postalCode: point.postcode,
			countryId: country.id,
			externalIdentifier: point.id,
			hidden: true,
		})

		await setShippingMethod(option, address.id)
	}

	async function setShippingMethod(option, addressId, countryId, postalCode) {
		if (option?.type === 'pickup') {
			if (!addressId) {
				shippingOptionModalOpen = null
				await tick() // force model redraw by nuclear power
				shippingOptionModalOpen = option
				return
			}

		} else if (option?.type === 'pickup_zasilkovna') {
			if (!addressId) {
				const allowedCountries = option.countries.map(it => it.code)
				const packetaApiKey = '1aa6ea930ecc5dbd'
				const packetaOptions = {
					country: allowedCountries.join(',').toLowerCase(),
					language: configuration.language
				}
				window.Packeta.Widget.pick(packetaApiKey, (point) => handlePacketaResult(option, point), packetaOptions)
				return
			}
		}  else if (option?.type === 'pickup_ppl') {
			if (!addressId) {
				document.addEventListener('ppl-parcelshop-map', (event) => {
					if (event instanceof CustomEvent) {
						handlePplPickupResult(option, event.detail)
					} else {
						console.error('There are no "details" in event')
					}
				})

				shippingOptionModalOpen = null
				await tick() // force model redraw by nuclear power
				shippingOptionModalOpen = option
				return
			}
		}  else if (option?.type === 'pickup_gls') {
			if (!addressId) {
				window.addEventListener('message', (rawData) => {
					if (rawData.origin === 'https://maps.gls-czech.cz' && rawData.isTrusted){
						handleGlsPickupResult(option, rawData.data?.parcelshop?.detail)
					}
				})
				shippingOptionModalOpen = null
				await tick() // force model redraw by nuclear power
				shippingOptionModalOpen = option
				return
			}
		} else if (option?.type === 'pickup_dpd') {
			if (!addressId) {
				if (dpdCountries.length === 0) {
					const countriesResponse = await (await fetch('https://pickup.dpd.cz/api/get-countries?lang=cs')).json()
					dpdCountries = countriesResponse.data.items
				}
				const allowedCountries = option.countries.map(it => it.code.toLowerCase())
				const countries = dpdCountries
					.filter(country => allowedCountries.includes(country.iso))
					.map(country => country.id)

				const countriesSpotsPromises = countries
					.map(async id => {
						const json = await (await fetch(`https://pickup.dpd.cz/api/get-all?lang=cs&country=${id}`)).json()
						return json?.data?.items?.filter(place => place.pickup_allowed === 1) ?? []
					})
				const items = (await Promise.all(countriesSpotsPromises)).flat()
				dpdOptions.set(option, items)

				shippingOptionModalOpen = null
				await tick() // force model redraw by nuclear power
				shippingOptionModalOpen = option
				return
			}
		} else if (option?.isAddressDependent) {
			if (option?.shippingAddress && !addressId && !postalCode) {
				addressId = option?.shippingAddress?.id
			}
			if (!addressId && !postalCode) {
				shippingOptionModalOpen = null
				await tick() // force model redraw by nuclear power
				shippingOptionModalOpen = option
				return
			}
		}

		// optimistic UI update
		$cart.shippingMethod = option
		shippingOptionModalOpen = null

		// call api
		$cart = await putShippingInfo(option ? option.code : null, addressId, countryId, postalCode)
		paymentOptions = await fetchPaymentOptions()
	}

	async function setPaymentMethod(option) {
		// optimistic UI update
		$cart.paymentMethod = option

		// call api
		$cart = await putPaymentInfo(option ? option.code : null, $cart.billingAddress ? $cart.billingAddress.id : null)
		shippingOptions = await fetchShippingOptions()
	}
</script>

<CheckoutLayout>
	<slot slot="main">
		{#if $cart && shippingOptions}
			<Title level="2" viewLevel="5">{translate('app.checkout.shipping.prompt')}</Title>
			<Spacer initial="24" />
			<CheckoutWarnings />

			{#if $cart.shippingMethod === null}
				<RadioThatLooksLikeCheckboxList name="shipping" items={shippingOptions} onClick={handleShippingMethodClick} address={effectiveShippingAddress} />
			{:else}
				<Box>
					<OptionInput
						viewType="checkbox"
						type="checkbox"
						checked
						on:change={e => setShippingMethod(null, null, null, null)}
					>
						<slot slot="label">
							{$cart.shippingMethod.label}
							{#if $cart.shippingAddress?.name}<br><strong>{$cart.shippingAddress.name}</strong>{/if}
							<!--{#if selectedShippingMethodAddress}na pobočce <strong>{selectedShippingMethodAddress.label}</strong>{/if}-->
						</slot>
						<slot name="main" slot="main">
							{#if $cart.shippingMethod.description}
								<Spacer initial="6" break768="16" />
								{@html $cart.shippingMethod.description}
							{/if}
							{#if $cart.shippingMethod.isAddressDependent && effectiveShippingAddress?.postalCode}
								<Spacer initial="6" break768="16" />
								Pro PSČ {effectiveShippingAddress?.postalCode}
								(<button class="checkoutStepTwo-change" on:click|preventDefault={e => {
									shippingOptionModalOpen = shippingOptions.find(it => it.code === $cart?.shippingMethod?.code)
								}}>změnit</button>)
							{/if}
						</slot>
						<slot slot="aside">
							{#if $cart.shippingMethod.price}
								{$cart.shippingMethod.price.formatted}
							{/if}
						</slot>
					</OptionInput>

					<Spacer initial="24" />
					<div class="checkoutStepTwo-button">
						<Button variant="outlined" on:click={e => setShippingMethod(null, null)}>{translate('app.checkout.shipping.change')}</Button>
					</div>
				</Box>
			{/if}

			{#each shippingOptions as shippingOption}
				{#if shippingOption.type === 'pickup'}
					<Modal open={shippingOptionModalOpen === shippingOption} type="centerWide">
						<svelte:fragment slot="title">
							<Title level="2" viewLevel="4">Výběr pobočky</Title>
						</svelte:fragment>
						<VenueOptions venues={shippingOption.addresses} {mapPinIcon}>
							<div slot="button" let:item>
								<Button on:click={e => setShippingMethod(shippingOption, item.address.id)} endIcon="legacy-arrow-right" condense={true}>
									Vyzvednout zde
								</Button>
							</div>
						</VenueOptions>
					</Modal>
				{:else if shippingOption.type === 'pickup_ppl'}
					<Modal open={shippingOptionModalOpen === shippingOption} type="centerWide">
						<script type="text/javascript" src="https://www.ppl.cz/sources/map/main.js" async></script>
						<link rel="stylesheet" href="https://www.ppl.cz/sources/map/main.css">
						<div id="ppl-parcelshop-map" data-language="{['cs', 'sk'].includes(configuration.language) ? 'cs' : 'en'}" data-mode="default"></div>
					</Modal>
				{:else if shippingOption.type === 'pickup_gls'}
					<Modal open={shippingOptionModalOpen === shippingOption} type="fullscreen">
						<svelte:fragment slot="title">
							<Title level="2" viewLevel="4">Výběr místa</Title>
						</svelte:fragment>
						<iframe class="glsChoice-iframe" frameborder="0" title="GLS Pickup Point Map" id="gls-parcelshop-map" src="https://maps.gls-czech.cz/?find=1&ctrcode={shippingOption.countries.map(it => it.code.toLowerCase())[0]}" width="100%" height="100%"></iframe>
					</Modal>
				{:else if shippingOption.type === 'pickup_dpd'}
					<Modal open={shippingOptionModalOpen === shippingOption} type="centerWide">
						<svelte:fragment slot="title">
							<Title level="2" viewLevel="4">Výběr místa</Title>
						</svelte:fragment>
						<DpdPickupOptions venues={dpdOptions.get(shippingOption)} {mapPinIcon}>
							<div slot="button" let:item>
								<Button on:click={e => handleSelectDpdPoint(shippingOption, item)} endIcon="legacy-arrow-right" condense={true}>
									Vyzvednout zde
								</Button>
							</div>
						</DpdPickupOptions>
					</Modal>
				{:else if shippingOption.isAddressDependent}
					<Modal open={shippingOptionModalOpen === shippingOption} type="centerWide">
						<svelte:fragment slot="title">
							<Title level="2" viewLevel="4">Zadejte PSČ</Title>
						</svelte:fragment>
						<AddressPostalCodeForm
							on:submit={e => setShippingMethod(
								shippingOption,
								effectiveShippingAddress?.postalCode === shippingPostalCode && effectiveShippingAddress?.country.id === shippingCountry?.id ? effectiveShippingAddress?.id : null,
								shippingCountry?.id, shippingPostalCode
								)
							}
							countryOptions={shippingOption.countries.map(({ id, name }) => ({ value: id, label: name }))}
							bind:country={shippingCountry}
							bind:postalCode={shippingPostalCode}
							address={effectiveShippingAddress}
						/>
					</Modal>
				{/if}
			{/each}
		{/if}

		<Spacer initial="32" break768="48" />

		{#if $cart && paymentOptions}
			<Title level="2" viewLevel="5">{translate('app.checkout.payment.prompt')}</Title>
			<Spacer initial="24" />

			{#if $cart.paymentMethod === null}
				<RadioThatLooksLikeCheckboxList
					name="payment-method"
					items={paymentOptions}
					onChange={setPaymentMethod}
				/>
			{:else}
				<Box>
					<OptionInput
						viewType="checkbox"
						type="checkbox"
						checked
						on:change={e => setPaymentMethod(null)}
					>
						<slot slot="label">{$cart.paymentMethod.label}</slot>
						<slot name="main" slot="main">
							{#if $cart.paymentMethod.description}
								<Spacer initial="6" break768="16" />
								{$cart.paymentMethod.description}
							{/if}
						</slot>
						<slot slot="aside">
							{#if $cart.paymentMethod.price}
								{$cart.paymentMethod.price.formatted}
							{/if}
						</slot>
					</OptionInput>

					<Spacer initial="24" />
					<div class="checkoutStepTwo-button">
						<Button variant="outlined" on:click={e => setPaymentMethod(null)}>{translate('app.checkout.payment.change')}</Button>
					</div>
				</Box>
			{/if}
		{/if}

		{#if $cart}
			<Spacer initial="24" />
			<CheckoutNavigationRow>
				<slot slot="center">
					<PriceSummary
						totalPrice={$cart.totalPrice.formatted}
						vatPrice={$cart.totalPrice.vatFormatted}
					/>
				</slot>
				<slot slot="right">
					<Button disabled={!Boolean($cart.shippingMethod && $cart.paymentMethod) || $cart.isInvalidCombination} type="link" size="large" condense link={nextStepLink} endIcon="arrow-right">{translate('app.checkout.buttons.continue')}</Button>
				</slot>
			</CheckoutNavigationRow>
		{/if}
	</slot>
	<slot slot="aside">
		{#if $cart && $cart.items}
			<Title level="2" viewLevel="5">{translate('app.checkout.cart.content')}</Title>
			<Spacer initial="24" />
			<CartSummary
				items={$cart.items}
				otherItems={[$cart.shippingMethod, $cart.paymentMethod, ...$cart.vouchers]}
				totalPrice={$cart.totalPrice}
			/>
		{/if}
	</slot>
</CheckoutLayout>
