<script>
	import { createEventDispatcher } from 'svelte'
	import { translate } from '../utils/translations'
	import Button from './Button.svelte'
	import InspectionStatus from './InspectionStatus.svelte'
	import LineSeparator from './LineSeparator.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'

	export let limitDate
	export let usedDate
	export let recommendedNextServiceDate
	export let currentOrderInProgress
	export let currentOrderAwaitingPickup

	const dispatch = createEventDispatcher()
	function handleOrderButtonClick() {
		dispatch('order')
	}
</script>

<div class="inspections">
	<Title viewLevel="5" level="4">{translate('userService.product.inspections.title')}</Title>
	{#if usedDate || limitDate}
		<Spacer initial="16" />
		<Title viewLevel="6" level="5">{translate('userService.product.inspections.guarantee.title')}</Title>
		<Spacer initial="4" />
		{#if usedDate}
			<InspectionStatus label={translate('userService.product.inspections.status.used')} timestamp={usedDate} />
		{:else}
			<InspectionStatus
				label={translate('userService.product.inspections.status.limit')}
				timestamp={limitDate}
				isInFuture
			/>
		{/if}
	{/if}
	{#if (currentOrderInProgress || currentOrderInProgress || currentOrderAwaitingPickup) && (usedDate || limitDate)}
		<Spacer initial="10" />
		<LineSeparator />
	{/if}

	{#if currentOrderInProgress || currentOrderInProgress || currentOrderAwaitingPickup}
		<Spacer initial="16" />
		<Title viewLevel="6" level="5">{translate('userService.product.inspections.service.title')}</Title>
		<Spacer initial="4" />
	{/if}

	{#if currentOrderInProgress && currentOrderInProgress.expectedFinishingDate}
		<InspectionStatus
			label="Právě probíhá, očekávané datum dokončení"
			timestamp={currentOrderInProgress.expectedFinishingDate}
			isInFuture
		/>
	{:else if currentOrderInProgress}
		<InspectionStatus
			label="Právě probíhá"
			isInFuture
		/>
	{:else if currentOrderAwaitingPickup}
		<InspectionStatus
			label="Právě probíhá, očekává se vyzvednutí"
		/>
	{:else if recommendedNextServiceDate}
		<InspectionStatus
			label={translate('userService.product.inspections.status.recommended')}
			timestamp={recommendedNextServiceDate}
			isInFuture
		/>
	{/if}
	<div class="inspections-action">
		<Button on:click={handleOrderButtonClick}>{translate('userService.product.order')}</Button>
	</div>
</div>
