<script>
	export let url
	export let title
	export let subtitle
	export let size = undefined
	export let code = undefined
	export let showCode = false
	export let discountDescription = undefined
</script>

<div class="cartItemInfo">
	<div class="cartItemInfo-title">{#if url}<a href={url}>{title}</a>{:else}{title}{/if}</div>
	{#if subtitle}<div class="cartItemInfo-subtitle">{subtitle}</div>{/if}

	{#if size}
		<div class="cartItemInfo-variants">
			Velikost: {size}
		</div>
	{/if}

	{#if code && showCode}
		<div class="cartItemInfo-code">
			Kód: {code}
		</div>
	{/if}

	{#if discountDescription}
		<div class="cartItemInfo-discounts">
			<span class="cartItemInfo-discount">
				{discountDescription}
			</span>
		</div>
	{/if}
</div>
