<script>
	import Icon from './Icon.svelte'
	import Picture from './Picture.svelte'

	export let item
	export let alt
</script>

<div class="interactiveGalleryStage">
	{#if item.type ==='image'}
		<div class="interactiveGalleryStage-in" style="--interactiveGalleryStage-image-width: {item.image.width}px">
			<Picture image={item.image} sizes={item.sizes} {alt} loading="eager" />
		</div>
	{:else if ['vimeo', 'youtube'].includes(item.type)}
		<div class="interactiveGalleryStage-in" style="--interactiveGalleryStage-image-width: 800px">
			<img class="interactiveGalleryStage-image" src={item.video.thumbnailUrl} {alt} loading="eager">
			<span class="interactiveGalleryStage-icon">
				<Icon name="youtubePlay" />
			</span>
		</div>
	{/if}
</div>
