<script>
	import { onMount, tick } from 'svelte'
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import { fetchAddressList, fetchCountryList, putAddress } from '../api/address'
	import AddAddressButton from './AddAddressButton.svelte'
	import Title from './Title.svelte'
	import Modal from './Modal.svelte'
	import Spacer from './Spacer.svelte'
	import AddressForm from './AddressForm.svelte'
	import Button from './Button.svelte'
	import Box from './Box.svelte'
	import FormRow from './FormRow.svelte'
	import Address from './Address.svelte'
	import Icon from './Icon.svelte'
	import ModalFormSubmitButton from './ModalFormSubmitButton.svelte'

	let addressOptions = null
	let countryOptions = null

	let showAddressForm = false
	let address = null

	let formElement = undefined

	onMount(async () => {
		addressOptions = await fetchAddressList()
		countryOptions = (await fetchCountryList()).map(({ id, name }) => ({ value: id, label: name }))
	})

	async function putAddressObj(address) {
		return await putAddress({
			...address,
			id: undefined,
			addressId: address.id,
			country: undefined,
			countryId: address.country.id,
			gpsLat: undefined,
			gpsLng: undefined,
		})
	}

	async function onCreateAddressClicked() {
		address = null
		showAddressForm = false
		await tick()
		showAddressForm = true
	}

	async function onEditAddressClicked(addressToEdit) {
		address = addressToEdit
		showAddressForm = false
		await tick()
		showAddressForm = true
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function onSaveAddress() {
		await putAddressObj(address)
		addressOptions = await fetchAddressList()
		showAddressForm = false
		address = null
	}
</script>

<div class="userAddressSetting">
	<Title level="5">Adresy</Title>
	<Spacer initial="16" />

	<Modal open={showAddressForm}>
		<svelte:fragment slot="title">
			<Title level="2" viewLevel="4">{#if address?.id}Upravit adresu{:else}Nová adresa{/if}</Title>
		</svelte:fragment>
		<form on:submit|preventDefault={onSaveAddress} bind:this={formElement}>
			<AddressForm {countryOptions} bind:address hideEmail showCompany />
			<Spacer initial="16" />
			<ModalFormSubmitButton>
				<Button on:click={submitButtonClick} type="submit">Uložit</Button>
			</ModalFormSubmitButton>
		</form>
	</Modal>

	{#if addressOptions && countryOptions}
		<FormRow columnCount="2">
			{#each addressOptions as address}
				<div class="userAddressSetting-item view-address">
					<Address {address} />

					<button class="userAddressSetting-edit" type="button" on:click={e => onEditAddressClicked(address)}>
						<span class="userAddressSetting-edit-icon"><Icon name="edit" /></span>
						<span class="userAddressSetting-edit-text">Upravit</span>
					</button>
				</div>
			{/each}

			<div class="userAddressSetting-item">
				<AddAddressButton on:click={onCreateAddressClicked} />
			</div>
		</FormRow>
	{/if}
</div>
