<script>
	import OptionalFeature from './OptionalFeature.svelte'
	import {complementSetting} from "../stores/complementSettingStore";
	import {translate} from "../utils/translations";

	export let complements
	export let productVariantId

	async function setQuantity(productVariantId, complementOfId, quantity) {
		if (quantity > 0) {
			$complementSetting.variantIds.add(productVariantId)
		} else {
			$complementSetting.variantIds.delete(productVariantId)
		}
		$complementSetting = $complementSetting
	}
</script>
{#if complements && complements.length > 0}
	<div>
		<h1 class="detailComplements-sectionTitle">{translate('app.checkout.recommendedProducts')}</h1>
		{#each complements as complement}
			<OptionalFeature baseItemVariantId={productVariantId} {...complement}
							 quantity={$complementSetting.variantIds.has(complement.variantId)} {setQuantity}/>
		{/each}
	</div>
{/if}
