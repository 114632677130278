<script>
	import Map from './Map.svelte'
	import RenderContentIfVisible from './RenderContentIfVisible.svelte'
	import OpeningHours from './OpeningHours.svelte'
	import Spacer from './Spacer.svelte'
	import {translate} from "../utils/translations";

	export let venue
	export let mapPinIcon = undefined

	let marker = undefined

	if(venue.address && venue.address.gpsLat && venue.address.gpsLng) {
		marker = {
			title: venue.label,
			latitude: venue.address.gpsLat,
			longitude: venue.address.gpsLng,
			icon: mapPinIcon,
		}
	}

	$: contacts = venue.store?.contacts ?? []
	$: phoneContacts = contacts.flatMap(it => it.phone ? [it.phone] : [])
</script>

<div class="venueDetails">
	<div class="venueDetails-content">
		{#if phoneContacts.length > 0}
			<div class="venueDetails-contacts">
				<div class="venueDetails-contacts-title">{translate('app.contacts.storePhone')}</div>
				{phoneContacts.join(', ')}
			</div>
			<Spacer initial="12" />
		{/if}

		{#if venue.store && venue.store.openingHours && venue.store.openingHours.openingHours.length > 0}
			<div class="venueDetails-openingHours">
				<div class="venueDetails-openingHours-title">{translate('app.openingHours.title')}</div>
				<OpeningHours openingHours={venue.store.openingHours.openingHours} />
			</div>
			<Spacer initial="24" break768="16" />
		{/if}

		<slot name="button" />
	</div>

	{#if venue.address && venue.address.gpsLat && venue.address.gpsLng}
		<div class="venueDetails-map">
			<RenderContentIfVisible>
				<Map
					center={marker}
					markers={[marker]}
				/>
			</RenderContentIfVisible>
		</div>
	{/if}
</div>
