<script>
	import TextInput from './TextInput.svelte'

	async function handleSubmit() {
		// @TODO load articles
	}
</script>

<form on:submit|preventDefault={handleSubmit}>
	<TextInput name="blogList-search" label="Vyhledat" submitIcon="search" />
</form>
