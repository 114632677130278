<script>
	export let original
	export let discounted
	export let discountDescription = undefined
</script>

<div class="cartItemPrice" title={discountDescription}>
	{#if original}
		{#if discounted && discounted.cents < original.cents}
			<span class="cartItemPrice-discounted">{discounted.formatted}</span>
		{/if}
		<span class="cartItemPrice-original">{original.formatted}</span>
	{/if}
</div>
