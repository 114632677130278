<script>
	import TextInput from './TextInput.svelte'

	export let name = "password"
	export let value
	export let label = "heslo"
	export let autocomplete
	export let errorMessage = null
	export let disabled = false
	export let note = undefined
	export let revealButtonText = 'Zobrazit'
	export let hideButtonText = 'Schovat'
</script>

<TextInput
	type="password"
	{name}
	{value}
	on:change
	minLength="8"
	{errorMessage}
	{label}
	{autocomplete}
	required
	{disabled}
	revealButtonText={revealButtonText}
	hideButtonText={hideButtonText}
>
	<slot slot="note">
		{#if note !== undefined}
			{note}
		{/if}
	</slot>
</TextInput>
