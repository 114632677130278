<script>
	import Button from './Button.svelte'
	import OrderSteps from './OrderSteps.svelte'
	import Picture from './Picture.svelte'

	export let order
	export let onOpen

	const maxProductsVisible = 4

	$: products = order.items.filter(it => it.type === 'product')
	$: productsWithImage = products.filter(it => it.image !== null)

	function handleDetailClick(event) {
		event.preventDefault()
		onOpen(order)
	}
</script>

<div class="orderPreview">
	<div class="orderPreview-info">
		<div class="orderPreview-row">{order.kindText} č. <span class="orderPreview-row-highlight">{order.orderNo}</span></div>
		<div class="orderPreview-row">Datum: <span class="orderPreview-row-highlight">{order.createdDate}</span></div>

		{#if order.status.code}
			<div class="orderPreview-steps">
				<div class="orderPreview-steps-in">
					<OrderSteps order={order} />
				</div>
				{#if order.status.link}
					<a href={order.status.link} class="orderPreview-status">{order.status.text}</a>
				{:else}
					<span class="orderPreview-status">{order.status.text}</span>
				{/if}
			</div>
		{/if}
	</div>

	<div class="orderPreview-products">
		{#each productsWithImage as product, index}
			{#if index < maxProductsVisible}
				<a href={product.url} class="orderPreview-products-item" title={product.title}>
					<div class="orderPreview-products-item-image">
						<div class="orderPreview-products-item-image-in">
							<Picture image={product.image} sizes="112px" ratio={1} />
						</div>
					</div>
				</a>
			{/if}
		{/each}

		{#if productsWithImage.length > maxProductsVisible}
			<div class="orderPreview-products-item">
				<span class="orderPreview-products-more">
					+{productsWithImage.length - (maxProductsVisible - 1)}
				</span>
			</div>
		{/if}
	</div>

	<div class="orderPreview-button">
		<Button condense endIcon="arrow-right" on:click={handleDetailClick} type="link" link={order.url}>Detail</Button>
	</div>
</div>
