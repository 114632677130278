<script>
	import Icon from './Icon.svelte'

	export let items
</script>

<ul class="iconList">
	{#each items as item}
		<li class="iconList-item">
			<span class="iconList-icon"><Icon name={item.icon} /></span><span class="iconList-text">{item.text}</span>
		</li>
	{/each}
</ul>
