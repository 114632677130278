<script>
	import clsx from 'clsx'
	import { uniqueId } from '../utils/uniqueId'
	import Collapsible from './Collapsible.svelte'
	import FormError from './FormError.svelte'
	import Icon from './Icon.svelte'

	/**
	 * @type {'text' | 'textarea' | 'email' | 'password' | 'search'}
	 */
	export let type = 'text'
	export let value = ''
	export let name
	export let label
	export let disabled = false
	export let required = false
	export let pattern = undefined
	export let minLength = undefined
	export let maxLength = undefined
	export let autocomplete = undefined
	export let errorMessage = undefined

	export let revealButtonText = 'Zobrazit'
	export let hideButtonText = 'Schovat'

	/**
	 * @type {null | 'search'}
	 */
	export let submitIcon = undefined

	let controlElement = undefined
	let togglePasswordTextElement = undefined
	let showRevealButton = type === 'password'
	let controlId = uniqueId('textInput-')
	
	let localErrorMessage = undefined
	let displayedErrorMessage = undefined

	$: {
		displayedErrorMessage = errorMessage || localErrorMessage || displayedErrorMessage
	}

	function handleFocus() {
		localErrorMessage = undefined
		errorMessage = undefined
	}

	function handleInvalid(event) {
		const input = event.target

		if (input.required && input.value.length === 0) {
			localErrorMessage = 'Vyplňte prosím toto pole'
		} else if (input.type === 'email') {
			localErrorMessage = 'Vyplňte e-mail ve správném formátu'
		} else if (maxLength && input.maxLength < input.value.length) {
			localErrorMessage = `Pole musí mít maximálně ${input.maxLength} znaků`
		} else if (input.type === 'password' && input.minLength >= input.value.length) {
			localErrorMessage = `Heslo musí mít alespoň ${input.minLength} znaků`
		} else if (input.name === 'postalCode') {
			localErrorMessage = `Vyplňte prosím číslo ve formátu: 123 45`
		} else {
			localErrorMessage = 'Vyplňte prosím toto pole ve správném formátu'
		}
	}

	function handleBlur(event) {
		event.target.checkValidity()
	}

	function handleTextAreaInput(event) {
		const target = event.target
		target.style.setProperty('--textInput-scrollHeight', 'auto')
		target.style.setProperty('--textInput-scrollHeight', `${target.scrollHeight}px`)
	}

	function togglePasswordVisibility() {
		if (type === 'password') {
			type = 'text'
			togglePasswordTextElement.textContent = revealButtonText
		} else {
			type = 'password'
			togglePasswordTextElement.textContent = hideButtonText
		}
	}
</script>

<label
	for={controlId}
	class={clsx(
		'textInput',
		Boolean(errorMessage || localErrorMessage) && 'is-validationShown',
		disabled && 'view-disabled',
	)}
>
	<div class="textInput-frame">
		{#if type !== 'textarea'}
			<!-- Prázdný placeholder, aby šlo v CSS použít selector ":placeholder-shown" -->
			<input
				id={controlId}
				on:invalid|preventDefault={handleInvalid}
				on:blur={handleBlur}
				on:focus={handleFocus}
				on:change
				on:input
				{type}
				value={value ?? ''}
				class="textInput-control"
				{name}
				placeholder=" "
				{required}
				{autocomplete}
				{disabled}
				minlength={minLength}
				maxlength={maxLength}
				pattern={pattern}
				bind:this={controlElement}
			/>
		{:else}
			<!-- Prázdný placeholder, aby šlo v CSS použít selector ":placeholder-shown" -->
			<textarea
				id={controlId}
				on:invalid|preventDefault={handleInvalid}
				on:focus={handleFocus}
				on:blur={handleBlur}
				on:change
				on:input
				{type}
				value={value ?? ''}
				class="textInput-control"
				{name}
				placeholder=" "
				{required}
				{disabled}
				minlength={minLength}
				maxlength={maxLength}
				rows="5"
				on:input={handleTextAreaInput}
				bind:this={controlElement}
			/>
		{/if}
		<div class="textInput-frame-in" />
		<span class="textInput-label">{label}</span>
		{#if showRevealButton}
			<button type="button" on:click={togglePasswordVisibility} class="textInput-button">
				<span class="textInput-button-text" bind:this={togglePasswordTextElement}>{revealButtonText}</span>
				<Icon name="legacy-eye" />
			</button>
		{/if}
		{#if submitIcon}
			<button type="submit" class="textInput-button">
				<Icon name={submitIcon} />
			</button>
		{/if}
	</div>
	<div class="textInput-error">
		<Collapsible expanded={Boolean(errorMessage || localErrorMessage)}>
			<FormError message={displayedErrorMessage} />
		</Collapsible>
	</div>
	{#if $$slots.note}
		<div class="textInput-note">
			<slot name="note" />
		</div>
	{/if}
</label>
