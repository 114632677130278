<div class="checkoutLayout">
	<div class="checkoutLayout-main">
		<slot name="main" />
	</div>
	{#if $$slots.aside}
		<div class="checkoutLayout-aside">
			<slot name="aside" />
		</div>
	{/if}
	<!-- https://github.com/sveltejs/svelte/issues/4546#issuecomment-627357929 -->
	{#if false}<slot />{/if}
</div>
