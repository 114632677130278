<script>
	import clsx from 'clsx'
	import Icon from './Icon.svelte'

	export let order

	const steps = [
		{
			status: 'unpaid',
			icon: 'cart',
		},
		{
			status: 'paid',
			icon: 'payment',
		},
		{
			status: 'shipping',
			icon: 'trolley',
		},
		{
			status: 'shipped',
			icon: 'check',
		},
	]

	let activeStepIndex = steps.findIndex((step) => {
		return step.status === order.status.code
	})
</script>

<ul class="orderSteps">
	{#if order.status.code === 'shipped'}
		<li class="orderSteps-item is-active">
			<span class="orderSteps-item-icon"><Icon name="cart" /></span>
		</li>
		{#if order.kind != 'Rezervace'}
			<li class="orderSteps-item is-active">
				<span class="orderSteps-item-icon"><Icon name="payment" /></span>
			</li>
		{/if}
		<li class="orderSteps-item is-active">
			<span class="orderSteps-item-icon"><Icon name="check" /></span>
		</li>
	{:else if order.status.code === 'cancelled'}
		<li class="orderSteps-item">
			<span class="orderSteps-item-icon"><Icon name="cross" /></span>
		</li>
	{:else}
		{#each steps as step, index}
			{#if order.payment.code === 'BRANA' || order.payment.code === 'ONLINE'}
				<li class={clsx("orderSteps-item", (index <= activeStepIndex) && 'is-active')}>
					<span class="orderSteps-item-icon"><Icon name={step.icon} /></span>
				</li>
			{:else}
				{#if index != 1}
					<li class={clsx("orderSteps-item", (index <= activeStepIndex) && 'is-active')}>
						<span class="orderSteps-item-icon"><Icon name={step.icon} /></span>
					</li>
				{/if}
			{/if}
		{/each}
	{/if}
</ul>
