<script>
	import Button from "./Button.svelte"
	import Picture from "./Picture.svelte"
	import Spacer from "./Spacer.svelte"
	import {toggleComparison} from "../api/account";

	export let product

	export let onDelete = () => {}

	async function handleDeleteClick() {
		await toggleComparison(product.id, false)
		onDelete()
	}

</script>

<div class="productInCompareTile">
	<div class="productInCompareTile-content">
		<a href={product.url} class="productInCompareTile-link"></a>
		<div class="productInCompareTile-image">
			<div class="productInCompareTile-image-in">
				<Picture image={product.image} sizes="(min-width: 768px) 300px, 100vw" />
			</div>
		</div>
		<div class="productInCompareTile-title">
			{product.title}
		</div>
		<div class="productInCompareTile-description">
			{product.description}
		</div>
		<div class="productInCompareTile-price">
			{product.price}
		</div>
	</div>
	<div class="productInCompareTile-buttons">
		<Button on:click={handleDeleteClick} type="button" variant="outlined">Odstranit z porovnání</Button>
		<Spacer initial="16" />
		<Button type="link" link={product.url}>Detail produktu</Button>
	</div>
</div>
