<script>
	import { onDestroy, onMount } from 'svelte'
	import SearchInput from './SearchInput.svelte'
	import {GtmProductHandler, pushGtmEvent} from "../utils/gtm";
	import {translate} from "../utils/translations";

	export let resultsSelector
	export let allResultsLinkSelector
	export let allResultsLink
	export let queryStringSelector

	const resultsElement = document.querySelector(resultsSelector)
	const allResultsLinkElement = document.querySelector(allResultsLinkSelector)
	const queryStringElement = document.querySelector(queryStringSelector)

	let inputElement
	let inputChangeTimeout

	let intersectionObserver
	let productListHelper
	let searchedValue = ''

	function onInput() {
		queryStringElement.textContent = inputElement.value
		clearTimeout(inputChangeTimeout)
		inputChangeTimeout = window.setTimeout(() => {
			loadSearchResults()
		}, 300)
	}

	async function loadSearchResults() {
		const value = inputElement.value;
		if (value.length >= 3) {
			const response = await fetch(`/api/search/box?query=${value}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			})
			const data = await response.json()
			if (data.htmlContent) {
				resultsElement.innerHTML = data.htmlContent
			}
			if (data.allResultsUrl) {
				allResultsLinkElement.href = data.allResultsUrl
				allResultsLinkElement.classList.add('is-visible')
			}
			productListHelper.setup()
			pushGtmEvent({
				event: "search.suggestView",
				search: {
					term: value,
					results: {
						count: data.count,
					}
				},
				_clear: true
			})
		} else {
			resultsElement.innerHTML = ''
			allResultsLinkElement.classList.remove('is-visible')
		}
	}

	onMount(() => {
		intersectionObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					onVisible()
				}
			})
		})
		intersectionObserver.observe(inputElement)

		productListHelper = new GtmProductHandler(resultsElement, 'Suggestions')
	})

	onDestroy(() => {
		productListHelper.destroy()
		intersectionObserver.unobserve(inputElement)
	})

	function onVisible() {
		requestAnimationFrame(() => {
			inputElement.focus()
		})
	}
</script>

<form class="headerSearchForm" action={allResultsLink}>
	<SearchInput on:input={onInput} bind:inputElement placeholder={translate('app.search.inputPlaceholder')} />
</form>
