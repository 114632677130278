<script>
	import { tick } from 'svelte'
	import Address from './Address.svelte'
	import AddressForm from './AddressForm.svelte'
	import AddressModalContent from './AddressModalContent.svelte'
	import Button from './Button.svelte'
	import Modal from './Modal.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'

	export let user
	export let address
	export let countryOptions
	export let addressOptions
	export let showCompany = false
	export let showSecondaryVatId = false

	let modalTitle = undefined
	let modalOpen = false

	async function handleOpenModal() {
		modalOpen = false
		await tick()
		modalOpen = true
	}

	function onAddressSelected(selectedAddress) {
		address = selectedAddress
		modalOpen = false
	}
</script>

{#if user.isSignedIn && addressOptions.length > 0}
	{#if address?.id}
		{#if address.city}
			<Address {address} />
		{:else}
			<AddressForm bind:address {countryOptions} hideEmail={user.isSignedIn} {showCompany} {showSecondaryVatId} />
		{/if}
		<Spacer initial="16" />
		<Button on:click={handleOpenModal} variant="outlined">Změnit</Button>
	{:else}
		<Button on:click={handleOpenModal}>
			<slot name="title" />
		</Button>
	{/if}

	<Modal open={modalOpen} type="center">
		<svelte:fragment slot="title">
			<Title level="2" viewLevel="4">
				{#if modalTitle !== undefined}
					{modalTitle}
				{:else}
					<slot name="title" />
				{/if}
			</Title>
		</svelte:fragment>
		<AddressModalContent {countryOptions} bind:modalTitle bind:addressOptions {onAddressSelected} selectedOption={address} />
	</Modal>
{:else}
	<AddressForm bind:address {countryOptions} hideEmail={user.isSignedIn} {showCompany} {showSecondaryVatId} />
{/if}
