<script>
	import FlashMessage from "./FlashMessage.svelte";
	import Spacer from "./Spacer.svelte";
	import Title from "./Title.svelte";
	import Address from "./Address.svelte";
	import LineSeparator from "./LineSeparator.svelte";

	export let store
	export let personContact
</script>
<FlashMessage type="success">
	<slot name="message"/>
</FlashMessage>

<Spacer initial="40"/>
{#if store}
	<Title level="5">Místo rezervace</Title>

	<Spacer initial="16"/>
	<strong>{store.name}</strong>
	<Address address={store.address}/>
	{#each store.contacts as contact}{contact.phone}<br>{/each}
	{#if store.url}<a href={store.url}>Více o pobočce</a>{/if}

	<Spacer initial="32"/>
	<LineSeparator/>
{/if}

<Spacer initial="32"/>
<Title level="5">Vaše kontaktní údaje</Title>

<Spacer initial="16"/>
{personContact.firstName} {personContact.lastName}<br>
{personContact.phone}<br>
{personContact.email}
