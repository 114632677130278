<script>
	import clsx from 'clsx'

	export let spaced = true
	export let condense = false

	const topLevelClass = clsx('checkoutRow', spaced && 'view-spaced', condense && 'view-condense')
</script>

<div class={topLevelClass}>
	<div class="checkoutRow-in">
		<slot />
	</div>
</div>
