<script>
	import Button from './Button.svelte'
	import Icon from './Icon.svelte'
	import {toggleComparison} from "../api/account";
	import {comparisons} from "../stores/comparisonStore";
	import {translate} from "../utils/translations";

	export let productId
	export let buttonVariant = "textual"

	let isInProductsCompare = false

	let buttonText = undefined
	let iconName = undefined

	$: {
		isInProductsCompare = $comparisons.find(it => it.id === productId) !== undefined
		if(isInProductsCompare) {
			buttonText = translate('app.product.compare.remove')
			iconName = "cross"
		} else {
			buttonText = translate('app.product.compare.add')
			iconName = "scales"
		}
	}

	async function handleButtonClick() {
		await toggleComparison(productId, !isInProductsCompare)
	}
</script>

{#if buttonVariant === 'textual'}
	<button
		class="productCompareButton"
		on:click={handleButtonClick}
	>
		<span class="productCompareButton-icon">
			<Icon name={iconName} />
		</span>
		{buttonText}
	</button>
{:else}
	<Button
		on:click={handleButtonClick}
		type="button"
		variant={buttonVariant}
		disableRipple={buttonVariant === 'textual'}
		condense
		startIcon={iconName}
	>
		{buttonText}
	</Button>
{/if}
