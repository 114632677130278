<script>
	import AddressSelect from './AddressSelect.svelte'
	import CheckoutRow from './CheckoutRow.svelte'
	import OptionInput from './OptionInput.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'
	import {translate} from "../utils/translations";

	export let cart
	export let countryOptions
	export let addressOptions
	export let shippingAddressSameExplicit
	export let shippingAddressSameAllowed
	export let shippingAddressSameEffective

	function handleChange(event) {
		shippingAddressSameExplicit = event.target.value === 'same'
	}
</script>

<CheckoutRow condense>
	<Title level="2" viewLevel="5">{translate('app.checkout.shippingAddress.title')}</Title>
	<Spacer initial="16" />

	<OptionInput
		type="radio"
		checked={shippingAddressSameEffective}
		disabled={!shippingAddressSameAllowed}
		value="same"
		on:change={handleChange}
		name="deliveryAddressSame"
	>
		<slot slot="label">{translate('app.checkout.shippingAddress.sameAsBilling')}</slot>
	</OptionInput>

	<Spacer initial="16" />

	<OptionInput
		type="radio"
		checked={!shippingAddressSameEffective}
		disabled={!shippingAddressSameAllowed}
		value="different"
		on:change={handleChange}
		name="deliveryAddressSame"
	>
		<slot slot="label">{translate('app.checkout.shippingAddress.different')}</slot>
	</OptionInput>

	{#if !shippingAddressSameEffective}
		<Spacer initial="16" />
		<AddressSelect user={cart.user} bind:address={cart.shippingAddress} {countryOptions} bind:addressOptions >
			<svelte:fragment slot="title">{translate('app.checkout.shippingAddress.prompt')}</svelte:fragment>
		</AddressSelect>
	{/if}
</CheckoutRow>
