<script>
	export let name
	export let label
	export let options
	export let autocomplete = undefined
	export let value
	export let required = false

	$: selectedOption = options.find(option => option.value === value)
</script>

<label class="select">
	<select on:change class="select-control" {name} {autocomplete} {required}>
		<option value="" style="display: none"></option>
		{#each options as option}
			<option value={option.value} selected={option === selectedOption}>{option.label}</option>
		{/each}
	</select>

	<div class="select-label">{label}</div>
	<div class="select-value">{selectedOption?.label ?? '\xa0'}</div>
	<span class="select-icon" />
</label>
