<script>
	import { filters,getGroupedSizes } from '../stores/filtersStore';
	import { getFormattedPriceValue } from '../utils/getFormattedPriceValue';
	import AppliedFiltersItem from './AppliedFiltersItem.svelte';

	export let currency
	export let groupSizes = false
</script>

<div class="appliedFilters">
	{#each $filters as item, index}
		{#if item.type === 'range'}
			{#if item.currentMin !== null}
				<AppliedFiltersItem attribute="price_min">
					Cena od {getFormattedPriceValue(item.currentMin, currency)}
				</AppliedFiltersItem>
			{/if}
			{#if item.currentMax !== null}
				<AppliedFiltersItem attribute="price_max">
					Cena do {getFormattedPriceValue(item.currentMax, currency)}
				</AppliedFiltersItem>
			{/if}
		{:else if item.type === 'options'}
			{#if item.code === 'size' && groupSizes}
				{#each Object.entries(getGroupedSizes(item)?.groups) as [groupKey, group]}
					{#if group.checked}
						<AppliedFiltersItem attribute={item.code} optionGroup={group.items.map((itemValue) => {return itemValue.label})} label={groupKey}>
							{group.label}
						</AppliedFiltersItem>
					{/if}
				{/each}
			{:else}
				{#if item.checkedOptions.length > 0}
					{#each item.checkedOptions as option}
						{#if item.code === 'color'}
							<AppliedFiltersItem attribute={item.code} option={option}>
								<div
									class="appliedFilters-color"
									style={`--appliedFilters-color: ${item.allOptions[option]?.colorHex ?? '#fff'}`}
								/>

							</AppliedFiltersItem>
						{:else}
							<AppliedFiltersItem attribute={item.code} option={option} view={item.code === 'brand' ? 'brand' : undefined}>
								{item.allOptions[option]?.label ?? option}
							</AppliedFiltersItem>
						{/if}
					{/each}
				{/if}
			{/if}
		{/if}
	{/each}
</div>
