<script>
	export let openingHours
</script>

<div class="openingHours">
	{#each openingHours as hours}
		<span class="openingHours-day">{hours.startDay}{#if hours.startDay !== hours.endDay}&nbsp;&ndash;&nbsp;{hours.endDay}{/if}:</span>
		<span class="openingHours-hours">{hours.timeBlocks}</span>
	{/each}
</div>
