<script>
	import clsx from 'clsx'
	import { translate } from '../utils/translations'
	import CartItemInfo from './CartItemInfo.svelte'
	import CartItemPrice from './CartItemPrice.svelte'
	import CartItemQuantity from './CartItemQuantity.svelte'
	import Collapsible from './Collapsible.svelte'
	import OptionalFeature from './OptionalFeature.svelte'
	import Picture from './Picture.svelte'
	import RemoveButton from './RemoveButton.svelte'

	export let productVariantId
	export let quantity
	export let requestedQuantity
	export let availableQuantity
	export let showCode = false
	export let forceExpand = false

	export let url
	export let title
	export let subtitle
	export let size
	export let image
	export let code = undefined

	export let price
	export let complements = undefined
	export let setQuantity = null

	$: discountDescription = [
		price ? price.discountDescription : null,
		...(complements ?? []).filter((it) => it.quantity).map((it) => it.price.discountDescription),
	]
		.filter((it) => !!it)
		.join(', ')

	function removeItem() {
		setQuantity(productVariantId, null, 0)
	}
</script>

<Collapsible expanded={quantity > 0 || requestedQuantity > 0 || forceExpand} transitionType="rightInsert">
	<div class={clsx('cartItem', quantity === 0 && 'view-sold-out')}>
		<div class="cartItem-productDetails">
			<div class="cartItem-image">
				<div class="cartItem-image-in">
					<Picture {image} sizes="112px" ratio={1} />
				</div>
			</div>
			<div class="cartItem-info">
				<CartItemInfo {url} {title} {subtitle} {size} {code} {showCode} {discountDescription} />
			</div>
			{#if setQuantity}
				<div class="cartItem-quantity">
					<CartItemQuantity
						{productVariantId}
						{quantity}
						{requestedQuantity}
						{availableQuantity}
						{setQuantity}
					/>
				</div>
			{/if}
			<div class="cartItem-price"><CartItemPrice {...price} /></div>
			{#if setQuantity}
				<div class="cartItem-remove">
					<RemoveButton on:click={removeItem} />
				</div>
			{/if}
		</div>
		{#if complements && complements.length > 0}
			<span class="cartItem-optionalFeaturesCaption">{translate('app.checkout.recommendedProducts')}</span>
			<div class="cartItem-optionalFeatures">
				{#each complements as complement}
					<OptionalFeature baseItemVariantId={productVariantId} {...complement} {setQuantity} />
				{/each}
			</div>
		{/if}
	</div>
</Collapsible>
