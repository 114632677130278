<script>
	import clsx from 'clsx'
	import Collapsible from './Collapsible.svelte'
	import FormError from './FormError.svelte'
	import OptionInput from './OptionInput.svelte'
	import Spacer from './Spacer.svelte'

	export let name
	export let items
	export let checkedValue = null
	export let view = null

	export let errorMessage = undefined

	export let handleChange = null
</script>

<div class={clsx('radio', view && `view-${view}`)}>
	<div class="radio-items">
		{#each items as item}
			<div class="radio-item">
				<OptionInput
					on:change={handleChange}
					type="radio"
					value={item.value}
					{name}
					checked={item.value === checkedValue}
					disabled={item.disabled || false}
				>
					<slot slot="label">{item.label}</slot>
					<slot name="main" slot="main" {item}>
						{#if item.description}
							<Spacer initial="6" break768="16" />
							{item.description}
						{/if}
					</slot>
					<slot slot="aside">
						{#if item.price}
							{item.price.formatted || item.price}
						{/if}
					</slot>
				</OptionInput>
			</div>
		{/each}
	</div>
	{#if errorMessage}
		<Collapsible expanded={!!errorMessage}>
			<div class="radio-error">
				<FormError message={errorMessage} />
			</div>
		</Collapsible>
	{/if}
</div>
