<script>
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import { emailPattern } from '../utils/emailPattern'
	import { passwordResetRequest } from '../api/account'
	import Button from './Button.svelte'
	import Collapsible from './Collapsible.svelte'
	import FormError from './FormError.svelte'
	import FormRow from './FormRow.svelte'
	import Spacer from './Spacer.svelte'
	import FlashMessage from './FlashMessage.svelte'
	import TextInput from './TextInput.svelte'

	let email = ''
	let errors = {}
	let successMessage = undefined
	let disableInputs = false

	let formElement = undefined

	async function handleSubmit() {
		const response = await passwordResetRequest(email)

		if (response.successMessage) {
			successMessage = response.successMessage
			disableInputs = true
		}
		if (response.errors) {
			errors = response.errors
		}
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	function handleEmailInputChange(event) {
		email = event.target.value
	}
</script>

<form on:submit|preventDefault={handleSubmit} class="passwordResetRequestForm" method="post" bind:this={formElement}>
	<Collapsible expanded={!!successMessage}>
		{#if successMessage}
			<FlashMessage type="success">{successMessage}</FlashMessage>
			<Spacer initial="16" />
		{/if}
	</Collapsible>

	<Collapsible expanded={errors.general}>
		{#if errors.general}
			{#each errors.general as error}
				<FormError message={error} view="standalone" />
			{/each}
			<Spacer initial="16" />
		{/if}
	</Collapsible>

	<FormRow>
		<TextInput
			type="email"
			name="email"
			value={email}
			on:change={handleEmailInputChange}
			errorMessage={errors.email?.join(';')}
			label="E-mail"
			autocomplete=""
			required
			disabled={disableInputs}
			pattern={emailPattern}
		/>
	</FormRow>
	<FormRow>
		<Button on:click={submitButtonClick} type="submit" disabled={disableInputs}>Odeslat žádost</Button>
	</FormRow>
	<Spacer initial="24" />
	<a class="passwordResetRequestForm-link" href="/prihlaseni">Zpátky na přihlášení</a>
</form>
