<script>
	import { sendComponentMessage } from '../utils/initializeComponents'
	import { FilterProducts } from '../components/FilterProducts'
	import Button from './Button.svelte'
	import Modal from './Modal.svelte'
	import Radio from './Radio.svelte'
	import Select from './Select.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'

	export let sortOptions
	export let filterProductsSelector
	export let initialSort

	let buttonText = 'Řadit'
	let modalOpen = false
	let value = initialSort
	const filterProductsElement = document.querySelector(filterProductsSelector)

	function handleInputChange(event) {
		value = event.target.value
		buttonText = sortOptions.find((option) => {
			return option.value === value
		})?.label
		sendComponentMessage(filterProductsElement, FilterProducts, 'setSort', value)

		setTimeout(() => {
			modalOpen = !modalOpen
		}, 300)
	}
</script>

<form class="sortOptionsFormMobile">
	<Button on:click={() => {
		modalOpen = !modalOpen
	}} variant="outlined" condense startIcon="legacy-sort">{buttonText}</Button>
	<Modal open={modalOpen} type="right">
		<div class="sortOptionsFormMobile-header">
			<div>
				<Button on:click={() => {modalOpen = !modalOpen}} variant="text" contentIcon="arrow-left" size="small" />
			</div>
			<Title level="4" viewLevel="4" centered>Řadit</Title>
		</div>
		<Spacer initial="32" />
		<Radio items={sortOptions} checkedValue={value} name="gender" handleChange={handleInputChange} view="block" />
	</Modal>
</form>
