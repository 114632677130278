<script>
	import ProgressIndicator from './ProgressIndicator.svelte'

	export let currentPrice = 0
	export let priceGoal = 0
</script>

<div class="freeShippingProgressIndicator">
	<ProgressIndicator start={0} end={currentPrice / priceGoal} />
</div>
