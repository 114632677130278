<script>
	import clsx from 'clsx'
	import { timestampToLocaleDateString } from '../utils/timestampToLocaleDateString'

	export let label
	export let timestamp = null
	export let isInFuture = false
</script>

<div class="inspectionStatus">
	<div class={clsx('inspectionStatus-icon', isInFuture && 'is-inFuture')} />
	<div>
		<b>{label}</b>{#if timestamp}: {timestampToLocaleDateString(timestamp)}{/if}
	</div>
</div>
