<script>
	import Icon from './Icon.svelte'

	export let address
</script>

<address class="address">
	{#if address.companyName}
		<div class="address-line">{address.companyName}</div>
	{/if}
	{#if address.firstName || address.lastName}
		<div class="address-line">{address.firstName} {address.lastName}</div>
	{/if}
	{#if address.addressLine1}
		<div class="address-line">{address.addressLine1}</div>
	{/if}
	{#if address.city && address.postalCode}
		<div class="address-line">{address.postalCode} {address.city}</div>
	{/if}
	{#if address.phone}
		<div class="address-phone"><span class="address-phone-icon"><Icon name="legacy-phone" /></span>{address.phone}</div>
	{/if}
</address>
