<script>
	import { Modal as ExternalModal } from '../components/Modal'
	import { sendComponentMessage } from '../utils/initializeComponents'
	import { translate } from '../utils/translations'
	import AdditionalOptionRow from './AdditionalOptionRow.svelte'
	import AddToCartButton from './AddToCartButton.svelte'
	import Box from './Box.svelte'
	import Button from './Button.svelte'
	import Collapsible from './Collapsible.svelte'
	import Picture from './Picture.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'
	import VenueDetails from './VenueDetails.svelte'

	export let variantId
	export let buyRedirectUri
	export let isAvailableSomewhere
	export let stores
	export let deliveryDateLabelForEshopStockedProducts
	export let isVariantBike

	export let reservationModalId
	export let stockAvailabilityModalId

	const reservationModal = document.querySelector(`#${reservationModalId}`)
	const stockAvailabilityModal = document.querySelector(`#${stockAvailabilityModalId}`)

	function isAvailableAtStore(store) {
		return store.quantity > 0
	}

	function onReservationButtonClick() {
		sendComponentMessage(stockAvailabilityModal, ExternalModal, 'close')
		sendComponentMessage(reservationModal, ExternalModal, 'open')
	}
</script>

<div class="stockAvailabilityAtStores">
	{#if isAvailableSomewhere}
		<Box>
			<div class="stockAvailabilityAtStores-item">
				<AdditionalOptionRow icon="chevron-down" showToggleExpandedButton={false}>
					<slot slot="left">
						{translate('app.product.detail.stockAvailability.eshop')}
						{#if isVariantBike}
							<span class="stockAvailabilityAtStores-status-note">{translate('app.product.detail.stockAvailability.bikesNote')}</span>
						{/if}
					</slot>
					<slot slot="right">
						<span class="stockAvailabilityAtStores-status is-available">
						{#if isVariantBike}
								{translate('app.product.detail.stockAvailability.stocked')}
							{:else}
								{translate('app.product.detail.stockAvailability.stockedPrefix')}
								{deliveryDateLabelForEshopStockedProducts}
							{/if}
						</span>
					</slot>
					<div class="stockAvailabilityAtStores-item-content" slot="content">
						<div class="stockAvailabilityAtStores-item-details">
							<Spacer initial="12" />
							<AddToCartButton productVariantId={variantId} redirectUri={buyRedirectUri} />
						</div>
					</div>
				</AdditionalOptionRow>
			</div>
		</Box>
		<Spacer initial="32" />
	{/if}
	<Title level="2" viewLevel="4">
		{translate('app.product.detail.stockAvailability.atStores')}
	</Title>
	<Spacer initial="16" />
	{#each stores as store}
		<Box>
			<div class="stockAvailabilityAtStores-item">
				<AdditionalOptionRow icon="chevron-down" expandedIcon="chevron-right" showToggleExpandedButton={false}>
					<slot slot="left">
						{store.name}
					</slot>
					<slot slot="right">
						{#if isAvailableAtStore(store)}
							<span class="stockAvailabilityAtStores-status is-available"
								>{translate('app.product.detail.stockAvailability.availableAtStore')}</span
							>
						{:else if isAvailableSomewhere}
							<span class="stockAvailabilityAtStores-status is-unavailable"
								>{translate('app.product.detail.stockAvailability.availableSoon')}
								{store.nextAvailableDayLabel}</span
							>
						{:else}
							<span class="stockAvailabilityAtStores-status is-unavailable"
								>{translate('app.product.detail.stockAvailability.outOfStock')}</span
							>
						{/if}
					</slot>
					<div slot="main">
						{store.address.addressLine1},
						{store.address.city},
						{store.address.postalCode}
					</div>
					<div class="stockAvailabilityAtStores-item-content" slot="content">
						<div class="stockAvailabilityAtStores-item-image">
							<Collapsible expanded={true} transitionType="bottomInsert">
								<div class="stockAvailabilityAtStores-item-image-in">
									<Picture image={store.image} sizes="215px" />
								</div>
							</Collapsible>
						</div>
						<div class="stockAvailabilityAtStores-item-details">
							<Spacer initial="12" />
							<VenueDetails venue={{ store: store }}>
								<div class="stockAvailabilityAtStores-item-buttons" slot="button">
									{#if isAvailableAtStore(store)}
										<div class="stockAvailabilityAtStores-item-button">
											<Button variant="outlined" on:click={onReservationButtonClick}>
												{translate('app.product.detail.reserveHere')}
											</Button>
										</div>
										<div class="stockAvailabilityAtStores-item-button">
											<AddToCartButton
												productVariantId={variantId}
												redirectUri={buyRedirectUri}
											/>
										</div>
									{/if}
								</div>
							</VenueDetails>
						</div>
					</div>
				</AdditionalOptionRow>
			</div>
		</Box>
	{/each}
</div>
