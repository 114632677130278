<script>
	import { onMount } from 'svelte'
	import Button from './Button.svelte'

	export let productsWrapperId

	let buttonText
	let buttonStartIcon

	const productsWrapper = document.getElementById(productsWrapperId)
	const productsFavoritesToggleButtons = productsWrapper.querySelectorAll('.productTileSecondary-heart')

	setGeneralButtonContent()

	onMount(() => {
		// Observe classlist changes on target buttons. If any of them change, update general button content
		const observer = new MutationObserver(entries => {
			entries.forEach(entry => {
				if(entry.attributeName === 'class') {
					setGeneralButtonContent()
				}
			})
		})
		productsFavoritesToggleButtons.forEach((button) => {
			observer.observe(button, { attributes: true })
		})
	})

	function onClick() {
		const allProductsInFavorites = isAllProductsInFavorites()
		productsFavoritesToggleButtons.forEach((button, i) => {
			if(allProductsInFavorites || (!allProductsInFavorites && !button.classList.contains('view-favorite'))) {
				// @TODO race condition při updatování počtu oblíbených položek
				setTimeout(() => {
					button.click()
				}, i * 50)
			}
		})
	}

	function isAllProductsInFavorites() {
		let allProductsInFavorites = true
		productsFavoritesToggleButtons.forEach((button) => {
			if(!button.classList.contains('view-favorite')) {
				allProductsInFavorites = false
			}
		})
		return allProductsInFavorites
	}

	function setGeneralButtonContent() {
		if(isAllProductsInFavorites()) {
			buttonStartIcon = 'heart-filled'
			buttonText = 'Odebrat vše z oblíbených'
		} else {
			buttonStartIcon = 'heart-outline'
			buttonText = 'Vše do oblíbených'
		}
	}
</script>

<Button type="button" variant="outlined" condense on:click={onClick} startIcon={buttonStartIcon}>{buttonText}</Button>
