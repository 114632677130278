<script>
	import { putServicebookOrder } from '../api/product'
	import { emailPattern } from '../utils/emailPattern'
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import { translate } from '../utils/translations'
	import Button from './Button.svelte'
	import FancyPhoneInput from './FancyPhoneInput.svelte'
	import FlashMessage from './FlashMessage.svelte'
	import FormRow from './FormRow.svelte'
	import Modal from './Modal.svelte'
	import ModalFormSubmitButton from './ModalFormSubmitButton.svelte'
	import Select from './Select.svelte'
	import Spacer from './Spacer.svelte'
	import TextInput from './TextInput.svelte'
	import Title from './Title.svelte'

	export let isOpen
	export let productName
	export let productSize
	export let userEmail
	export let userPhone
	export let userFirstName
	export let userLastName

	export let serviceItemNo
	export let serviceOffers

	$: serviceTypeOptions = serviceOffers.map((offer) => ({
		label: offer.name,
		value: offer.id,
	}))

	let email = userEmail
	let phone = userPhone
	let firstName = userFirstName
	let lastName = userLastName
	let note = ''
	let serviceType = null
	let store = null

	$: storeOptions =
		serviceType?.stores.map((store) => ({
			label: store.name,
			value: store.id,
		})) || []

	let result = null

	function handleEmailChange(event) {
		email = event.target.value
	}

	function handlePhoneChange(value) {
		phone = value.replace(/\s+/g, '')
	}

	function handleFirstNameChange(event) {
		firstName = event.target.value
	}

	function handleLastNameChange(event) {
		lastName = event.target.value
	}

	function handleNoteChange(event) {
		note = event.target.value
	}

	function handleServiceTypeChange(event) {
		serviceType = serviceOffers.find((offer) => offer.id === event.target.value)
		store = null
	}

	function handleStoreChange(event) {
		store = serviceType?.stores.find((store) => store.id === event.target.value)
	}

	let formElement = undefined

	async function onSaveOrder() {
		const payload = await putServicebookOrder(
			serviceType?.id,
			serviceItemNo,
			firstName,
			lastName,
			email,
			phone,
			note,
			store?.id,
		)

		result = payload.success
	}

	function submitButtonClick() {
		focusInvalidInput(formElement)
	}
</script>

<Modal open={isOpen} type="center">
	<svelte:fragment slot="title">
		<Title level="2" viewLevel="4">
			{translate('userService.order.modal.title')}
		</Title>
	</svelte:fragment>
	<Title level="3" viewLevel="5">
		{productName} <small>{translate('userService.product.size')}: {productSize}</small>
	</Title>
	<Spacer initial="24" />
	{#if result === null}
		<form on:submit|preventDefault={onSaveOrder} mehod="post" bind:this={formElement}>
			<FormRow>
				<Select
					name="serviceTypeId"
					options={serviceTypeOptions}
					value={serviceType?.id}
					on:change={handleServiceTypeChange}
					required
					label={translate('app.formInputs.serviceType')}
				/>
			</FormRow>
			<FormRow columnCount={2}>
				<TextInput
					name="firstName"
					label={translate('app.formInputs.firstName')}
					required
					autocomplete="given-name"
					value={firstName}
					on:change={handleFirstNameChange}
				/>
				<TextInput
					name="lastName"
					label={translate('app.formInputs.lastName')}
					required
					autocomplete="family-name"
					value={lastName}
					on:change={handleLastNameChange}
				/>
			</FormRow>
			<FormRow columnCount={2}>
				<TextInput
					type="email"
					name="email"
					label="E-mail"
					required
					autocomplete="email"
					pattern={emailPattern}
					value={email}
					on:change={handleEmailChange}
				/>
				<FancyPhoneInput {phone} onChange={handlePhoneChange} />
			</FormRow>
			<FormRow>
				<Select
					name="storeId"
					options={storeOptions}
					value={store?.id}
					on:change={handleStoreChange}
					required
					label={translate('app.formInputs.store')}
				/>
			</FormRow>
			<FormRow>
				<TextInput
					name="note"
					label={translate('app.formInputs.note')}
					type="textarea"
					value={note}
					maxLength="250"
					on:change={handleNoteChange}
				/>
			</FormRow>

			<Spacer initial="16" />

			<div class="reservationForm-gdpr">
				{@html translate('app.form.reservationPrivacyPolicyConsent')}
			</div>

			<Spacer initial="40" />
			<ModalFormSubmitButton>
				<Button on:click={submitButtonClick} type="submit" endIcon="arrow-right" condense
					>{translate('userService.order.modal.save')}</Button
				>
			</ModalFormSubmitButton>
		</form>
	{:else if result === true}
		<Spacer initial="24" />
		<FlashMessage type="success">{translate('userService.order.success')}</FlashMessage>
	{:else}
		<Spacer initial="24" />
		<FlashMessage type="error">{translate('userService.order.failure')}</FlashMessage>
	{/if}
</Modal>
