<script>
	import { tick } from 'svelte'
	import { emailPattern } from '../utils/emailPattern'
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	// import { postSignUp } from '../api/account'
	import Button from './Button.svelte'
	import Collapsible from './Collapsible.svelte'
	import FormError from './FormError.svelte'
	import FormRow from './FormRow.svelte'
	import Modal from './Modal.svelte'
	import Spacer from './Spacer.svelte'
	import TextInput from './TextInput.svelte'
	import Title from "./Title.svelte"
	import Select from "./Select.svelte"
	import {putServiceOrder} from "../api/service"
	import ReservationSuccess from "./ReservationSuccess.svelte"
	import {pushGtmEvent} from "../utils/gtm";
	import {translate} from "../utils/translations";

	export let buttonText
	export let storeOptions
	export let service

	let modalOpen = false

	let formElement = undefined

	let values = {
		email: '',
		firstName: '',
		lastName: '',
		phone: '',
		storeId: null,
		note: '',
	}

	let sent = false
	$: selectedStore = storeOptions.find(it => it.id === values.storeId)

	async function handleSubmit(e) {
		const serviceName = service.name ?? window.location.href;
		await putServiceOrder({
			...values,
			servicePrice: service.price,
			serviceName: serviceName,
		})
		sent = true
		await pushGtmEvent({
			event: 'service.order',
			service: {
				name: serviceName,
				store: selectedStore?.name,
			},
			customer: {
				email: values.email,
				jmeno: values.firstName,
				prijmeni: values.lastName,
				telefon: values.phone
			}
		})
	}


	let errors = {}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	function handleChange(event) {
		const {name, value, type, checked} = event.target
		values[name] = type === 'checkbox' ? checked : value
	}

	async function handleOpenModal() {
		modalOpen = false
		await tick()
		modalOpen = true
	}
</script>


<div class="orderServiceForm-modalOpener">
	<Button type="button" on:click={handleOpenModal} variant="contained">{buttonText}</Button>
</div>

<Modal open={modalOpen}>
	<svelte:fragment slot="title">
		{#if sent}
			<Title level="2" viewLevel="4">Rezervace</Title>
		{:else}
			<Title level="2" viewLevel="4">Objednat</Title>
		{/if}
	</svelte:fragment>
	{#if sent}
		<ReservationSuccess store={selectedStore} personContact={values}>
			<slot slot="message">
				{translate('app.serviceDetail.order.success')}
			</slot>
		</ReservationSuccess>
	{:else}
		<form on:submit|preventDefault={handleSubmit} method="post" bind:this={formElement}>
			<Spacer initial="16"/>

			<Collapsible expanded={errors.general}>
				{#if errors.general}
					{#each errors.general as error}
						<FormError message={error} view="standalone"/>
					{/each}
					<Spacer initial="16"/>
				{/if}
			</Collapsible>

			<FormRow columnCount="2">
				<TextInput
					type="text"
					name="firstName"
					on:change={handleChange}
					errorMessage={errors.firstName?.join(';')}
					label={translate('app.formInputs.firstName')}
					autocomplete="given-name"
				/>
				<TextInput
					type="text"
					name="lastName"
					on:change={handleChange}
					errorMessage={errors.lastName?.join(';')}
					label={translate('app.formInputs.lastName')}
					autocomplete="family-name"
				/>
			</FormRow>

			<FormRow columnCount={2}>
				<TextInput
					type="email"
					name="email"
					on:change={handleChange}
					errorMessage={errors.email?.join(';')}
					label="E-mail"
					autocomplete=""
					required
					pattern={emailPattern}
				/>
				<TextInput
					type="tel"
					name="phone"
					on:change={handleChange}
					errorMessage={errors.tel?.join(';')}
					label={translate('app.formInputs.phone')}
					autocomplete=""
					required
				/>
			</FormRow>

			<FormRow>
				<Select
					name="storeId"
					options={storeOptions.map(it => ({value: it.id, label: it.name}))}
					value={values.storeId}
					on:change={handleChange}
					required
					label="Prodejna"
					autocomplete=""
				/>
			</FormRow>

			<FormRow>
				<TextInput
					type="textarea"
					name="note"
					on:change={handleChange}
					errorMessage={errors.note?.join(';')}
					label="Poznámka"
					autocomplete=""
					maxLength="250"
				>
					<slot slot="note">
						{translate('app.form.note.note')}
					</slot>
				</TextInput>
			</FormRow>

			<Spacer initial="32"/>

			<p class="orderServiceForm-gdprNote">{@html translate('app.form.reservationPrivacyPolicyConsent')} {@html translate('app.form.ourTeamWillReachOut')}</p>

			<Spacer initial="40"/>

			<div class="orderServiceForm-button">
				<Button on:click={submitButtonClick} type="submit" endIcon="legacy-arrow-right">Odeslat rezervaci</Button>
			</div>
		</form>
	{/if}
</Modal>
