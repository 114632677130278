<script>
	import CheckoutRow from './CheckoutRow.svelte'
	import Button from './Button.svelte'
	import Todo from './Todo.svelte'
	import { putOrderRetry } from '../api/cart'

	export let orderId
	export let orderToken
	export let paymentOptions

	async function retryOrder(paymentMethodCode) {
		window.location.href = (await putOrderRetry(orderId, orderToken, paymentMethodCode)).redirect
	}
</script>

{#each paymentOptions as paymentOption}
	<CheckoutRow>
		<Todo>
			{paymentOption.label}
			<div style='float: right'>
				<Button on:click={e => retryOrder(paymentOption.code)}>Zaplatit</Button>
			</div>
		</Todo>
	</CheckoutRow>
{/each}
