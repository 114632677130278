<script>
	import Box from './Box.svelte'
	import Collapsible from './Collapsible.svelte'
	import Map from './Map.svelte'
	import OptionInput from './OptionInput.svelte'
	import Picture from './Picture.svelte'
	import RenderContentIfVisible from './RenderContentIfVisible.svelte'
	import Spacer from './Spacer.svelte'
	import VenueDetails from './VenueDetails.svelte'

	export let mapPinIcon

	export let venues = []
	export let selected = null

	const mapMarkers = venues.flatMap((venue) => {
		if (!venue.address.gpsLat || !venue.address.gpsLng) {
			return []
		}

		return [{
			title: venue.label,
			latitude: venue.address.gpsLat,
			longitude: venue.address.gpsLng,
			icon: mapPinIcon,
		}]
	})

	let mapCenter = {
		latitude: 50.08291317108088,
		longitude: 14.421589136244334,
	}

	function handleChange(index) {
		selected = venues[index]

		if (selected.address.gpsLat && selected.address.gpsLng) {
			mapCenter = { latitude: selected.address.gpsLat, longitude: selected.address.gpsLng }
		}
	}
</script>

<div class="venueOptions">
	<div class="venueOptions-items">
		{#each venues as venue, index}
			<Box>
				<div class="venueOptions-item">
					<OptionInput
						type="radio"
						name="venues"
						on:change={() => handleChange(index)}
						checked={venue === selected}
					>
						<slot slot="label">{venue.label}</slot>
						<div class="venueOptions-item-content" slot="main">
							<div>
								<Spacer initial="6" break768="16" />
								{venue.address.addressLine1},
								{venue.address.city},
								{venue.address.postalCode}

								{#if venue.store}
									<div class="venueOptions-item-image">
										<Collapsible expanded={venue === selected} transitionType="bottomInsert">
											<Picture image={venue.store.primaryImage} />
										</Collapsible>
									</div>
								{/if}

								<Collapsible expanded={venue === selected} transitionType="topInsert">
									<Spacer initial="12" />
									<VenueDetails {venue} {mapPinIcon}>
										<slot name="button" slot="button" item={venue} />
									</VenueDetails>
								</Collapsible>
							</div>
						</div>
					</OptionInput>
				</div>
			</Box>
		{/each}
	</div>
	<div class="venueOptions-map">
		<RenderContentIfVisible>
			<Map center={mapCenter} markers={mapMarkers} />
		</RenderContentIfVisible>
	</div>
</div>
