<script>
	import { slide } from 'svelte/transition';
	import {translate} from '../utils/translations'
	import CheckoutRow from "./CheckoutRow.svelte";
	import AdditionalOptionRow from "./AdditionalOptionRow.svelte";
	import Spacer from "./Spacer.svelte";
	import FormError from "./FormError.svelte";
	import FormRow from "./FormRow.svelte";
	import RemoveButton from "./RemoveButton.svelte";
	import TextInput from "./TextInput.svelte";
	import { cart } from '../stores/cartStore'
	import Todo from "./Todo.svelte";
	import {applyVoucher, removeVoucher} from "../api/cart";
	import Button from "./Button.svelte";
	import CartItemPrice from "./CartItemPrice.svelte";

	let code = ''
	const handleCodeChange = (event) => {
		code = event.target.value
	}
	let message = ''
	const handleSubmit = async () => {
		const result = await applyVoucher(code)
		$cart = result.cart
		if (result.voucherApplication.success) {
			code = ''
			message = ''
		} else {
			message = result.voucherApplication.message
		}
	}
	const handleRemove = async (id) => {
		const result = await removeVoucher(id)
		$cart = result.cart
	}
</script>

<CheckoutRow>
	<AdditionalOptionRow icon="percentage" expanded={$cart.vouchers.length > 0 || message}>

		<slot slot="left">{translate('app.checkout.vouchers.question')}</slot>
		<slot slot="right">{translate('app.checkout.vouchers.prompt')}</slot>
		<slot slot="content">
			{#if message}
				<Todo>{message}</Todo>
			{/if}
			{#each $cart.vouchers as voucher}
				<div class="additionalOptionRow-voucher" transition:slide>
					<div class="additionalOptionRow-voucher-main">
						<div class="additionalOptionRow-voucher-line">
							{voucher.code}
							<div class="additionalOptionRow-voucher-remove">
								<RemoveButton on:click={() => handleRemove(voucher.id)} />
							</div>
						</div>
						{#if voucher.warning}
							<FormError message={voucher.warning} />
						{/if}
					</div>
					{#if voucher.price}
						<div class="additionalOptionRow-voucher-price">
							<CartItemPrice original={voucher.price} discounted={false}/>
						</div>
					{/if}
				</div>
			{/each}
			<Spacer initial="16"/>
			<form on:submit|preventDefault={handleSubmit}>
				<FormRow columnCount="2">
					<TextInput name="voucher" label={translate('app.checkout.vouchers.label')} value={code} on:change={handleCodeChange}/>
					<Button on:click={handleSubmit} size="formItem">{translate('app.checkout.vouchers.submit')}</Button>
				</FormRow>
			</form>
		</slot>
	</AdditionalOptionRow>
</CheckoutRow>
