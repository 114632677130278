<script>
	import AdditionalOptionRow from './AdditionalOptionRow.svelte'
	import CheckoutRow from './CheckoutRow.svelte'
	import SignUpForm from './SignUpForm.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'
	import {translate} from "../utils/translations";

	export let loyaltyProgramLink
</script>

<CheckoutRow>
	<AdditionalOptionRow icon="legacy-star">
		<slot slot="left">
			<strong>{translate('app.checkout.signUp.title')}</strong><br />
			{translate('app.checkout.signUp.text.part1')} <a href={loyaltyProgramLink}>{translate('app.checkout.signUp.text.part2')}</a>.
		</slot>

		<slot slot="right">{translate('app.signUp.createButton')}</slot>

		<slot slot="content">
			<Spacer initial="40" />
			<Title level="4" viewLevel="6">{translate('app.signUp.createCredentials')}</Title>
			<Spacer initial="24" />
			<SignUpForm onSignUp={() => {
				window.location.href = window.location.href + (window.location.href.includes('?') ? '&' : '?') + 'signUpOk=1'
			}}/>
		</slot>
	</AdditionalOptionRow>
</CheckoutRow>
