<script>
	import { fetchCart,fetchOrderLoyaltyStatus,putCartItemQuantity } from '../api/cart'
	import { cart } from '../stores/cartStore'
	import { orderLoyaltyStatus } from '../stores/orderLoyaltyStatusStore'
	import { translate } from '../utils/translations'
	import AdditionalOptionRow from './AdditionalOptionRow.svelte'
	import Button from './Button.svelte'
	import CartHeader from './CartHeader.svelte'
	import CartItem from './CartItem.svelte'
	import CartShare from "./CartShare.svelte"
	import CheckoutLoyaltyProgress from './CheckoutLoyaltyProgress.svelte'
	import CheckoutNavigationRow from './CheckoutNavigationRow.svelte'
	import CheckoutRow from './CheckoutRow.svelte'
	import CheckoutSignInForm from './CheckoutSignInForm.svelte'
	import CheckoutSignUpForm from './CheckoutSignUpForm.svelte'
	import CheckoutWarnings from "./CheckoutWarnings.svelte"
	import EmptyCart from './EmptyCart.svelte'
	import FreeShippingProgressIndicator from './FreeShippingProgressIndicator.svelte'
	import PriceSummary from './PriceSummary.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'
	import Vouchers from './Vouchers.svelte'

	export let homeLink
	export let nextStepLink
	export let passwordResetRequestLink
	export let loyaltyProgramLink

	async function setQuantity(productVariantId, complementOfId, quantity) {
		// optimistic UI update
		const baseItemVariantId = complementOfId ?? productVariantId
		const baseItem = $cart.items.find((it) => it.productVariantId === baseItemVariantId)

		if (complementOfId === null) {
			baseItem.quantity = quantity
			baseItem.requestedQuantity = quantity
		} else {
			let complement = baseItem.complements.find((it) => it.productVariantId === productVariantId);
			complement.quantity = quantity
			complement.requestedQuantity = quantity
		}

		// force redraw
		$cart = $cart

		// call api
		$cart = await putCartItemQuantity(productVariantId, complementOfId, quantity)
		$orderLoyaltyStatus = await fetchOrderLoyaltyStatus()
	}

	async function handleSignIn() {
		$cart = await fetchCart()
		$orderLoyaltyStatus = await fetchOrderLoyaltyStatus()
	}

	let loyaltyStatusFormatted
	$: {
		if (!$orderLoyaltyStatus || !$cart) {
			loyaltyStatusFormatted = null
		} else if ($cart.user.isSignedIn) {
			loyaltyStatusFormatted = translate('app.userLoyaltyProgram.orderStatus.same')
		} else {
			if ($orderLoyaltyStatus.hasNewReachedGroup && $orderLoyaltyStatus.reachedGroup.code !== 'basic') {
				loyaltyStatusFormatted = translate('app.userLoyaltyProgram.orderStatusUnregistered.reached').replace('%group%', $orderLoyaltyStatus.reachedGroup.name)
			} else {
				loyaltyStatusFormatted = translate('app.userLoyaltyProgram.orderStatusUnregistered.basic')
			}
		}
	}

</script>

{#if $cart}
	<div class="checkoutStepOne">
		<CartHeader>
			<div slot="left">
				<Title level="2" viewLevel="5">{translate('app.checkout.cart.titleLong')}</Title>
			</div>
			<div slot="right">
				<CartShare/>
			</div>
		</CartHeader>
		<Spacer initial="24" />
		<CheckoutWarnings />

		{#if $cart.items.length === 0}
			<EmptyCart />
		{:else}
			{#each $cart.items as cartItem (cartItem.productVariantId)}
				<CheckoutRow spaced={false}>
					<CartItem {...cartItem} {setQuantity} />
				</CheckoutRow>
			{/each}
			{#if $cart.shippingMethod}
				<CheckoutRow>
					<AdditionalOptionRow icon="legacy-delivery">
						<slot slot="left">
							{$cart.shippingMethod.label}
						</slot>
						<slot slot="right">
							{$cart.shippingMethod.price.formatted}
						</slot>
					</AdditionalOptionRow>
				</CheckoutRow>
			{/if}
			{#if $cart.paymentMethod}
				<CheckoutRow>
					<AdditionalOptionRow icon="payment">
						<slot slot="left">
							{$cart.paymentMethod.label}
						</slot>
						<slot slot="right">
							{$cart.paymentMethod.price.formatted}
						</slot>
					</AdditionalOptionRow>
				</CheckoutRow>
			{/if}

			<Vouchers/>

			{#if $cart.freeShippingPriceGoal && $cart.freeShippingRemaining}
				<CheckoutRow>
					{#if $cart.freeShippingRemaining.cents === 0 && translate($cart.user.isSignedIn ? 'app.checkout.freeShippingIndicator.signedIn.goalReached' : 'app.checkout.freeShippingIndicator.notSignedIn.goalReached')}
						<AdditionalOptionRow icon="legacy-delivery">
							<slot slot="left">
								{translate($cart.user.isSignedIn ? 'app.checkout.freeShippingIndicator.signedIn.goalReached' : 'app.checkout.freeShippingIndicator.notSignedIn.goalReached')}
							</slot>
						</AdditionalOptionRow>
					{:else if translate($cart.user.isSignedIn ? 'app.checkout.freeShippingIndicator.signedIn.goalNotReached.part1' : 'app.checkout.freeShippingIndicator.notSignedIn.goalNotReached.part1')
						&& translate($cart.user.isSignedIn ? 'app.checkout.freeShippingIndicator.signedIn.goalNotReached.part2' : 'app.checkout.freeShippingIndicator.notSignedIn.goalNotReached.part2')}
						<AdditionalOptionRow icon="legacy-delivery">
							<slot slot="left">
								{translate($cart.user.isSignedIn ? 'app.checkout.freeShippingIndicator.signedIn.goalNotReached.part1' : 'app.checkout.freeShippingIndicator.notSignedIn.goalNotReached.part1')}
								<strong>{$cart.freeShippingRemaining.formatted}</strong>
								{translate($cart.user.isSignedIn ? 'app.checkout.freeShippingIndicator.signedIn.goalNotReached.part2' : 'app.checkout.freeShippingIndicator.notSignedIn.goalNotReached.part2')}
							</slot>
							<slot slot="right">
								<FreeShippingProgressIndicator currentPrice={$cart.itemsTotalPrice.cents} priceGoal={$cart.freeShippingPriceGoal.cents} />
							</slot>
						</AdditionalOptionRow>
					{/if}
				</CheckoutRow>
			{/if}

			{#if !$cart.user.isSignedIn}
				<Spacer initial="16" />
				<CheckoutSignInForm onSignIn={handleSignIn} {passwordResetRequestLink} />
				<CheckoutSignUpForm {loyaltyProgramLink} />
			{/if}
			{#if loyaltyStatusFormatted}
				<CheckoutLoyaltyProgress currentTariff={$orderLoyaltyStatus.reachedGroup.code}>
					<span>
						{@html loyaltyStatusFormatted}
					</span>
				</CheckoutLoyaltyProgress>
			{/if}

			<Spacer initial="24" />

			<CheckoutNavigationRow>
				<slot slot="left">
					<Button type="link" variant="outlined" startIcon="legacy-arrow-left" link={homeLink} condense>{translate('app.checkout.buttons.leave')}</Button>
				</slot>
				<slot slot="center">
					<PriceSummary
						totalPrice={$cart.totalPrice.formatted}
						vatPrice={$cart.totalPrice.vatFormatted}
					/>
				</slot>
				<slot slot="right">
					<Button type="link" size="large" condense link={nextStepLink} endIcon="legacy-arrow-right" disabled={$cart.isInvalidCombination}>{translate('app.checkout.buttons.continue')}</Button>
				</slot>
			</CheckoutNavigationRow>
		{/if}
	</div>
{/if}
