<script>
	import clsx from 'clsx'
	import Collapsible from './Collapsible.svelte'
	import Icon from './Icon.svelte'
	import Spacer from './Spacer.svelte'
	import {cart} from '../stores/cartStore'

	/**
	 * @type {import('./Icon.svelte').IconName}
	 */
	export let icon = undefined
	export let expandedIcon = icon

	export let expanded = false
	export let showToggleExpandedButton = true

	function headerToggleExpanded() {
		if ($$slots.content && !showToggleExpandedButton) {
			toggleExpanded()
		}
	}

	function toggleExpanded() {
		expanded = !expanded
	}
</script>

<div
	class={clsx(
		'additionalOptionRow',
		$$slots.content && 'is-collapsible',
		icon && 'view-withIcon',
		expanded && 'is-expanded',
		showToggleExpandedButton && 'view-withToggleButton'
	)}
>
	<div on:click={headerToggleExpanded} class={clsx("additionalOptionRow-header", $$slots.content && !showToggleExpandedButton ? "is-wholeHeaderCollapsible" : "")}>
		{#if icon}
			<div class="additionalOptionRow-icon">
				<Icon name={expanded ? expandedIcon : icon} />
			</div>
		{/if}
		<div class="additionalOptionRow-left">
			<slot name="left" />
		</div>

		{#if $$slots.content && showToggleExpandedButton}
			<button on:click={toggleExpanded} type="button" class="additionalOptionRow-right view-button">
				<div class="additionalOptionRow-right-content">
					<slot name="right" />
				</div>
				<span class="additionalOptionRow-chevron">
					<Icon name="legacy-chevron" />
				</span>
			</button>
		{:else}
			<div class="additionalOptionRow-right">
				<slot name="right" />
			</div>
		{/if}
	</div>
	{#if $$slots.main}
		<Spacer initial="16"></Spacer>
		<div class="additionalOptionRow-main">
			<slot name="main" />
		</div>
	{/if}
	{#if $$slots.content}
		<div class="additionalOptionRow-content">
			<Collapsible {expanded}>
				<slot name="content" />
			</Collapsible>
		</div>
	{/if}
	<!-- https://github.com/sveltejs/svelte/issues/4546#issuecomment-627357929 -->
	{#if false}<slot />{/if}
</div>
