<script>
	import { putPriceGuarantee } from '../api/cart'
	import { emailPattern } from '../utils/emailPattern'
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import { genericPhoneFormat } from '../utils/phone'
	import { translate } from '../utils/translations'
	import Button from './Button.svelte'
	import FlashMessage from './FlashMessage.svelte'
	import FormRow from './FormRow.svelte'
	import ReservationSuccess from './ReservationSuccess.svelte'
	import Spacer from './Spacer.svelte'
	import TextInput from './TextInput.svelte'

	export let variantId
	export let values = {}

	let result = null
	let formElement = undefined
	let submitFired = false

	function handleChange(event) {
		values[event.target.name] = event.target.value
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function handleSubmit(event) {
		result = null
		submitFired = true
		const payload = await putPriceGuarantee(
			variantId,
			values.firstName,
			values.lastName,
			values.email,
			values.phone,
			values.link,
			values.note,
		).catch(() => {
			submitFired = false
			result = false
		})

		result = payload.ok
	}
</script>

<form on:submit|preventDefault={handleSubmit} method="post" bind:this={formElement}>
	<div class="priceGuaranteeForm">
		<div class="priceGuaranteeForm-subtitle">
			{@html translate('app.product.detail.priceGuarantee.subtitle')}
		</div>

		{#if result === null || result === false}
			{#if result === false}
				<Spacer initial="32" />
				<FlashMessage type="error">Formulář se nepodařilo odeslat.</FlashMessage>
			{/if}
			<Spacer initial="32" />

			<FormRow columnCount={2}>
				<TextInput
					name="firstName"
					value={values.firstName}
					on:change={handleChange}
					label={translate('app.formInputs.firstName')}
					required
					autocomplete="given-name"
				/>
				<TextInput
					name="lastName"
					value={values.lastName}
					on:change={handleChange}
					label={translate('app.formInputs.lastName')}
					required
					autocomplete="family-name"
				/>
			</FormRow>

			<FormRow columnCount={2}>
				<TextInput
					type="email"
					name="email"
					value={values.email}
					on:change={handleChange}
					label="E-mail"
					required
					autocomplete="email"
					pattern={emailPattern}
				/>
				<TextInput
					type="tel"
					name="phone"
					value={values.phone}
					on:change={handleChange}
					label={translate('app.formInputs.phone')}
					required
					autocomplete="tel"
					pattern={genericPhoneFormat}
				/>
			</FormRow>

			<FormRow columnCount={1}>
				<TextInput
					name="link"
					value={values.link}
					on:change={handleChange}
					label="Odkaz na eshop konkurence"
					required
				/>
			</FormRow>

			<FormRow columnCount={1}>
				<TextInput type="textarea" maxLength="250" name="note" value={values.note} on:change={handleChange} label="Poznámka" />
			</FormRow>

			<Spacer initial="16" />

			<div class="reservationForm-gdpr">
				{@html translate('app.form.reservationPrivacyPolicyConsent')}
			</div>

			<Spacer initial="40" />

			<div class="priceGuaranteeForm-footer">
				<Button on:click={submitButtonClick} type="submit" endIcon="legacy-arrow-right" disabled={submitFired}
					>Odeslat požadavek</Button
				>
			</div>
		{:else if result === true}
			<Spacer initial="24" />
			<ReservationSuccess personContact={values}>
				<slot slot="message">Odkaz na eshop konkurence se podařilo úspěšně odeslat. Děkujeme.</slot>
			</ReservationSuccess>
		{:else}
			<Spacer initial="24" />
			<FlashMessage type="error"
				>Nastala neočekávaná chyba. Odkaz na eshop konkurence se nepodařilo odeslat.</FlashMessage
			>
		{/if}
	</div>
</form>
