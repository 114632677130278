<script>
	import clsx from 'clsx'
	import Icon from './Icon.svelte'

	export let variant
	export let colorVariant
	export let size
	export let disableRipple
	export let startIcon
	export let endIcon
	export let condense
	export let squashed
	export let contentIcon
</script>

<span
	class={clsx(
		'button-in',
		`view-variant-${variant}`,
		colorVariant && `view-colorVariant-${colorVariant}`,
		`view-size-${size}`,
		disableRipple && 'view-rippleDisabled',
		(startIcon || endIcon) && 'has-icon',
		condense && 'view-condense',
		squashed && 'view-squashed',
	)}
>
	{#if startIcon}
		<span class="button-icon">
			<Icon name={startIcon} />
		</span>
	{/if}
	<span class="button-content">
		{#if contentIcon}
			<span class="button-icon">
				<Icon name={contentIcon} />
			</span>
		{:else}
			<slot />
		{/if}
	</span>
	{#if endIcon}
		<span class="button-icon">
			<Icon name={endIcon} />
		</span>
	{/if}
</span>
