<script>
	import { FilterProducts } from '../components/FilterProducts';
	import { filters,getGroupedSizes } from '../stores/filtersStore';
	import { getFormattedPriceValue } from '../utils/getFormattedPriceValue';
	import { pushGtmEvent } from "../utils/gtm";
	import { sendComponentMessage } from '../utils/initializeComponents';
	import Accordion from './Accordion.svelte';
	import ColorOptions from './ColorOptions.svelte';
	import OptionInput from "./OptionInput.svelte";
	import ProductFiltersOptionList from './ProductFiltersOptionList.svelte';
	import RangeSlider from './RangeSlider.svelte';

	export let currency
	export let filterProductsSelector
	export let groupSizes

	const filterProductsElement = document.querySelector(filterProductsSelector)

	let formElement

	const getFiltersData = () => {
		if (!formElement) {
			return {}
		}
		const formData = new FormData(formElement)
		const filtersData = {}

		for (const [key, value] of formData.entries()) {
			if (key.endsWith('[]')) { // options
				const code = key.substring(0, key.length - 2)
				filtersData[code] ??= []
				filtersData[code].push(value)

			} else if (key.endsWith('_min') || key.endsWith('_max')) { // range
				const code = key.substring(0, key.length - 4)
				const kind = key.substring(key.length - 3)
				const limit = $filters.find(it => it.code === code)[kind].toString()

				if (limit !== value) {
					filtersData[key] = Number(value)
				}
			} else {
				filtersData[key] = value
			}
		}
		return filtersData
	}
	async function loadData(filtersData) {
		sendComponentMessage(filterProductsElement, FilterProducts, 'setFilters', filtersData)
	}

	let debounceTimer = null

	async function onFormChange() {
		clearTimeout(debounceTimer)
		debounceTimer = setTimeout(() => {
			loadData(getFiltersData())
		}, 300)
	}
	const formatRange = (min, max, code) => {
		return `${code === 'price' ? min / 100 : min}|${code === 'price' ? max / 100 : max}`
	}
	const getGtmFiltersState = () => {
		const data = getFiltersData()
		return Object.fromEntries($filters.map(it => {
			if (it.type === 'options') {
				if (!data[it.code]) {
					return undefined
				}
				return [it.code, data[it.code]?.join('|')]
			} else if (it.type === 'range') {
				let min = data[it.code + '_min']
				let max = data[it.code + '_max']
				if (!min && !max) {
					return undefined
				}
				return [it.code, formatRange(min || it.min, max || it.max, it.code)]
			} else if (it.type === 'bool') {
				return [it.code, !!data[it.code]]
			}
		}).filter(it => it !== undefined))
	}
	const optionsChangeHandler = (code, value, checked) => {
		pushGtmEvent({
			event: checked ? 'filter.add' : 'filter.remove',
			filter: {
				type: code,
				value,
				action: checked ? 'add' : 'remove',
				config: getGtmFiltersState(),
			}
		})
	}
	const rangeChangeHandler = (e) => {
		let gtmFiltersState = getGtmFiltersState();
		pushGtmEvent({
			event: 'filter.add',
			filter: {
				type: e.detail.code,
				value: formatRange(e.detail.min, e.detail.max, e.detail.code),
				action: 'add',
				config: gtmFiltersState,
			}
		})
		onFormChange()
	}

	const boolChangeHandler = (code,  checked) => {
		pushGtmEvent({
			event: checked ? 'filter.add' : 'filter.remove',
			filter: {
				type: code,
				value: true,
				action: checked ? 'add' : 'remove',
				config: getGtmFiltersState(),
			}
		})
	}
</script>

<form class="productFilters" on:change={onFormChange} bind:this={formElement}>
	<Accordion items={$filters} view="filters">
		<div slot="title" let:item>{item.label}</div>
		<div slot="content" let:item>
			{#if item.type === 'options'}
				{#if item.code === 'color'}
					<ColorOptions options={item.allOptions} checkedOptions={item.checkedOptions} availableOptions={item.availableOptions} name={item.code}
						onToggle={optionsChangeHandler}/>
				{:else}
					<ProductFiltersOptionList
						{item}
						onToggle={optionsChangeHandler}
						groupedSizes={(groupSizes && item.code === 'size') ? getGroupedSizes(item) : undefined}
						view={item.code === 'brand' ? 'brand' : undefined}
					/>
				{/if}
			{:else if item.type === 'range'}
				<!-- @TODO api should probably send information about unit -->
				<RangeSlider
					on:change={rangeChangeHandler}
					limitMin={item.min}
					limitMax={item.max}
					name={item.code}
					initialValueMin={item.currentMin}
					initialValueMax={item.currentMax}
					valueFormatter={(value) => {
						if (item.code === 'price') {
							return getFormattedPriceValue(value, currency)
						}
						return value
					}}
				/>
			{:else if item.type === 'bool'}
				<div class="productFiltersOptionList-items">
					<div class="productFiltersOptionList-item">
						<OptionInput
							type="checkbox"
							name={`${item.code}`}
							checked={item.value}
							disabled={item.count === 0 && !item.value}
							value={1}
							on:change={e => boolChangeHandler(item.code, e.target.checked)}
						>
							<slot slot="label">Ano</slot>
						</OptionInput>
					</div>
				</div>
			{/if}
		</div>
	</Accordion>
</form>
