<script>
	import clsx from 'clsx'
	import OptionInput from './OptionInput.svelte'

	export let item
	export let onToggle = () => {}
	export let groupedSizes = undefined
	export let view = undefined
	let checkedGroupedSizes = undefined

	const collapseLimit = 6

	let isCollapsed = false
	let sortedAllOptions = []
	let listedOptions = []

	$: {
		if (groupedSizes === undefined && item.allOptions && item.availableOptions && item.checkedOptions) {
			sortedAllOptions = Object.entries(item.allOptions).sort(([_a_key, a_value], [_b_key, b_value]) => a_value.order - b_value.order)
			listedOptions = sortedAllOptions.map(([key, value]) => {
				if (item.availableOptions.indexOf(key) !== -1 || item.checkedOptions?.indexOf(key) !== -1) {
					return [key, value]
				}
			}).filter((item) => item !== undefined)
		}
	}

	$: {
		if (groupedSizes !== undefined) {
			const groupedSizesArray = Object.entries(groupedSizes.groups)
			const filteredCheckedGroupSizes = groupedSizesArray.filter((group) => group.some(item => item.checked === true))
			checkedGroupedSizes = filteredCheckedGroupSizes.length > 0 ? filteredCheckedGroupSizes : []

			isCollapsed = Object.entries(groupedSizes.groups).length > collapseLimit

			checkedGroupedSizes.forEach((group) => {
				if (groupedSizesArray.indexOf(group) + 1 > collapseLimit) {
					isCollapsed = false
				}
			})
		} else {
			isCollapsed = Object.entries(item.availableOptions).length > 6

			item.checkedOptions.forEach((option) => {
				const optionIndex = Array.from(listedOptions).findIndex(([key]) => key === option)
				if (optionIndex + 1 > collapseLimit) {
					isCollapsed = false
				}
			})
		}
	}

	function onGroupOptionChange(groupKey) {
		groupedSizes.groups[groupKey].checked = !groupedSizes.groups[groupKey].checked
	}
</script>

<div class={clsx('productFiltersOptionList', isCollapsed && 'is-collapsed', view && `view-${view}`)}>
	<div class="productFiltersOptionList-items">
		<!-- special code just for ski sizes grouping -->
		{#if groupedSizes}
			{#each Object.entries(groupedSizes.groups) as [groupKey, groupValue]}
				{#if groupValue.available}
					<div class="productFiltersOptionList-item">
						<OptionInput
							type="checkbox"
							checked={groupValue.checked}
							on:change={(e) => onGroupOptionChange(groupKey)}
						>
							<slot slot="label">{groupKey}</slot>
						</OptionInput>
					</div>
					{#each groupValue.items as itemValue}
						<div class="productFiltersOptionList-item is-hidden">
							<OptionInput
								type="checkbox"
								name={`${item.code}[]`}
								checked={groupValue.checked}
								value={itemValue.label}
								on:change={(e) => onToggle(item.code, itemValue.label, e.target.checked)}
							>
								<slot slot="label">{itemValue.label}</slot>
							</OptionInput>
						</div>
					{/each}
				{/if}
			{/each}
		{:else}
			{#each listedOptions as [key, value]}
				<div class="productFiltersOptionList-item">
					<OptionInput
						type="checkbox"
						name={`${item.code}[]`}
						checked={item.checkedOptions?.indexOf(key) !== -1}
						value={key}
						on:change={(e) => onToggle(item.code, key, e.target.checked)}
					>
						<slot slot="label">{value.label}</slot>
					</OptionInput>
				</div>
			{/each}
		{/if}
	</div>
	{#if isCollapsed}
		<button on:click={() => (isCollapsed = false)} class="productFiltersOptionList-more" type="button">
			+ Zobrazit další
		</button>
	{/if}
</div>
