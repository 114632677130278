<script>
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import { emailPattern } from '../utils/emailPattern'
	import FlashMessage from './FlashMessage.svelte'
	import Icon from './Icon.svelte'
	import OptionInput from './OptionInput.svelte'
	import TextInput from './TextInput.svelte'
	import { subscribe } from '../api/account'
	import {translate} from "../utils/translations";

	let values = { email: '', consent: false }
	let done = false

	let formElement = undefined

	function handleChange(event) {
		values[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function handleSubmit() {
		await subscribe(values.email, values.consent)
		done = true
	}
</script>

{#if !done}
	<form on:submit|preventDefault={handleSubmit} bind:this={formElement} class="newsletterForm" method="post">
		<div class="newsletterForm-emailRow">
			<TextInput
				type="email"
				name="email"
				autocomplete="email"
				label="Váš e-mail"
				required
				on:change={handleChange}
				pattern={emailPattern}
			/>
			<button on:click={submitButtonClick} class="newsletterForm-submit" type="submit">
				<Icon name="legacy-sent" />
			</button>
		</div>
		<OptionInput type="checkbox" name="consent" required on:change={handleChange}>
			<slot slot="label">{translate('newsletter.consent')}</slot>
		</OptionInput>
	</form>
{:else}
	<FlashMessage type="success">
		Hotovo
	</FlashMessage>
{/if}
