<div class="checkoutNavigationRow">
	{#if $$slots.left}
		<div class="checkoutNavigationRow-left">
			<slot name="left" />
		</div>
	{/if}
	{#if $$slots.center}
		<div class="checkoutNavigationRow-center">
			<slot name="center" />
		</div>
	{/if}
	{#if $$slots.right}
		<div class="checkoutNavigationRow-right">
			<slot name="right" />
		</div>
	{/if}
	<!-- https://github.com/sveltejs/svelte/issues/4546#issuecomment-627357929 -->
	{#if false}<slot />{/if}
</div>
