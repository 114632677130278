<script>
	import { tick } from 'svelte'
	import { timestampToLocaleDateString } from '../utils/timestampToLocaleDateString'
	import { translate } from '../utils/translations'
	import Box from './Box.svelte'
	import Collapsible from './Collapsible.svelte'
	import Icon from './Icon.svelte'
	import Inspections from './Inspections.svelte'
	import Picture from './Picture.svelte'
	import ServicebookServiceOrders from './ServicebookServiceOrders.svelte'
	import ServiceOrderModal from './ServiceOrderModal.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'

	export let data
	export let userEmail
	export let userPhone
	export let userFirstName
	export let userLastName

	let isHistoryOpen = false
	let isRemoveOpen = false
	let isOrderModalOpen = false

	$: currentOrderInProgress = data.serviceOrders.find(order => order.state === "ServiceInProgress")
	$: currentOrderAwaitingPickup = data.serviceOrders.find(order => order.state === "AwaitingPickup")

	async function handleOrderOpenModal() {
		isOrderModalOpen = false
		await tick()
		isOrderModalOpen = true
	}

	function handleHistoryClick() {
		isHistoryOpen = true
	}

	function handleRemoveClick() {
		isRemoveOpen = true
	}
</script>

<div class="productServiceTile">
	<Box>
		<Spacer break768="16" />
		<div class="productServiceTile-in">
			<div class="productServiceTile-media">
				<div class="productServiceTile-media-in">
					{#if data.image}
						<Picture image={data.image} sizes="270px" />
					{/if}
				</div>
			</div>
			<div class="productServiceTile-content">
				<div class="productServiceTile-heading">
					<Title level="3">{data.externalItemDescription}</Title>
					<div class="productServiceTile-remove">
						{#if isRemoveOpen}
							Pro odebrání produktu se prosím obraťte na <a
								href="mailto:odebrat-produkt@endorphinrepublic.cz?subject=Odebrat%20produkt"
								>odebrat-produkt@endorphinrepublic.cz</a
							>
						{:else}
							<button type="button" class="productServiceTile-remove-toggle" on:click={handleRemoveClick}>
								<Icon name="cross" />
								<span class="productServiceTile-remove-text">
									{translate('userService.product.remove')}
								</span>
							</button>
						{/if}
					</div>
				</div>
				<Spacer initial="4" />
				{#if data.externalItemDescription}
					<p class="productServiceTile-description">
						{data.externalItemDescription}
						<!-- @TODO: Don't repeat title -->
					</p>
				{/if}
				{#if data.serviceItemNo}
					<p class="productServiceTile-description">
						{translate('userService.product.code')}: {data.serviceItemNo}
					</p>
				{/if}
				{#if data.size}
					<p class="productServiceTile-description">{translate('userService.product.size')}: {data.size}</p>
				{/if}
				{#if data.salesDate}
					<p class="productServiceTile-description">
						{translate('userService.product.salesDate')}: {timestampToLocaleDateString(data.salesDate)}
					</p>
				{/if}
				<p class="productServiceTile-description">
					{translate('userService.product.warranty.title')}
					<a href={data.warranty.documentUrl} class="productServiceTile-description-linkWithIcon">
						<Icon name="purchaseOrder" />
						<span>{data.warranty.documentName}</span>
					</a>
				</p>
				<Spacer initial="32" />
				<Inspections
					{...data.inspection}
					recommendedNextServiceDate={data.recommendedNextServiceDate}
					currentOrderInProgress={currentOrderInProgress}
					currentOrderAwaitingPickup={currentOrderAwaitingPickup}
					on:order={handleOrderOpenModal}
				/>

				{#if data.serviceOrders.length > 0}
					<Spacer initial="10" />
					{#if isHistoryOpen === false}
						<button type="button" on:click={handleHistoryClick} class="productServiceTile-historyToggle"
							>{translate('userService.product.history.open')}</button
						>
					{/if}
					<Collapsible expanded={isHistoryOpen}>
						<Spacer initial="30" />
						<Title viewLevel="5" level="4">{translate('userService.product.history.title')}</Title>
						<Spacer initial="14" />
						<ServicebookServiceOrders orders={data.serviceOrders} />
					</Collapsible>
				{/if}
			</div>
		</div>
		<Spacer break768="16" />
	</Box>
</div>

<ServiceOrderModal
	isOpen={isOrderModalOpen}
	productName={data.externalItemDescription}
	productSize={data.size}
	{userEmail}
	{userPhone}
	{userFirstName}
	{userLastName}
	serviceOffers={data.serviceOffers}
	serviceItemNo={data.serviceItemNo}
/>
