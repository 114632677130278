<script>
	import FlashMessage from "./FlashMessage.svelte";
	import {cart} from '../stores/cartStore'
	import Spacer from './Spacer.svelte'
</script>
{#if $cart.isInvalidCombination}
	<FlashMessage type="error">Není možné kombinovat nákup dárkových poukazů a ostatních produktů.</FlashMessage>
	<Spacer initial="24" />
{/if}
{#if $cart.totalPrice.cents < 0}
	<FlashMessage type="error">Cena objednávky musí být vyšší než uplatněné dárkové poukazy.</FlashMessage>
	<Spacer initial="24" />
{/if}

{#each $cart.items as item}
	{#if item.quantity === 0}
		<FlashMessage type="warning">Položka {item.title} již není dostupná.</FlashMessage>
		<Spacer initial="24" />
	{:else if item.requestedQuantity > item.quantity}
		<FlashMessage type="warning">Položka {item.title} je dostupná již pouze v množství {item.quantity} ks (požadováno {item.requestedQuantity} ks)</FlashMessage>
		<Spacer initial="24" />
	{/if}
{/each}
