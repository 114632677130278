<script>
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import { passwordReset } from '../api/account'
	import Button from './Button.svelte'
	import Collapsible from './Collapsible.svelte'
	import FormError from './FormError.svelte'
	import FormRow from './FormRow.svelte'
	import PasswordInput from './PasswordInput.svelte'
	import Spacer from './Spacer.svelte'
	import FlashMessage from './FlashMessage.svelte'

	export let tokenValue
	let password = ''
	let errors = {}
	let successMessage = undefined
	let success = false

	let formElement = undefined

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function handleSubmit() {
		const response = await passwordReset(password, tokenValue)

		if (response.successMessage) {
			successMessage = response.successMessage
			success = true
		}
		if (!response.ok) {
			errors = response.errors
		}
	}

	function handlePassordInputChange(event) {
		password = event.target.value
	}
</script>

<form on:submit|preventDefault={handleSubmit} class="passwordResetForm" method="post" bind:this={formElement}>
	<Collapsible expanded={!!successMessage}>
		{#if successMessage}
			<FlashMessage type="success">{successMessage}</FlashMessage>
			<Spacer initial="16" />
		{/if}
	</Collapsible>

	<Collapsible expanded={errors.general}>
		{#if errors.general}
			{#each errors.general as error}
				<FormError message={error} view="standalone" />
			{/each}
			<Spacer initial="16" />
		{/if}
	</Collapsible>

	{#if success}
		<Button link="/" type="link">Pokračovat do e-shopu</Button>
	{:else }
		<FormRow>
			<PasswordInput
				value={password}
				errorMessage={errors.password?.join(';')}
				on:change={handlePassordInputChange}
				autocomplete="new-password"
			/>
		</FormRow>
		<FormRow>
			<Button on:click={submitButtonClick} type="submit">Změnit heslo</Button>
		</FormRow>
		<Spacer initial="24" />
		<a class="passwordResetForm-link" href="/prihlaseni">Zpátky na přihlášení</a>
	{/if}
</form>
