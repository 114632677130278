<script>
	import clsx from 'clsx'
	import { uniqueId } from '../utils/uniqueId'
	import Collapsible from './Collapsible.svelte'

	export let id = uniqueId('accordion-')
	/**
	 * @type {'default' | 'attributes' | 'filters' | 'faq'}
	 */
	export let view = 'default'
	export let items

	let collapsed = []

	function toggle(index) {
		collapsed = collapsed.includes(index) ? collapsed.filter(it => it !== index) : [...collapsed, index]
	}
</script>

<div class={clsx('accordion', view && `view-${view}`)} {id}>
	{#each items as item, index}
		<div class="accordion-item">
			<button on:click={e => toggle(index)} type="button" class="accordion-item-button" class:is-active={!collapsed.includes(index)}>
				<div class="accordion-item-title">
					<slot name="title" {item} />
				</div>
				<span class="accordion-item-icon" />
			</button>
			<Collapsible expanded={!collapsed.includes(index)}>
				<div class="accordion-item-content">
					<slot name="content" {item} />
				</div>
			</Collapsible>
		</div>
	{/each}
</div>
