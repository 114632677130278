<script>
	import AdditionalOptionRow from './AdditionalOptionRow.svelte'
	import Box from './Box.svelte'
	import CartItem from './CartItem.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'
	import CartItemPrice from './CartItemPrice.svelte'
	import OrderSteps from './OrderSteps.svelte'

	export let goodsReturnText = ''

	export let order
</script>

<div class="orderDetail">
	<Box>
		<div class="orderDetail-box">
			<div class="orderDetail-box-item">
				<span class="orderDetail-box-item-label">{order.kindText} č. </span>
				<span class="orderDetail-box-item-value view-large">{order.orderNo}</span>
			</div>
			<div class="orderDetail-box-item">
				{#if order.status.location}
					<span class="orderDetail-box-item-label">Objednáno:</span>
					<span class="orderDetail-box-item-value">{order.status.location}</span>
				{/if}
			</div>
			<div class="orderDetail-box-item">
				{#if order.status.text}
					<span class="orderDetail-box-item-label">Stav:</span>
					<span class="orderDetail-box-item-value">{order.status.text}</span>
				{/if}
			</div>
			<div class="orderDetail-box-item">
				<span class="orderDetail-box-item-label">Cena celkem:</span>
				<span class="orderDetail-box-item-value">{order.totalPrice.formatted}</span>
			</div>
			<div class="orderDetail-box-item">
				<span class="orderDetail-box-item-label">Datum:</span>
				<span class="orderDetail-box-item-value">{order.createdDate}</span>
			</div>
			{#if order.invoiceNo}
				<div class="orderDetail-box-item">
					<span class="orderDetail-box-item-label">{#if order.kind === 'creditMemo'}Dobropis{:else}Faktura{/if}:</span>
					<span class="orderDetail-box-item-value"><a href="{order.invoiceUrl}">{order.invoiceNo}</a></span>
				</div>
			{/if}
		</div>
	</Box>

	{#if order.status.code}
		<Box>
			<AdditionalOptionRow>
				<slot slot="left">
					<div class="orderDetail-steps">
						<div class="orderDetail-steps-in">
							<OrderSteps order={order} />
						</div>
						{#if order.status.link}
							<a href={order.status.link} class="orderPreview-status">{order.status.text}</a>
						{:else}
							<span class="orderPreview-status">{order.status.text}</span>
						{/if}
					</div>
				</slot>
			</AdditionalOptionRow>
		</Box>
	{/if}

	<Spacer initial="32" break768="56" />

	<Title level="3" viewLevel="5">Položky</Title>
	<Spacer initial="22" />
	<div class="orderDetail-products">
		{#each order.items.filter(it => it.type === 'product') as product}
			<Box>
				<CartItem {...product} showCode forceExpand />
			</Box>
		{/each}
	</div>

	{#if order.shipping}
		<Spacer initial="32" break768="56" />

		<Title level="3" viewLevel="5">Doprava</Title>
		<Spacer initial="22" />
		<Box>
			<AdditionalOptionRow showToggleExpandedButton={false}>
				<slot slot="left">
					<strong>{order.shipping.label ?? order.shipping.code}</strong><br>
					{order.shipping.address.name}<br>
					{order.shipping.address.address}<br>
					{#if order.shipping.address.postCode}{order.shipping.address.postCode}<br>{/if}
				</slot>
				<slot slot="right">
					{#if order.shipping.price}
						<CartItemPrice {...order.shipping.price} />
					{:else}
						<strong>Zdarma</strong>
					{/if}
				</slot>
			</AdditionalOptionRow>
		</Box>
	{/if}

	{#if order.payment}
		<Spacer initial="32" break768="56" />

		<Title level="3" viewLevel="5">Platba</Title>
		<Spacer initial="22" />
		<Box>
			<AdditionalOptionRow showToggleExpandedButton={false}>
				<slot slot="left">
					<strong>{order.payment.label ?? order.payment.code}</strong><br>
					{order.payment.address.name}<br>
					{#if order.payment.address.companyIdentifier}{order.payment.address.companyIdentifier},{/if}
					{#if order.payment.address.vatIdentifier}{order.payment.address.vatIdentifier}<br>{/if}
					{order.payment.address.address}<br>
					{#if order.payment.address.postCode}{order.payment.address.postCode}<br>{/if}
				</slot>
				<slot slot="right">
					{#if order.payment.price}
						<CartItemPrice {...order.payment.price} />
					{:else}
						<strong>Zdarma</strong>
					{/if}
				</slot>
			</AdditionalOptionRow>
		</Box>
	{/if}

	{#if order.kind !== 'creditMemo'}
		<Spacer initial="32" break768="56" />

		<Title level="3" viewLevel="5">Vrácení</Title>
		<Spacer initial="22" />
		<Box>
			<AdditionalOptionRow icon="change" showToggleExpandedButton={false}>
				<slot slot="left">
					{goodsReturnText}
				</slot>
			</AdditionalOptionRow>
		</Box>
	{/if}
</div>
