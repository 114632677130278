<script>
	export let productVariantId
	export let quantity
	export let availableQuantity
	export let setQuantity

	function decrementQuantity() {
		setQuantity(productVariantId, null, quantity - 1)
	}

	function incrementQuantity() {
		setQuantity(productVariantId, null, quantity + 1)
	}
</script>

{#if quantity === 0}
	Vyprodáno
{:else}
	<div class="cartItemQuantity">
		<button class="cartItemQuantity-minus" on:click={decrementQuantity} />
		<div class="cartItemQuantity-amount">{quantity}</div>
		<button disabled={availableQuantity <= quantity} class="cartItemQuantity-plus" on:click={incrementQuantity} />
	</div>
{/if}
