<script>
	import { onMount } from 'svelte'
	import { getJsonPayload } from '../utils/api'
	import { translate } from '../utils/translations'
	import AddCustomProductToUserService from './AddCustomProductToUserService.svelte'
	import ProductServiceTile from './ProductServiceTile.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'

	export let userEmail
	export let userPhone
	export let userFirstName
	export let userLastName

	let serviceItems = []

	onMount(async () => {
		serviceItems = (await getJsonPayload('/api/servicebook/items')).data
	})
</script>

{#if serviceItems.length > 0}
	<Title level="2" viewLevel="5">{translate('userService.products.title')}</Title>
	<Spacer initial="28" />
	{#each serviceItems as data}
		<ProductServiceTile {data} {userEmail} {userPhone} {userFirstName} {userLastName} />
	{/each}

	<Spacer initial="80" break1000="140" />
{/if}

<Title level="2" viewLevel="5">{translate('userService.addProduct.title')}</Title>
<Spacer initial="28" />
<AddCustomProductToUserService />
