<script>
	import Button from './Button.svelte'

	export let name
	export let options
	export let autocomplete = undefined
	export let value
	export let required = false

	$: selectedOption = options.find(option => option.value === value)
</script>

<div class="buttonSelect">
	<Button variant="outlined" condense endIcon="legacy-chevron-down" tabindex="-1">
		{selectedOption?.label ?? '\xa0'}
		<select on:change class="buttonSelect-control" {name} {autocomplete} {required} slot="customContent">
			{#each options as option}
			<option value={option.value} selected={option === selectedOption}>{option.label}</option>
			{/each}
		</select>
	</Button>
</div>
