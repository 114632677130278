<script>
	import Icon from './Icon.svelte'

	export let placeholder

	export let inputElement = undefined
	export let autocomplete = 'search'
</script>

<label class="searchInput" aria-label="Zadejte dotaz">
	<span class="searchInput-icon">
		<Icon name="search" />
	</span>
	<input type="search" on:input on:focus class="searchInput-input" name="query" {placeholder} {autocomplete} bind:this={inputElement} />
</label>
