<script>
	import clsx from 'clsx'

	export let level = '1'
	export let viewLevel = level
	export let centered = false
	export let secondary = false

	const topLevelElementClass = clsx(
		'title',
		'view-level-' + viewLevel,
		centered && 'view-centered',
		secondary && 'view-secondary',
	)
</script>

{#if level === '1'}
	<h1 class={topLevelElementClass}><slot /></h1>
{:else if level === '2'}
	<h2 class={topLevelElementClass}><slot /></h2>
{:else if level === '3'}
	<h3 class={topLevelElementClass}><slot /></h3>
{:else if level === '4'}
	<h4 class={topLevelElementClass}><slot /></h4>
{:else if level === '5'}
	<h5 class={topLevelElementClass}><slot /></h5>
{:else if level === '6'}
	<h6 class={topLevelElementClass}><slot /></h6>
{/if}
