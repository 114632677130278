<script>
	import clsx from 'clsx'
	import Collapsible from './Collapsible.svelte'
	import FormError from './FormError.svelte'

	/**
	 * @type {'checkbox' | 'radio'}
	 */
	export let type
	/**
	 * @type {'checkbox' | 'radio'}
	 */
	export let viewType = type
	export let name = null
	export let value = null
	export let disabled = false
	export let checked = null
	export let required = false

	export let errorMessage = undefined

	let localErrorMessage = undefined
	let displayedErrorMessage = undefined

	$: {
		displayedErrorMessage = errorMessage || localErrorMessage || displayedErrorMessage
	}

	function handleFocus() {
		localErrorMessage = undefined
		errorMessage = undefined
	}

	function handleInvalid() {
		localErrorMessage = 'Vyplňte prosím toto pole'
	}

	function handleBlur(event) {
		event.target.checkValidity()
	}
</script>

<div
	class={clsx(
		'optionInput',
		disabled && 'is-disabled',
		viewType && `view-type-${viewType}`,
		Boolean(errorMessage || localErrorMessage) && 'is-validationShown',
	)}
>
	<label class="optionInput-control">
		<input
			on:invalid|preventDefault={handleInvalid}
			on:focus={handleFocus}
			on:blur={handleBlur}
			on:change
			on:click
			{type}
			{name}
			{value}
			{disabled}
			{checked}
			{required}
			class="optionInput-input"
		/>
		<span class="optionInput-label">
			{#if $$slots.label}
				<slot name="label" />
			{/if}
		</span>
		{#if $$slots.aside}
			<div class="optionInput-aside">
				<slot name="aside" />
			</div>
		{/if}
	</label>
	{#if name && $$slots.label && $$slots.main === undefined}
		<a href="?{name.replace(/\[\]/g, '')}={value}" class="optionInput-link"><slot name="label" /></a>
	{/if}
	<div class="optionInput-error">
		<Collapsible expanded={Boolean(errorMessage || localErrorMessage)}>
			<FormError message={displayedErrorMessage} />
		</Collapsible>
	</div>
	{#if $$slots.main}
		<div class="optionInput-main">
			<slot name="main" />
		</div>
	{/if}
	<!-- https://github.com/sveltejs/svelte/issues/4546#issuecomment-627357929 -->
	{#if false}<slot />{/if}
</div>
