<script>
	import clsx from 'clsx'

	export let message

	export let asHtml = false

	/**
	 * @type {'standalone'} | null
	 */
	export let view = null
</script>

<div class={clsx('formError', view && `view-${view}`)}>
	<span class="formError-message">{#if asHtml}{@html message}{:else}{message}{/if}</span>
</div>
