<script>
	import { Modal as ExternalModal } from '../components/Modal'
	import SearchInput from './SearchInput.svelte'
	import { sendComponentMessage } from '../utils/initializeComponents'
	import {translate} from "../utils/translations";

	export let searchModalId

	const modal = document.querySelector(`#${searchModalId}`)

	function onFocus() {
		sendComponentMessage(modal, ExternalModal, 'open')
	}
</script>

<div class="noResultsSearchForm">
	<SearchInput on:focus={onFocus} autocomplete="off" placeholder={translate('app.search.inputPlaceholder')} />
</div>
