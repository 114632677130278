<script>
	import { cart } from '../stores/cartStore'
	import Button from './Button.svelte'
	import CartItemPrice from './CartItemPrice.svelte'
	import Picture from './Picture.svelte'
	import {translate} from "../utils/translations";

	export let checkoutLink

	let totalPrice = undefined
	let cartItems = []

	cart.subscribe((cart) => {
		if(cart) {
			totalPrice = cart.totalPrice.formatted
			cartItems = cart.items
		}
	})
</script>

{#if cartItems.length > 0}
	<div class="headerCartDetails">
		<ul class="headerCartDetails-items">
			{#each cartItems as cartItem}
				{#if cartItem.quantity > 0}
					<li class="headerCartDetails-item">
						<a class="headerCartDetails-item-in" href={cartItem.url}>
							<div class="headerCartDetails-item-image">
								<div class="headerCartDetails-item-image-in">
									<Picture image={cartItem.image} sizes="112px" ratio={1} />
								</div>
							</div>
							<div class="headerCartDetails-item-content">
								<div>{cartItem.title}</div>
								{#if cartItem.quantity > 1}
									<div class="headerCartDetails-item-quantity">{cartItem.quantity} ks</div>
								{/if}
								{#if cartItem.price}
									{#if cartItem.price.discounted && cartItem.price.discounted.cents < cartItem.price.original.cents}
										<div class="headerCartDetails-item-price view-discounted">{cartItem.price.discounted.formatted}</div>
									{/if}
									<div class="headerCartDetails-item-price">{cartItem.price.original.formatted}</div>
								{/if}
							</div>
						</a>
					</li>
				{/if}
			{/each}
		</ul>
		<div class="headerCartDetails-totalPrice">
			<span>{translate('app.headerCartDetails.total')}</span> <span>{totalPrice}</span>
		</div>
		<div class="headerCartDetails-button">
			<Button type="link" link={checkoutLink}>{translate('app.headerCartDetails.goToCart')}</Button>
		</div>
	</div>
{/if}
