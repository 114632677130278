<script>
	import {productVariantSelect} from "../stores/productVariantSelectStore";

	export let items
	export let products = []
	let selectedVariants = Object.fromEntries(products.map(it => [it.id, it.variants[0]]))
	productVariantSelect.subscribe(sub => {
		selectedVariants = Object.fromEntries(products.map(it => {
			const selectedVariant = sub.variants[it.id]
			return [it.id, selectedVariant ? it.variants.find(it => it.id === selectedVariant) : it.variants[0]];
		}))
	})
</script>

<div class="productsCompareCarousel-table view-attributes">
	{#each items as compareItem}
		<div class="productsCompareCarousel-table-row">
			<div class="productsCompareCarousel-table-heading">
				<span class="productsCompareCarousel-table-heading-in">{compareItem.label}</span>
			</div>
			{#each products as product}
				<div class="productsCompareCarousel-table-cell">
					{#if compareItem.type === 'brand'}
						{product.brand}
					{:else if compareItem.type === 'price'}
						{selectedVariants[product.id].price}
					{:else if compareItem.type === 'attribute'}
						{selectedVariants[product.id].attributes[compareItem.attributeId]?.join(', ') ?? ''}
					{/if}
				</div>
			{/each}
		</div>
	{/each}
</div>
