<script>
	import { onDestroy, onMount } from 'svelte'
	import { isMaterialized } from '../utils/isMaterialized'

	/**
	 * @type {HTMLDivElement}
	 */
	let element
	let isVisible = false
	let resizeObserver

	onMount(() => {
		resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				isVisible = isMaterialized(entry.target)
			})
		})
		resizeObserver.observe(element)
	})

	onDestroy(() => {
		resizeObserver.unobserve(element)
	})
</script>

<div bind:this={element}>
	{#if isVisible}
		<slot />
	{/if}
</div>
