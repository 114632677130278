<script>
	import clsx from 'clsx'
	import Icon from './Icon.svelte'

	/**
	 * @type {null | 'success' | 'error' | 'warning'}
	 */
	export let type


</script>

<div class={clsx("flashMessage", type && `view-${type}`)}>
	{#if type === 'success'}
		<span class="flashMessage-icon">
			<Icon name={'legacy-ok'} />
		</span>
	{:else if type === 'error'}
		<span class="flashMessage-icon">
			<Icon name={'legacy-error'} />
		</span>
	{/if}
	<p class="flashMessage-text">
		<slot />
	</p>
</div>
