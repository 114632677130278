<script>
	import clsx from 'clsx'
	import { afterUpdate } from 'svelte'
	import { forceReflow } from '../utils/forceReflow'

	/**
	 * @type {'topInsert' | 'leftInsert' | 'rightInsert' | 'bottomInsert' | 'fade'}
	 */
	export let transitionType = 'topInsert'
	export let expanded = true

	let delayedExpanded = expanded
	let isTransitioning = false
	let contentHeight = 'auto'
	let contentElement

	function onTransitionEnd(event) {
		if (event.propertyName === 'visibility') {
			contentHeight = 'auto'
			isTransitioning = false
		}
	}

	function updateContentHeight() {
		contentHeight = `${contentElement.getBoundingClientRect().height}px`
	}

	afterUpdate(() => {
		if (expanded !== delayedExpanded) {
			isTransitioning = true
			updateContentHeight()
			requestAnimationFrame(() => {
				forceReflow(contentElement)
				delayedExpanded = expanded
			})
		}
	})
</script>

<div
	class={clsx(
		'collapsible',
		delayedExpanded && 'is-expanded',
		`view-transitionType-${transitionType}`,
		isTransitioning && 'is-transitioning',
	)}
	on:transitionend={onTransitionEnd}
	style="--collapsible-content-height: {contentHeight}"
>
	<div class="collapsible-content" bind:this={contentElement}>
		<slot />
	</div>
</div>
