<script>
	import { onMount } from 'svelte'
	import Box from './Box.svelte'
	import OrderDetail from './OrderDetail.svelte'
	import OrderPreview from './OrderPreview.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'
	import { fetchOrderList } from '../api/account'
	import Button from './Button.svelte'

	export let goodsReturnText = ''

	export let listOrderUrl = null
	export let selectedOrderNo = null

	let orders = null

	$: selectedOrder = selectedOrderNo && orders ? orders.find(it => it.orderNo === selectedOrderNo) : null

	onMount(async () => {
		orders = await fetchOrderList()
		window.history.replaceState({ selectedOrderNo }, '')
	})

	function onOpen(order) {
		selectedOrderNo = order.orderNo
		window.history.pushState({ selectedOrderNo }, '', order.url)
		window.scrollTo({ top: 0 })
	}

	function onBack(event) {
		event.preventDefault()
		selectedOrderNo = null
		window.history.pushState({ selectedOrderNo }, '', listOrderUrl)
		window.scrollTo({ top: 0 })
	}

	function handlePopState(event) {
		selectedOrderNo = event.state.selectedOrderNo
	}

</script>

<svelte:window on:popstate={handlePopState}/>

<div class="userOrders">
	{#if selectedOrder === null}
		<Title level="2" viewLevel="4">Vaše objednávky</Title>
		<Spacer initial="22" />

		{#if orders !== null}
			{#each orders as order}
				<Box>
					<OrderPreview {order} {onOpen} />
				</Box>
			{/each}
		{/if}
	{:else}
		<Title level="2" viewLevel="4">
			<Button type="link" variant="text" disableRipple contentIcon="legacy-arrow-left" link={listOrderUrl} on:click={onBack} />
			Detail {#if selectedOrder.kind === 'creditMemo'}dobropisu{:else}objednávky{/if}
		</Title>
		<Spacer initial="22" />
		<OrderDetail order={selectedOrder} {goodsReturnText} />
	{/if}
</div>
