<script>
	import { getGoogleMaps } from '../utils/getGoogleMaps'
	import Loader from './Loader.svelte'
	import MapInner from './MapInner.svelte'

	/**
	 * @typedef {Object} Location
	 * @property {number} latitude
	 * @property {number} longitude
	 *
	 * @typedef {Object} MarkerData
	 * @property {number} latitude
	 * @property {number} longitude
	 * @property {string} title
	 * @property {(() => void) | undefined} onClick
	 * @property {boolean | undefined} small
	 */

	/**
	 * @type {MarkerData[]}
	 */
	export let markers = []
	/**
	 * @type {Location}
	 */
	export let center
	/**
	 * @type {number}
	 */
	export let zoom = 12


	const googleMapsPromise = getGoogleMaps()
</script>

<div class="map">
	{#await googleMapsPromise}
		<Loader />
	{:then googleMaps}
		<MapInner {googleMaps} {markers} {center} {zoom} />
	{/await}
</div>
