<script>
	import CartItemPrice from './CartItemPrice.svelte'
	import Icon from './Icon.svelte'
	import OptionInput from './OptionInput.svelte'

	export let baseItemVariantId

	export let productVariantId
	export let quantity
	export let title
	export let price
	export let info
	export let color
	export let size
	export let url

	export let setQuantity

	function handleChange(event) {
		setQuantity(productVariantId, baseItemVariantId, event.target.checked ? 1 : 0)
	}
</script>

<div class="optionalFeature">
	<div class="optionalFeature-control">
		<OptionInput type="checkbox" checked={quantity > 0} on:change={handleChange}>
			<slot slot="label">
				{#if url}
					<a href="{url}" target="_blank">{title}</a>
				{:else}
					{title}
				{/if}
				{#if color}
					| {color}
				{/if}
				{#if size}
					| {size}
				{/if}
			</slot>
			<slot slot="aside">
				<div class="optionalFeature-aside">
					<span class="optionalFeature-price"><CartItemPrice {...price} /></span>
					{#if info}
						<button type="button" class="optionalFeature-info">
							<span class="optionalFeature-info-icon">
								<Icon name="legacy-info" />
							</span>
							<span class="optionalFeature-info-text">{@html info}</span>
						</button>
					{/if}
				</div>
			</slot>
		</OptionInput>
	</div>
</div>
