<script>
	import CloseCross from './CloseCross.svelte'
	import { Modal as ExternalModal } from '../components/Modal'
	import { configuration } from '../utils/configuration'
	import { sendComponentMessage } from '../utils/initializeComponents'

	/**
	 * @type {'left' | 'center' | 'right' | 'topCenter' | 'centerWide' | 'fullscreen'}
	 */
	export let type = 'center'
	/**
	 * @type {boolean}
	 */
	export let open

	/**
	 * @type {HTMLDivElement}
	 */
	let content

	const initialType = type // Change of type after first initialization is not supported

	const contentElements = {
		left: document.getElementById(configuration.svelteModalContentLeftId),
		center: document.getElementById(configuration.svelteModalContentCenterId),
		right: document.getElementById(configuration.svelteModalContentRightId),
		topCenter: document.getElementById(configuration.svelteModalContentTopCenterId),
		centerWide: document.getElementById(configuration.svelteModalContentCenterWideId),
		fullscreen: document.getElementById(configuration.svelteModalContentFullscreenId),
	}

	const modalElements = {
		left: contentElements.left.closest('.modal'),
		center: contentElements.center.closest('.modal'),
		right: contentElements.right.closest('.modal'),
		topCenter: contentElements.topCenter.closest('.modal'),
		centerWide: contentElements.centerWide.closest('.modal'),
		fullscreen: contentElements.fullscreen.closest('.modal'),
	}

	$: {
		requestAnimationFrame(() => {
			sendComponentMessage(modalElements[initialType], ExternalModal, open ? 'open' : 'close')
		})
	}

	$: {
		if (open === true && content) {
			contentElements[initialType].innerHTML = ''
			contentElements[initialType].appendChild(content)
		}
	}

	// @TODO: poslouchat na zavření modalu - onRequestModalClose
</script>

<div class="modal-svelteWrapper">
	{#if open}
		<div bind:this={content}>
			{#if $$slots.title}
				<div class="modal-content-header">
					<slot name="title" />
					<div class="modal-content-header-close">
						<CloseCross on:click={() => {
							sendComponentMessage(modalElements[initialType], ExternalModal, 'close')
						}} />
					</div>
				</div>
			{/if}
			<slot />
		</div>
	{/if}
</div>
