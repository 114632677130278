<script>
	import { translate } from '../utils/translations'
	import Box from './Box.svelte'
	import Button from './Button.svelte'
	import Icon from './Icon.svelte'
	import Spacer from './Spacer.svelte'
</script>

<Box>
	<Spacer break768="16" />
	<div class="addCustomProductToUserService">
		<div class="addCustomProductToUserService-icon">
			<Icon name="plus" />
		</div>
		<p class="addCustomProductToUserService-text">{translate('userService.addProduct.message')}</p>
		<Button
			type="link"
			link="mailto:mujservis@endorphinrepublic.cz?subject=P%C5%99idat%20vlastn%C3%AD%20produkt"
			>{translate('userService.addProduct.contact')}</Button
		>
	</div>
	<Spacer break768="16" />
</Box>
