<script>
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import Button from './Button.svelte'
	import Collapsible from './Collapsible.svelte'
	import FlashMessage from './FlashMessage.svelte'
	import FormRow from './FormRow.svelte'
	import PasswordInput from './PasswordInput.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'
	import { postChangePassword } from '../api/account'

	let values = { oldPassword: '', newPassword: '' }
	let errors = {}
	let disableInputs = false
	let successMessage = undefined

	let formElement = undefined

	function handleChange(event) {
		values[event.target.name] = event.target.value
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function handleSubmit(event) {
		disableInputs = true
		const response = await postChangePassword(values.oldPassword, values.newPassword)
		
		if (response.successMessage) {
			successMessage = response.successMessage
			values = { oldPassword: '', newPassword: '' }
		}
		if (!response.ok) {
			errors = response.errors
		}
		disableInputs = false
	}
</script>

<div class="userPasswordSetting">
	<Title level="5">Změna hesla</Title>
	<Spacer initial="16" />
	
	<FormRow columnCount="2">
		<form on:submit|preventDefault={handleSubmit} bind:this={formElement}>
			<Collapsible expanded={!!successMessage}>
				{#if successMessage}
					<FlashMessage type="success">{successMessage}</FlashMessage>
					<Spacer initial="16" />
				{/if}
			</Collapsible>
			<FormRow>
				<PasswordInput
					name="oldPassword"
					value={values.oldPassword}
					label={'současné heslo'}
					errorMessage={errors.oldPassword?.join(';')}
					on:change={handleChange}
					autocomplete="current-password"
					disabled={disableInputs}
				/>
				</FormRow>
				
				<FormRow>
					<PasswordInput
					name="newPassword"
					value={values.newPassword}
					label={'nové heslo'}
					errorMessage={errors.newPassword?.join(';')}
					on:change={handleChange}
					autocomplete="new-password"
					disabled={disableInputs}
				/>
			</FormRow>
	
			<FormRow>
				<div class="userPasswordSetting-button">
					<Button on:click={submitButtonClick} type="submit">Změnit heslo</Button>
				</div>
			</FormRow>
		</form>
	</FormRow>
</div>
