<script>
	import clsx from 'clsx'
	import ButtonInner from './ButtonInner.svelte'

	/**
	 * @type {'contained' | 'outlined' | 'text'}
	 */
	export let variant = 'contained'
	/**
	 * @type {null | 'cyan' | 'grey' | 'red' | 'yellow'}
	 */
	export let colorVariant = null
	/**
	 * @type {'medium' | 'small' | 'large' | 'formItem'}
	 */
	export let size = 'medium'
	export let disableRipple = false
	/**
	 * @type {null | import('./Icon.svelte').IconName}
	 */
	export let startIcon = null
	/**
	 * @type {null | import('./Icon.svelte').IconName}
	 */
	export let endIcon = null
	/**
	 * @type {null | import('./Icon.svelte').IconName}
	 */
	export let contentIcon = null
	/**
	 * @type {null | string}
	 */
	export let link = null
	/**
	 * @type {'link' | 'button' | 'submit'}
	 */
	export let type = 'button'
	export let disabled = false
	export let condense = variant === 'text'
	export let squashed = false
	export let tabindex = undefined

	let innerProps

	$: {
		innerProps = {
			variant,
			colorVariant,
			size,
			disableRipple,
			startIcon,
			endIcon,
			condense,
			squashed,
			contentIcon,
		}
	}
</script>

{#if type === 'link' && disabled === false}
	<a on:click class="button" href={link} {tabindex}>
		<ButtonInner {...innerProps}><slot /></ButtonInner>
	</a>
{:else}
	<button on:click class="button" {disabled} {type} {tabindex}>
		<ButtonInner {...innerProps}><slot /></ButtonInner>
		<slot name="customContent" />
	</button>
{/if}
