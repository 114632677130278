<script>
	import AdditionalOptionRow from './AdditionalOptionRow.svelte'
	import CheckoutRow from './CheckoutRow.svelte'
	import SignInForm from './SignInForm.svelte'
	import Spacer from './Spacer.svelte'
	import {translate} from "../utils/translations";

	export let onSignIn

	export let signUpLink = undefined
	export let passwordResetRequestLink = undefined
</script>

<CheckoutRow>
	<AdditionalOptionRow icon="legacy-account-anonymous">
		<slot slot="left">
			<strong>{translate('app.checkout.signIn.title')}</strong><br />
			{translate('app.checkout.signIn.text')}
		</slot>

		<slot slot="right">{translate('app.signIn.title')}</slot>

		<slot slot="content">
			<Spacer initial="24" />
			<SignInForm {onSignIn} {signUpLink} {passwordResetRequestLink} compact />
		</slot>
	</AdditionalOptionRow>
</CheckoutRow>
