<script>
	import { translate } from '../utils/translations'
	import Icon from './Icon.svelte'
	import { timestampToLocaleDateString } from '../utils/timestampToLocaleDateString'

	export let orders
</script>

<div class="servicebookServiceOrders">
	{#each orders as data}
		<div class="servicebookServiceOrders-item">
			<div class="servicebookServiceOrders-number">
				{translate('userService.product.history.number')}:
				<div class="servicebookServiceOrders-number-value">
					{data.serviceOrderNo}
				</div>
			</div>
			<div class="servicebookServiceOrders-date">
				<b>
					{#if data.state === 'ServiceInProgress'}
						{translate(
							'userService.product.history.status.serviceInProgress',
						)}
					{:else if data.state === 'AwaitingPickup'}
						{translate(
							'userService.product.history.status.awaitingPickup',
						)}
					{:else if data.state === 'Finished'}
						{translate(
							'userService.product.history.status.finished',
						)}
					{/if}
				</b>
			</div>
			<div class="servicebookServiceOrders-date">
				{#if data.state === 'ServiceInProgress' && data.expectedFinishingDate}
					<b>Očekávané datum dokončení:</b>
					{timestampToLocaleDateString(data.expectedFinishingDate)}
				{/if}
			</div>
			<div class="servicebookServiceOrders-download">
				{#if data.invoiceUrl}
					<a href={data.invoiceUrl} class="servicebookServiceOrders-download-link"
						><Icon name="purchaseOrder" /> {translate('userService.product.history.file')}</a
					>
				{/if}
			</div>
		</div>
	{/each}
</div>
