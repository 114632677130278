<script>
	import Map from './Map.svelte'
	import RenderContentIfVisible from './RenderContentIfVisible.svelte'
	import OpeningHours from './OpeningHours.svelte'
	import Spacer from './Spacer.svelte'
	import {translate} from "../utils/translations";

	export let venue
	export let mapPinIcon

	const marker = {
		title: venue.company,
		latitude: venue.latitude,
		longitude: venue.longitude,
		icon: mapPinIcon,
	}
</script>

<div class="venueDetails">
	<div class="venueDetails-content">
		{#if venue.hours?.length > 0}
			<div class="venueDetails-openingHours">
				<div class="venueDetails-openingHours-title">{translate('app.openingHours.title')}</div>
				<OpeningHours openingHours={venue.hours.map(day => {
					const blocks = day.closeMorning !== "" && day.closeMorning === day.openAfternoon ? [
						`${day.openMorning} – ${day.closeAfternoon}`
					] : [
						...(day.openMorning !== "" ? [`${day.openMorning} – ${day.closeMorning}`] : []),
						...(day.openAfternoon !== "" ? [`${day.openAfternoon} – ${day.closeAfternoon}`] : [])
					]
					return ({
						startDay: day.dayName.toLowerCase(),
						endDay: day.dayName.toLowerCase(),
						timeBlocks: blocks.length > 0 ? blocks.join(', ') : translate('app.openingHours.closed'),
					});
				}).reduce((acc, item) => {
					if (acc.length === 0) {
						return [item]
					}
					const last = acc[acc.length - 1]
					if (last.timeBlocks === item.timeBlocks) {
						return [
							...acc.slice(0, -1),
							{
								startDay: last.startDay,
								endDay: item.endDay,
								timeBlocks: item.timeBlocks,
							},
						]
					}
					return [...acc, item]
				}, [])} />
			</div>
			<Spacer initial="24" break768="16" />
		{/if}

		<slot name="button" />
	</div>

	<div class="venueDetails-map">
		<RenderContentIfVisible>
			<Map
				center={marker}
				markers={[marker]}
			/>
		</RenderContentIfVisible>
	</div>
</div>
