<script>
	import { uniqueId } from '../utils/uniqueId'
	import FormRow from './FormRow.svelte'
	import Select from './Select.svelte'
	import {productVariantSelect} from "../stores/productVariantSelectStore";

	export let sizes
	export let productId
	const selectId = uniqueId('sizes-')
	let selectedValue = sizes[0]?.value

	function handleSizeChange(event) {
		productVariantSelect.update(it => ({...it, variants: {...it.variants, [productId]: event.target.value}}))
		selectedValue = event.target.value
	}
</script>

<div class="productSizeSelect">
	<FormRow>
		<Select
			name={selectId}
			options={sizes}
			value={selectedValue}
			on:change={handleSizeChange}
			label="Velikost"
		/>
	</FormRow>
</div>
