<script>
	import { emailPattern } from '../utils/emailPattern'
	import { focusInvalidInput } from '../utils/focusInvalidInput'
	import { postSignUp } from '../api/account'
	import Box from './Box.svelte'
	import Button from './Button.svelte'
	import Collapsible from './Collapsible.svelte'
	import FormError from './FormError.svelte'
	import FormRow from './FormRow.svelte'
	import IconList from './IconList.svelte'
	import LineSeparator from './LineSeparator.svelte'
	import OptionInput from './OptionInput.svelte'
	import PasswordInput from './PasswordInput.svelte'
	import Radio from './Radio.svelte'
	import Spacer from './Spacer.svelte'
	import TextInput from './TextInput.svelte'
	import Title from './Title.svelte'
	import {translate} from "../utils/translations";

	export let showFullForm = false
	export let signInLink = undefined

	export let accountBenefits = undefined

	let formElement = undefined

	export let onSignUp = () => {
		window.location.href = '/account/registration-thank-you'
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function handleSubmit() {
		const response = await postSignUp(
			values.email,
			values.password,
			values.firstName,
			values.lastName,
			values.gender,
			values.termsAgreement,
			values.newsletterAgreement,
		)

		if (!response.ok) {
			errors = response.errors
		} else {
			onSignUp()
		}
	}

	let values = {
		email: '',
		password: '',
		firstName: '',
		lastName: '',
		gender: null,
		termsAgreement: !showFullForm,
		newsletterAgreement: false,
	}
	let errors = {}

	const genderItems = [
		{
			value: 'male',
			label: 'Muž',
		},
		{
			value: 'female',
			label: 'Žena',
		},
	]

	function handleChange(event) {
		const { name, value, type, checked } = event.target
		values[name] = type === 'checkbox' ? checked : value
	}
</script>

<form on:submit|preventDefault={handleSubmit} class="signUpForm" method="post" bind:this={formElement}>
	<Collapsible expanded={errors.general}>
		{#if errors.general}
			{#each errors.general as error}
				<FormError message={error} view="standalone" />
			{/each}
			<Spacer initial="16" />
		{/if}
	</Collapsible>

	{#if showFullForm}
		<LineSeparator text={translate('app.signUp.label')} />
		<Spacer initial="32" break768="40" />
		<Title level="2" viewLevel="4">{translate('app.signUp.yourCredentials')}</Title>
		<Spacer initial="16" />
		<FormRow columnCount="2">
			<TextInput
				type="text"
				name="firstName"
				on:change={handleChange}
				errorMessage={errors.firstName?.join(';')}
				label={translate('app.formInputs.firstName')}
				autocomplete="given-name"
			/>
			<TextInput
				type="text"
				name="lastName"
				on:change={handleChange}
				errorMessage={errors.lastName?.join(';')}
				label={translate('app.formInputs.lastName')}
				autocomplete="family-name"
			/>
		</FormRow>
	{/if}
	<FormRow columnCount={showFullForm ? 1 : 2}>
		<TextInput
			type="email"
			name="email"
			on:change={handleChange}
			errorMessage={errors.email?.join(';')}
			label="E-mail"
			autocomplete=""
			pattern={emailPattern}
			required
		/>
		<PasswordInput
			value={values.password}
			errorMessage={errors.password?.join(';')}
			on:change={handleChange}
			autocomplete="new-password"
			note={translate('app.formInputs.passwordNote')}
			revealButtonText={translate('app.formInputs.passwordReveal')}
			hideButtonText={translate('app.formInputs.passwordHide')}
		/>
	</FormRow>

	{#if showFullForm}
		<Spacer initial="16" />
		<Radio items={genderItems} name="gender" {handleChange} errorMessage={errors.gender?.join(';')} view="inline" />
		<Spacer initial="32" break768="40" />
		<Box>
			<Title level="2" viewLevel="4">{translate('app.signUp.benefitsTitle')}</Title>
			<Spacer initial="24" break768="32" />
			<div class="signUpForm-iconList">
				<IconList items={accountBenefits} />
			</div>
		</Box>

		<Spacer initial="32" break768="56" />

		<OptionInput
			type="checkbox"
			name="termsAgreement"
			value="false"
			on:change={handleChange}
			errorMessage={errors.termsAgreement?.join(';')}
			required
		>
			<slot slot="label">{translate('app.form.privacyPolicyConsent')}</slot>
		</OptionInput>

		<Spacer initial="16" />

		<OptionInput
			type="checkbox"
			name="newsletterAgreement"
			value="false"
			on:change={handleChange}
			errorMessage={errors.newsletterAgreement?.join(';')}
		>
			<slot slot="label">{translate('app.form.newsletterConsent')}</slot>
		</OptionInput>
	{/if}



	{#if showFullForm}
		<Spacer initial="32" break768="56" />
		<div class="signUpForm-button">
			<Button on:click={submitButtonClick} type="submit" endIcon="legacy-arrow-right" size="formItem">{translate('app.signUp.createButton')}</Button>
		</div>
		<Spacer initial="32" break768="40" />
		<LineSeparator text={translate('app.signUp.alreadyHaveAnAccount')} />
		<Spacer initial="32" break768="40" />
		<div class="signUpForm-button">
			<Button type="link" variant="outlined" startIcon="legacy-account-anonymous" link={signInLink} size="formItem">{translate('app.signIn.title')}</Button>
		</div>
	{:else}
		<FormRow columnCount="2">
			<Button on:click={submitButtonClick} type="submit" endIcon="legacy-arrow-right" size="formItem" condense>{translate('app.signUp.createButton')}</Button>
		</FormRow>
	{/if}
</form>
