<script>
	import {focusInvalidInput} from '../utils/focusInvalidInput'
	import AddressForm from './AddressForm.svelte'
	import DeliveryAddressOptions from './DeliveryAddressOptions.svelte'
	import Title from './Title.svelte'
	import Spacer from './Spacer.svelte'
	import Button from './Button.svelte'
	import ModalFormSubmitButton from './ModalFormSubmitButton.svelte'
	import { fetchAddressList, putAddress } from '../api/address'
	import {translate} from "../utils/translations";

	export let addressOptions = []
	export let countryOptions = []
	export let selectedOption = null
	export let onAddressSelected

	let showAddressForm = false
	let address = null
	export let modalTitle

	let formElement = undefined

	async function putAddressObj(address) {
		return await putAddress({
			...address,
			id: undefined,
			addressId: address.id,
			country: undefined,
			countryId: address.country.id,
			gpsLat: undefined,
			gpsLng: undefined,
		})
	}

	function onCreateAddressClicked() {
		address = null
		showAddressForm = true
	}

	function onEditAddressClicked(addressToEdit) {
		address = addressToEdit
		showAddressForm = true
	}

	function submitButtonClick(event) {
		focusInvalidInput(formElement)
	}

	async function onSaveAddress() {
		await putAddressObj(address)
		addressOptions = await fetchAddressList()
		showAddressForm = false
		address = null
	}

	$: {
		if(showAddressForm) {
			if(address?.id) {
				modalTitle = translate('app.checkout.address.edit')
			} else {
				modalTitle = translate('app.checkout.address.new')
			}
		} else {
			modalTitle = undefined
		}
	}
</script>

{#if showAddressForm}
	<form on:submit|preventDefault={onSaveAddress} mehod="post" bind:this={formElement}>
		<AddressForm {countryOptions} bind:address hideEmail showCompany />
		<Spacer initial="16" />
		<ModalFormSubmitButton>
			<Button on:click={submitButtonClick} type="submit">{translate('app.checkout.address.save')}</Button>
		</ModalFormSubmitButton>
	</form>
{:else}
	<DeliveryAddressOptions {addressOptions} {countryOptions} {selectedOption} {onAddressSelected} {onCreateAddressClicked} {onEditAddressClicked} />
{/if}
