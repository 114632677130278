<script>
	import {translate} from "../utils/translations";

	export let totalPrice
	export let vatPrice
</script>
{#if totalPrice.startsWith('-')}
	<div class="priceSummary">
		<div class="priceSummary-main">
			<span class="priceSummary-label">{translate('app.checkout.priceSummary.remaining')}</span>
			<span class="priceSummary-value">{totalPrice.slice(1)}</span>
		</div>
	</div>
{:else}
	<div class="priceSummary">
		<div class="priceSummary-main">
			<span class="priceSummary-label">{translate('app.checkout.priceSummary.totalPrice')}</span>
			<span class="priceSummary-value">{totalPrice}</span>
		</div>
		<div class="priceSummary-vat">
			<span class="priceSummary-label">{translate('app.checkout.priceSummary.vatPrice')}</span>
			<span class="priceSummary-value">{vatPrice}</span>
		</div>
	</div>
{/if}
