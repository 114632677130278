<script>
	import { configuration } from '../utils/configuration'

	/**
	 * @typedef {'plus' | 'minus' | 'rollback' | 'chat' | 'orderHistory' | 'star-outline' | 'percentage' | 'transportation' | 'payment' | 'heart-outline' | 'delete' | 'check' | 'arrow-right' | 'arrow-left' | 'cart' | 'scales' | 'user' | 'priceTag' | 'info-filled' | 'shop' | 'info-outline' | 'skiing' | 'pin-outline' | 'pin-filled' | 'trash' | 'change' | 'archeology' | 'receiveCash' | 'chevron-right' | 'chevron-up' | 'chevron-left' | 'purchaseOrder' | 'internal' | 'search' | 'folder' | 'category' | 'news' | 'share-arrow' | 'share-balls' | 'edit' | 'inProgress' | 'sent' | 'eye' | 'trolley' | 'maintenance' | 'replace' | 'giftCard' | 'return' | 'starOfBethlehem' | 'phone' | 'location' | 'cookie' | 'reload' | 'spring-horizontal' | 'camera' | 'coupon' | 'cross' | 'geography' | 'chevron-left' | 'garageDoor' | 'image' | 'clock' | 'membershipCard' | 'passport' | 'star-halfEmpty' | 'footstep' | 'plot' | 'seedOfLife' | 'paymentCard' | 'star-filled' | 'squats' | 'skiRental' | 'lease' | 'ram' | 'ram-5' | 'union' | 'cyclist' | 'weight' | 'bikeLight' | 'sun' | 'cloud' | 'facebook' | 'external' | 'feminine' | 'fork-spring' | 'spring-vertical' | 'lightning' | 'helmet' | 'mountain' | 'road' | 'reflective' | 'scissors' | 'fork-basic' | 'glass-replacement' | 'glass-over-1' | 'telescopic' | 'glass-swap' | 'hand' | 'rideloc' | 'twinloc' | 'faceSize' | 'electricMotor' | 'feather' | 'partlyCloudy' | 'waterproof' | 'permeable-1' | 'ecological' | 'glass-over-2' | 'permeable-2' | 'accessories' | 'backpack' | 'bags' | backpack-'volume' | 'bikes' | 'boots' | climbing-'shoe' | 'clothes' | 'components' | 'glasses' | 'headlamp' | helmet-'2' | 'helmets' | 'shoes' | 'ski' | 'sport' | 'sticks' | vitamins-and-'minerals' | 'yoga' | 'lifestyle'} IconName
	 */

	/**
	 * @type {IconName}
	 */
	export let name
</script>

<svg class="icon icon-{name}">
	<use xlink:href="{configuration.iconsPath}#{name}" />
</svg>
