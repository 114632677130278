<script>
	import AddressSelect from './AddressSelect.svelte'
	import CheckoutRow from './CheckoutRow.svelte'
	import Spacer from './Spacer.svelte'
	import Title from './Title.svelte'
	import {translate} from "../utils/translations";

	export let cart
	export let countryOptions
	export let addressOptions
	export let showSecondaryVatId = false

</script>

<CheckoutRow condense>
	<Title level="2" viewLevel="5">{translate('app.checkout.billingAddress.title')}</Title>
	<Spacer initial="16" />
	<AddressSelect user={cart.user} bind:address={cart.billingAddress} {countryOptions} bind:addressOptions showCompany={true} {showSecondaryVatId}>
		<svelte:fragment slot="title">{translate('app.checkout.billingAddress.prompt')}</svelte:fragment>
	</AddressSelect>
</CheckoutRow>
