<script>
	import Button from "./Button.svelte";
	import {complementSetting} from "../stores/complementSettingStore";
	import {addToCart} from "../api/cart";
	import {translate} from "../utils/translations";

	export let productVariantId
	export let redirectUri
	export let inCart = false
	let adding = false
	const handleAddToCart = async () => {
		adding = true
		try {
			await addToCart(productVariantId, [...$complementSetting.variantIds.values()])
			window.location.href = redirectUri
		} catch {
			adding = false
		}
	}
</script>
<div class="addToCartButton">
	<Button condense={true} startIcon="cart" on:click={handleAddToCart} disabled={adding}>
		{#if adding}
			{translate('app.product.detail.inCart')}
		{:else if inCart}
			{translate('app.product.detail.buyAnother')}
		{:else}
			{translate('app.product.detail.buy')}
		{/if}
	</Button>
</div>
